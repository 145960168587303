import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import {
    StyleSheet,
    Text,
    View,
    Image,
    Dimensions,
    FlatList,
    ActivityIndicator,
} from "react-native-web";

import { connect } from "react-redux";
import {
    FomraatedDate,
} from "../../shared/core/datehelper";

import ReminderWebHandler from "../components/ReminderHandler";
import { fetchReminderFromApi, fetchMoreReminderFromApi } from "../../shared/actions/ReminderActions";
import ReminderHandler from "../components/ReminderHandler";
import { checkToken } from '../../shared/actions/TokenValidActions';

const useNavigate = require("react-router-dom").useNavigate;
const RemidersHome = props => {
    const history = useNavigate()

    const [isloaded, setIsload] = React.useState(false);

    const { Reminders, isGettingReminders } = props.Reminders;
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(100);


    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])

    React.useEffect(() => {
        props.tokenVal(history);
    }, [])

    useEffect(() => {
        //alert('Called Future')
        props.getReminder(pageSize, pageNumber);
    }, [])


    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    React.useEffect(() => {
        const onDimensionsChange = ({ window }) => {
            setDimensions(window);
        };
        Dimensions.addEventListener('change', onDimensionsChange);

        return () => Dimensions.removeEventListener('change', onDimensionsChange);
    }, []);

    const isLargeScreen = dimensions.width >= 1024;
    let columnCount = isLargeScreen ? 2 : 1;

    return (
       
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Reminders</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row row-margin-zero">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                          
                                            <div className="item-wrapper">

                                                {
                                                    isGettingReminders && (
                                                        <View style={{ textAlign: "center", marginBottom: 5 }}>
                                                            <ActivityIndicator size="small" color="#00A1DE" />
                                                        </View>
                                                    )
                                                }
                                                {
                                                    typeof (Reminders) !== 'undefined' && Reminders.length ? (
                                                        <FlatList
                                                            style={{ marginTop: 15, flex: 1 / 2 }}
                                                            data={Reminders}
                                                            renderItem={({ item }) => (
                                                                <ReminderHandler
                                                                    title={item.resource.extension[2].valueString}
                                                                    reminderProvider={item.resource.sender.display}
                                                                    color={item.resource.extension[5].valueString}
                                                                    reminderNote={item.resource.note == undefined ? "(Not Available)" : item.resource.note[0].text}
                                                                    reminderDate={FomraatedDate(item.resource.sent)} //item.resource.extension[5].valueString
                                                                    reminderDescription={item.resource.extension[0].valueString}
                                                                />
                                                            )}
                                                            keyExtractor={(item) => item.resource.id}
                                                            initialNumToRender={10}
                                                            maxToRenderPerBatch={10}
                                                            numColumns={columnCount}
                                                            key={columnCount}
                                                            windowSize={7}

                                                        />
                                                    ) : !isGettingReminders && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                                                    </View>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

               
    )
}





function mapStateToProps(state) {
    return {
        Reminders: state.Reminder,
    };
}

function mapDispatchToProps(disptach) {
    return {
        getReminder: (pageSize, pageNumber) => disptach(fetchReminderFromApi(pageSize, pageNumber)),
        tokenVal: (history) => disptach(checkToken(history))

        //getMoreReminder: (pageNumber, pageSize) => disptach(fetchMoreReminderFromApi(pageNumber, pageSize)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RemidersHome);

// export default RemidersHome
