import React, { useEffect, useState } from "react";
import {
  Timeline,
  Modal,
  DatePicker,
  Space,
  Input,
  Select,
  Button, Spin
} from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const TimelineFilterModal = ({
  isFilterModalOpen,
  handleFilterCancel,
  handleFilterOk,
  getTimelineDateTextSearch,
  lookupPersonsData
}) => {
  const [filterStartDateMoment, setFilterStartDateMoment] = useState("");
  const [filterStartDateString, setFilterStartDateString] = useState(null);
  const [filterEndDateMoment, setFilterEndDateMoment] = useState("");
  const [filterEndDateString, setFilterEndDateString] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterProviderId, setFilterProviderId] = useState("")

  const disabledDateForStart = (current) => {
    
    return current && current > moment().endOf("day") || current < moment().subtract(1, "year");
  };
  const disabledDateForEnd = (current) => {
    return (
      current &&
      current <= filterStartDateMoment || current > moment().endOf("day")
    );
  };
  const handleFilterReset = () => {
    setFilterStartDateMoment("")
    setFilterStartDateString(null)
    setFilterEndDateMoment("")
    setFilterEndDateString(null)
    setFilterText("")
    setFilterProviderId("")
  }
  useEffect(() => {
    console.log("lookupPersonsData ====>>>> ", lookupPersonsData)
  }, [lookupPersonsData])
  return (
    <>
      <Modal
        title="Timeline Search Filter"
        visible={isFilterModalOpen}
        onCancel={handleFilterCancel}
        footer={[
          <Button
            key="close"
            className="close-btn"
            onClick={handleFilterCancel}
          >
            Close
          </Button>,
          <Button className="reset-btn" key="reset" onClick={handleFilterReset}>
            Reset
          </Button>,
          <Button
            key="Filter"
            className="filter-btn"
            onClick={() => {
              getTimelineDateTextSearch(
                pageSize,
                pageNumber,
                filterStartDateString,
                filterEndDateString,
                filterText,
                filterProviderId == "" ? null : filterProviderId
              );
              handleFilterOk();
            }}
          >
            Filter
          </Button>,
        ]}
      >
        <Space direction="vertical" size={12}>
          <DatePicker
            placeholder={"Select start Date e.g. dd/mm/year"}
            format={"DD MMM YYYY"}
            value={filterStartDateMoment}
            disabledDate={disabledDateForStart}
            onChange={(date, dateString) => {
              setFilterStartDateMoment(date);
              setFilterStartDateString(dateString);
            }}
          />
          <DatePicker
            placeholder={"Select end Date e.g. dd/mm/year"}
            format={"DD MMM YYYY"}
            value={filterEndDateMoment}
            disabledDate={disabledDateForEnd}
            onChange={(date, dateString) => {
              setFilterEndDateMoment(date);
              setFilterEndDateString(dateString);
            }}
          />
          <Input
            placeholder="Type here to search.."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <Select
            showSearch
            placeholder="Select Provider"
            optionFilterProp="children"
            onChange={(e) => setFilterProviderId(e)}
            value={filterProviderId}
            onSearch={() => console.log("search clicked")}
          >
            <Option value={""}>All</Option>
            {lookupPersonsData.length > 0 ? lookupPersonsData[0].entry?.map((data) => {
              return <Option value={data.resource.id}>{data.resource.title}</Option>
            }) : <Spin />}
          </Select>
        </Space>
      </Modal>
    </>
  );
};

export default TimelineFilterModal;
