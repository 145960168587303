import React, { useEffect,useState } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import AppointmentHandler from "../components/AppointmentHandler";
import FutureAppointmentView from "./AppointmentTabs/FutureAppointmentView";
import CompleteAppointmentView from "./AppointmentTabs/CompleteAppointmentView";
import MissedAppointmentView from "./AppointmentTabs/MissedAppointmentView";
import CompleteAppointmentViewcopy from "./AppointmentTabs/CompleteAppointmentViewcopy";
import SearchAppointmentView from "./AppointmentTabs/SearchAppointmentView";
import { validatePatientTokenFromApi } from "../../shared/actions/TokenValidActions";
import loadjs from "loadjs";


import { View } from "react-native-web";
import { Tabs } from "antd";
import 'antd/dist/antd.css';
import renderHTML from 'react-render-html';
import GetAppSettings from '../../shared/core/GetAppSettings';
import NewUpdatedAppointment from './AppointmentTabs/NewUpdatedAppointment';
import { fetchfAppointmentsFromApi } from '../../shared/actions/FutureAppointActions';
const { TabPane } = Tabs;
//var loadjs = require('loadjs');
const Appointments = props => {

    const [isloaded, setIsload] = React.useState(false);
    const { TokenValidator, isChecking } = props.TokenValidator;
    const [routeid, setRoute] = React.useState("1")
    const [portalSetting, setPortalSetting] = useState(null);
    useEffect(() => {
        GetAppSettings().then((info) => {
            const set = JSON.parse(info)
            setPortalSetting(set)
        })
    }, [])
    React.useEffect(() => {

        // loadjs(['http://indici-pre-production.web.app/assets/js/template.js', 'http://indici-pre-production.web.app/assets/js/dashboard.js'], 'foobar');
        // loadjs.ready('foobar', function () {
        //     setIsload(true);
        // });


        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);


    }, [])

    useEffect(() => {
        //props.validateToken();
        if (props?.location?.state && props?.location?.state?.id != undefined) {
            //   alert(props.location.state.id)
            setRoute(props?.location?.state?.id)
            //  getMessageById(props.location.state.id)
        } else {
            //  alert("Teri merbani")
            setRoute("1")
            // history.push('/emails')
        }
    }, [])
    return (
      
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Appointments</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            
                            <div className="row row-margin-zero h-100">
                                <div className="col-12 h-100">
                                    <div className="grid h-100">
                                        <div className="grid-body h-100">
                                            {/* <h2 className="grid-title">Appointments</h2> */}
                                {/* props.location.state && props.location.state.id != undefined ? "2" */}
                                            <div className="item-wrapper appointment-container">
                                    <Tabs defaultActiveKey={"1"} centered items={[
                                                    {
                                                    label:"Book Appointment",
                                                    key: "1",
                                                    children: <NewUpdatedAppointment/>
                                                },
                                                    {
                                                    label:<div onClick={()=>{
                                                        props.getAppoint(100, 1);
                                                    
                                                }}>Upcoming Appointment</div>,
                                                    key: "2",
                                                    children: <FutureAppointmentView/>
                                                },
                                                    {
                                                    label:"History",
                                                    key: "3",
                                                    children: <SearchAppointmentView/>
                                                },

                                                ]} >
                                                    {/* <TabPane tab="Book Appointment" key="1">
                                                        {/* <NewAppointment /> */}
                                                        {/* <NewUpdatedAppointment />
                                                    </TabPane>
                                                    <TabPane tab="Upcoming Appointment(s)" key="2" >
                                                        <FutureAppointmentView />
                                                    </TabPane>

                                            <TabPane tab="History" key="3"> */}
                                                        {/* <CompleteAppointmentView /> */}
                                                        {/* <SearchAppointmentView />
                                                    </TabPane>  */}

                                                    {/* <TabPane tab="Missed" key="3">
                                                        <MissedAppointmentView />
                                                    </TabPane>

                                                    <TabPane tab="Cancelled" key="4">
                                                        <CompleteAppointmentViewcopy />
                                                    </TabPane>

                                                    <TabPane tab="Search" key="5">
                                                        <SearchAppointmentView />
                                                    </TabPane> */}


                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

              
    )
}


function mapStateToProps(state) {
    return {
        TokenValidator: state.TokenValidator
    }
}
function mapDispatchToProps(disptach) {
    return {
        
        validateToken: () => disptach(validatePatientTokenFromApi()),
        getAppoint: (pageSize, pageNumber) => disptach(fetchfAppointmentsFromApi(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Appointments)
// export default
