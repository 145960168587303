import React, { useEffect, useState } from 'react'
import { Modal, Input, notification } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



import ReCAPTCHA from 'react-google-recaptcha';
import dayjs from 'dayjs';
import Settings from '../../shared/config/Settings';
const StylesCss = `
 .react-datepicker-wrapper,
 .react-datepicker__input-container,
 .react-datepicker__input-container input {
   width: 100%;
 }
 
`;

const { TextArea } = Input
const LoginHelpModal = ({ visible, setVisible, handleCancel }) => {
    const [value, setValue] = useState()
    const [email, setEmail] = useState("")
    const [NHI, setNHI] = useState("")
    const [fName, setFName] = useState("")
    const [famName, setFamName] = useState("")
    const [DOB, setDOB] = useState("")
    const [help, setHelp] = useState("")
    const [mainMsg, setMainMsg] = useState("")

    const [emaErrMsg, setEmaErrMsg] = useState("")
    const [NHIErrMsg, setNHIErrMsg] = useState("")
    const [fNameErrMsg, setFNameErrMsg] = useState("")
    const [famNameErrMsg, setFamNameErrMsg] = useState("")
    const [DOBErrMsg, setDOBErrMsg] = useState("")
    const [helpErrMsg, setHelpErrMsg] = useState("")
    const [mMsgErrMsg, setmMsgErrMsg] = useState("")
    const [valiErrorCaught, setValiErrorCaught] = useState(false)
    const [isValidated, setIsValidated] = useState(false)

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description:
                message,
        });
    };
    const validateForm = () => {
        let result = false
        if (email === "" && NHI === "") {
            setEmaErrMsg("Either Email or NHI Number is Required")
            setEmaErrMsg("Either Email or NHI Number is Required")
            result = true
        }
        if (fName === "") {
            setFNameErrMsg("First Name is Required")
            result = true

        }
        if (famName === "") {
            setFamNameErrMsg("Family Name is Required")
            result = true

        }
        if (DOB === "") {
            setDOBErrMsg("Date of Birth is required")
            result = true

        }
        if (help === "") {
            setHelpErrMsg("Subject is Required")
            result = true

        }
        if (mainMsg === "") {
            setmMsgErrMsg("Message body is Required")
            result = true

        }
        return result
    }
    const handleOk = () => {
        let errCaught = validateForm()

        if (errCaught) {
            setValiErrorCaught(!valiErrorCaught);
        }
        else {
            let raw = {
                "PatientID": '',        //send this empty
                "EmailAddress": email,   //email address
                "UID": NHI,       //NHI number
                "FirstName": fName,     //first name
                "FamilyName": famName,  //family name
                "DOB": dayjs(DOB).format("YYYY-MM-DD"),       //date of birth
                "MessageSubject": help,   //Message Subject
                "MessageBody": mainMsg    //body goes here
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(raw),
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${Settings.apiUrl}SendHelpMessage`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    // console.log("Send Help API response ", result)
                    openNotificationWithIcon("success", result)

                    handleCancel()
                    onClose()
                }
                )

                .catch(error => openNotificationWithIcon("error", error));
        }
    }


    function onRecaptchaChange(value) {
        console.log("Captcha value:", value);
        setIsValidated(false)
    }

    const onClose = () => {
        setEmail("")
        setNHI("")
        setFName("")
        setFamName("")
        setDOB("")
        setHelp("")
        setMainMsg("")

    }
    function onRecaptchaExpired(value) {
        console.log("Captcha value:", value);
        setIsValidated(true)
    }
    useEffect(() => {

    }, [valiErrorCaught])
    return (
        <>
        <style>{StylesCss}</style>
            <Modal
            onClick={e => e.stopPropagation()}
            backdrop="static"
                open={visible}
                title="Login Help"
                onOk={handleOk}
                onCancel={() => {
                    handleCancel()
                    onClose()
                }}
                okText="Ask For Help"
                centered
                footer={[
                    <button className="btn btn-outline" onClick={() => { handleCancel(); onClose() }}>Close</button>,
                    <button className="btn btn-primary" disabled={isValidated} onClick={handleOk}>Ok</button>,
                ]}
            >
                <div className="modalBody">

                    <Input className="my-2" placeholder="Please Enter Your Email" value={email} onChange={(e) => {
                        setEmail(e.target.value);
                        setEmaErrMsg("");
                    }} />
                    <p className="errorMsg text-danger m-0">{emaErrMsg}</p>
                    <div className="text-center m-0">

                        <span className="help-OR-text">OR</span>
                    </div>

                    <Input className="my-2" placeholder="Please Enter Your NHI Number" value={NHI} onChange={(e) => { setNHI(e.target.value); setNHIErrMsg("") }} />
                    <p className="errorMsg text-danger m-0">{NHIErrMsg}</p>
                    <div className="text-center m-0">

                        <span className="help-OR-text">OR</span>
                    </div>

                    <Input className="my-2" placeholder="First Name" value={fName} onChange={(e) => { setFName(e.target.value); setFNameErrMsg("") }} />
                    <p className="errorMsg text-danger m-0">{fNameErrMsg}</p>

                    <Input className="my-2" placeholder="Family Name" value={famName} onChange={(e) => { setFamName(e.target.value); setFamNameErrMsg("") }} />
                    <p className="errorMsg text-danger m-0">{famNameErrMsg}</p>

                    <DatePicker
                    filterDate={d => {
                        return new Date() > d;
                      }}
                    isClearable
                    className="ant-input my-2"
                    onChange={(e) => { setDOB(e); setDOBErrMsg("") }}
                    selected={DOB}
                    placeholderText="Date of Birth"
                    dateFormat="dd-MM-yyyy"
                    style={{width:'400px'}}
                    />

                    {/* <DatePicker className="my-2 bg-light" placeholder="Date of Birth" value={DOB} onChange={(e) => { setDOB(e); setDOBErrMsg("") }} /> */}
                    <p className="errorMsg text-danger m-0">{DOBErrMsg}</p>

                    <Input className="my-2" placeholder="Subject" value={help} onChange={(e) => { setHelp(e.target.value); setHelpErrMsg("") }} />
                    <p className="errorMsg text-danger m-0">{helpErrMsg}</p>

                    <TextArea
                        className="my-2"
                        value={mainMsg}
                        onChange={(e) => { setMainMsg(e.target.value); setmMsgErrMsg("") }}
                        placeholder="Type Message"
                        autoSize={{
                            minRows: 3,
                            maxRows: 5,
                        }}
                    />
                    <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        sitekey={`${process.env.REACT_APP_CAPTCHA_SITEKEY}`}
                        onChange={onRecaptchaChange}
                        onExpired={onRecaptchaExpired}
                        badge={'bottomleft'}
                    />
                    <p className="errorMsg text-danger m-0">{mMsgErrMsg}</p>

                </div>

            </Modal>
        </>
    )
}

export default LoginHelpModal
