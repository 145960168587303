import { SENDING_MESSAGE, SENDING_MESSAGE_SUCCESS, SENDING_MESSAGE_REFRESH_DATA, SENDING_MESSAGE_LOADING, SENDING_MESSAGE_LISTING_ERROR, SENDING_MESSAGE_FAILED } from '../../constants/Constant'


//SET INITAILS STATE
const initialState =  {
    sendMessage: null,
    sendMessageLoading: false,
    sendMessageSuccess: false,
    sendMessageFailed: false,
    sendMessageError: null,
    sendMessageStatus: "idle",
  }

//export the defult state

export default function sendingMessageReducer(state = initialState, action) {
    switch (action.type) {
        case SENDING_MESSAGE:
            return {
                ...state,
                sendMessageLoading: true,
                sendMessageStatus: "loading",
                sendMessage: null,
                sendMessageSuccess: false,
                sendMessageFailed: false,
                sendMessageError: null,
                
            }
   case SENDING_MESSAGE_REFRESH_DATA:
            return {
                ...state,
                sendMessage: null,
                sendMessageLoading: false,
                sendMessageSuccess: false,
                sendMessageFailed: false,
                sendMessageError: null,
                sendMessageStatus: "idle",
            }

        case SENDING_MESSAGE_SUCCESS:
            return {
                ...state,
                sendMessageLoading: false,
                sendMessage: action.data,
                sendMessageSuccess: true,
                sendMessageStatus: "success",

                sendMessageFailed: false,
                sendMessageError: null,
            }

        case SENDING_MESSAGE_FAILED:
            return {
                ...state,
                sendMessageLoading: false,
                sendMessageStatus: "failed",
                sendMessageError: action.data,
                sendMessageFailed: true,

                sendMessage: null,
                sendMessageSuccess: false,
            }

        default:
            return state
    }
}