import React, { useEffect } from 'react';

import { connect } from "react-redux";
import { getVitalfromAPI } from '../../shared/actions/VitalsActions';
import { View, ActivityIndicator, FlatList, StyleSheet, Text, StatusBar } from "react-native-web";
import VitalHandler from '../components/VitalHandler';
import { checkToken } from '../../shared/actions/TokenValidActions';

const useNavigate = require("react-router-dom").useNavigate;

const Vitals = props => {
    const { isLoading,  wholeArray } = props.VitalReducer
    const history = useNavigate()

    React.useEffect(() => {
        props.tokenVal(history);
    }, [])

    useEffect(() => {
            props.getVital()
    }, [])

    return (
       
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Vitals</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row row-margin-zero">
                                {
                                    isLoading && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                }
                             

                                {
                                    typeof (wholeArray) !== 'undefined' && wholeArray.length ? (wholeArray.map((item,key) => {
                                        return (
                                            <VitalHandler
                                            key={key}
                                                keyitem={key}
                                                vitalName={item.name}
                                                vitalLabelsArray={item.labels}
                                                vitalDataArray={item.data}
                                                vitalDataArrayB={item.datab} />

                                        )
                                    })) : !isLoading && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                                    </View>
                                }

                            </div>

                        </div>
                    </div>

    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: StatusBar.currentHeight || 0,
    },
    item: {
        backgroundColor: '#f9c2ff',
        padding: 20,
        marginVertical: 8,
        marginHorizontal: 16,
    },
    title: {
        fontSize: 32,
    },
});

function mapStateToProps(state) {
    return {
        VitalReducer: state.VitalReducer
    }
}


function mapDispatchToProps(disptach) {
    return {
        getVital: (pageSize, vitaltype) => disptach(getVitalfromAPI()),
        tokenVal: (history) => disptach(checkToken(history))
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Vitals)
