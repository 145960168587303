import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text } from "react-native-web";

import { Tabs } from "antd";
import "antd/dist/antd.css";
import DueTab from "./ImmuneTabs/DueTab";
import OverdueTab from "./ImmuneTabs/OverdueTab";
import AdministeredTab from "./ImmuneTabs/AdministeredTab";
import AllTabs from "./ImmuneTabs/AllTabs";
import { fetchProfileFromApi } from "../../shared/actions/ProfileActions";
import { connect } from "react-redux";
import { ActivityIndicator } from "react-native";
import PendingInvoice from "./Accounts/PendingInvoice";
import AllInvoice from "./Accounts/AllInvoice";

const { TabPane } = Tabs;

const Accounts = (props) => {

  React.useEffect(() => {

    // loadjs(['http://indici-pre-production.web.app/assets/js/template.js', 'http://indici-pre-production.web.app/assets/js/dashboard.js'], 'foobar');
    // loadjs.ready('foobar', function () {
    //     setIsload(true);
    // });


    const script = document.createElement("script");
    script.async = true;
    script.src = "https://indici-pre-production.web.app/assets/js/template.js";
    document.body.appendChild(script)
    // d.div.appendChild(script);


  }, [])
  return (
          <div className="page-content-wrapper-inner">
            <div className="viewport-header">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb has-arrow">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Accounts
                  </li>
                </ol>
              </nav>
            </div>
            <div className="content-viewport">
              <div className="row row-margin-zero">
                <div className="col-12">

                  {/* ----- */}
                  <div className="row mb-2">
                    <div className="col-md-12 mx-auto">
                      <div className="alert alert-warning" role="alert" style={{  overflowX: 'hidden', padding: '15px 20px 15px 20px', lineHeight: '22px' }}>

                        <h6 className="alert-heading">Disclaimer:</h6>
                        <p>Never give indici or myindici account details, passwords, or confidential information out to anyone and be careful what information you share on social media. If you get a suspicious call, text, or email, or if you notice any suspicious activity, contact your medical practice immediately.</p>

                        <p className="mb-0"><strong> Remember:</strong> Team indici will never ask you for your bank details or personal information.</p>


                      </div>
                    </div>
                  </div>
                  {/* -----  */}

                  <div className="grid">
                    <div className="grid-body">
                      {/* <h2 className="grid-title">Accounts</h2> */}
                      <div className="item-wrapper accounts-container">
                        <Tabs defaultActiveKey="1" centered>
                          <TabPane
                            tab="Pending Invoices"
                            key="1"
                            style={{ backgroundColor: "#f7f7f7", padding: 10 }}
                          >
                            <PendingInvoice />
                          </TabPane>

                          <TabPane tab="All Invoices" key="2">
                            <AllInvoice />
                          </TabPane>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

       
  );
};

//export default connect(mapStateToProps, mapDispatchToProps)(ImmuneHome);
export default Accounts;
