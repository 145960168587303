import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";

export default function VitalHandler({
    keyitem,
    vitalName,
    vitalLabelsArray,
    vitalDataArray,
    vitalDataArrayB
}) {
    const [readings, setReadings] = useState([]) 

    
    useEffect(()=>{
        console.log('vitalLabelsArray',vitalLabelsArray)
        console.log('vitalDataArray',vitalDataArray)
        console.log('vitalDataArrayB',vitalDataArrayB)
        let arr = [
        ]
        vitalLabelsArray.map((date, index)=>{
            if (index == 0) {
                if (vitalName?.toLowerCase() == "blood pressure") {
                    arr.push(
                        ["Date", "Systolic", "Diastolic"]
                    )

                }
                else if (vitalName?.toLowerCase() == "weight") {

                    arr.push(
                        ["Date", "Weight"]
                    )
                }
                else if (vitalName?.toLowerCase() == "heart rate") {

                    arr.push(
                        ["Date", "Heart Rate"]
                    )
                }
                else if (vitalName?.toLowerCase() == "body height") {
                    arr.push(
                        ["Date", "Body Height"]
                    )
                }
                else if (vitalName?.toLowerCase() == "body mass index") {
                    arr.push(
                        ["Date", "Body Mass Index"]
                    )
                }
                else {
                    arr.push(
                        ["", "", ""])
                }
            }

            if (vitalDataArrayB[index]) {
                arr.push([date, vitalDataArray[index], vitalDataArrayB[index]])
            } else {
                arr.push([date, vitalDataArray[index]])
            }
        })
        setReadings(arr)
      },[])
      useEffect(()=>{
        console.log("readingsss", readings)
      },[readings])

    const options = {
        vAxis: { 
            minValue: 0,
            maxValue: 200,
            format: 0, 
            gridlines: { count: 10 }, minorGridlines: { count: 0 }},
        chartArea: { width: "80%", height: "70%" },
            pointSize: 5,
            legend: {'position':'top','alignment':'center'},
    };
    const data = [
        ["BP", "Value"],
        ["08 Oct 20022", 40],
        ['09 Oct 20022"', 52],
        ['10 Oct 20022"', 49]        
    ];
    {console.log("readings",  readings)}
    return (
      
        <div className="col-md-6" >
            <div className="grid">
                <div className="grid-body">
                    <h2 className="grid-title">{vitalName}</h2>
                    <div className="item-wrapper">
                        {console.log("key vital",keyitem + '-' + vitalName)}
                
                        <Chart
                            key={keyitem}
                            chartType="AreaChart"
                            width="100%"
                            height="300px"
                            data={readings}
                            options={options}
                            legendToggle={true}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}
