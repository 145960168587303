import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Text,
    ActivityIndicator,
    Dimensions
} from 'react-native-web';
import { connect } from 'react-redux'
import ImmuneHandler from "../../components/ImmuneHandler";
import { GetAppointmentDate, GetMonthName, GetYear, ShowDateImmune, GetHeadingDates } from '../../../shared/core/datehelper'
import { fetchImmunefromApi, fetchMoreImmunefromApi } from '../../../shared/actions/ImmuneActions';
import PrintAppointmentHeader from '../../components/PrintAppointmentHeader';
import { useReactToPrint } from 'react-to-print';
import { Tooltip } from 'antd';
const AllTabs = props => {

    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(1);
    const { Immune, isGettingImmune } = props.Immune;
    const { Profile } = props.Profile;
    const { Practise } = props.Practise;
    const [isPrintActive, setIsPrintActive] = useState(false)


    useEffect(() => {
        //alert('Called Future')
        props.getAllImmune(pageSize, pageNumber);
    }, [])

    function GetTagColor(results) {
        // due immunization check.
        let itemType = ""
        results.resource.extension.map(item => {
            if (item.url == "ImmType") {
                itemType = item.valueString
            }
        })
        if (itemType == "overdue") {
            return 'card-header text-white bg-danger'
            // return { backgroundColor: Colors.highAllergy }
        }
        // due 
        else if (itemType == "due") {
            return 'card-header text-white bg-warning'
            //return { backgroundColor: Colors.midAllergy }
        }
        else if (itemType == "administered") {

            return 'card-header text-white bg-success'
            //{ backgroundColor: Colors.lowAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        }
        else {
            // latter check
            return 'card-header text-white bg-success'
            // return { backgroundColor: Colors.lowAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }

        }
    }



    function GetHeadingDateForAll(results) {

        if (results.resource.extension[10].valueBoolean == true) {
            //overdue
            return GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)

            // return 'card-header text-white bg-danger'
        }
        else if (results.resource.extension[11].valueBoolean == false && results.resource.extension[10].valueBoolean == false && results.resource.status === undefined) {
            //due
            return GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)
            //return 'card-header text-white bg-warning'

        }
        else if (results.resource.status !== undefined && results.resource.status == "completed") {
            //admin

            return GetAppointmentDate(results.resource.date) + ' ' + GetMonthName(results.resource.date) + ' ' + GetYear(results.resource.date)


            //  return 'card-header text-white bg-success'

        } else {
            //null
            return GetAppointmentDate(results.resource.date) + ' ' + GetMonthName(results.resource.date) + ' ' + GetYear(results.resource.date)

            // return 'card-header text-white bg-success'


        }
    }
    const componentRef = useRef();
    const pageStyleCss = `@page {
        size: A4 landscape;
      }
      *{
          font-family: sans-serif;
        }
         .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        h3,h4,h5,p{
            margin:0px;
        }
        p span{
            font-weight: 600;
        }
        .ind-med{
            border-bottom: 1px solid grey;
            padding:5px 10px; 
        }
        .card-text, .card-title, .med-date{
            color: black !important;
            font-weight: 300 !important;
        }
        .med-heading{
            color: black !important;
            font-weight: 600 !important;
            margin-bottom: 3px;
        }
     `;
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: false,
        pageStyle: pageStyleCss,
    })
    useEffect(() => {
        if (isPrintActive) {
            handlePrint()
            setIsPrintActive(false)
        }
    }, [isPrintActive])
    return (
        <div className="d-flex flex-column">
            <div className="row row-margin-zero">
                <div className="col-12">
                    <div style={{ marginLeft: 10, alignItems: 'center' }}>
                        <span className="badge badge-success" style={{ marginLeft: 15, marginTop: 10 }}>Administered</span>
                        <span className="badge badge-danger" style={{ marginLeft: 15, marginTop: 10 }}>Overdue</span>
                        <span className="badge badge-warning" style={{ marginLeft: 15, marginTop: 10 }}>Due</span>
                    </div>
                </div>
            </div>
            {
                isGettingImmune && <View style={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (Immune) !== 'undefined' && Immune.length ? (
                    <>
                        <Tooltip title="Print" className="print-icon d-flex align-self-end justify-content-end align-items-center" style={{ marginRight: "20px" }} onClick={() => { setIsPrintActive(true) }}>
                           <div className='print-immune-btn btn btn-primary mr-2'>
                            Print
                           </div>
                            {/* <p className="m-0 print-text">Print</p> */}
                        </Tooltip>
                        {/* <button className="btn btn-info align-self-end" style={{ marginRight: "20px" }} onClick={() => setIsPrintActive(true)}>Print</button> */}
                        <div id="printModal" ref={componentRef} >
                            <PrintAppointmentHeader organizationData={Practise} patientProfile={Profile} isPrintActive={isPrintActive} />
                    <FlatList
                        data={Immune}
                        renderItem={({ item }) => (
                            <ImmuneHandler
                                styles={GetTagColor(item)}
                                heading={item.resource.extension[0].valueString}//{GetHeading(item)}
                                date={GetHeadingDateForAll(item)}//{GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}   //{ShowDateImmune(item)}
                                month={GetYear(item.resource.extension[12].valueDateTime)}
                                //  type_diagnoes={item.resource.extension[0].valueString}
                                indication={(item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : "N/A")}
                                vacc={(item.resource.extension[6].valueString != undefined ? item.resource.extension[6].valueString : "N/A")}
                                datedue={GetHeadingDates(item)}
                            />
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}
                    />
                        </div>
                    </>
                ) : !isGettingImmune && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                        <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                </View>

            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        Immune: state.Immune,
        Profile: state.Profile,
        Practise: state.Practise
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllImmune: (pageSize, pageNumber) => dispatch(fetchImmunefromApi(pageSize, pageNumber)),
        //getAllImmuneMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreImmunefromApi(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTabs)


// export default AllTabs
