import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { ActivityIndicator, View, FlatList, Text } from "react-native";
import AppointmentHandler from '../../components/AppointmentHandler';
import { fetchfAppointmentsFromApi } from "../../../shared/actions/FutureAppointActions";
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime, dateFormatWithOutTime } from '../../../shared/core/datehelper';
import { Modal } from 'antd';
import getUserId from '../../../shared/core/GetToken';
import Settings from '../../../shared/config/Settings';
import dayjs from 'dayjs'
import { notification } from 'antd';
import AppointmentLocationModal from '../../components/AppointmentLocationModal';
import moment from 'moment';

const FutureAppointmentView = props => {
    const { Profile, isFetching } = props.Profile;
    const { FutureAppointments, isGettingFut } = props.FutureAppointments;
    const { Practise } = props.Practise
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    const [cancelvisible, setcancelVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [modalText, setModalText] = React.useState('Content of the modal');
    const [deleteitemid, setDeleteItemId] = React.useState('')
    const [cancelAppointErrMsg, setCancelAppointErrMsg] = React.useState('')

    // useEffect(() => {
    //     //alert('Called Future')
    //     props.getAppoint(pageSize, pageNumber);
    // }, [])
    const AppointmentCancelHandler = (item) => {
        setcancelVisible(true)
        setDeleteItemId(item.resource.id)
    }
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description: message,
        });
    };
    const handleOk = () => {
        let reason = document.getElementById('mReason1').value
        if (reason.trim() == "") {
            setCancelAppointErrMsg("Reason is required")
            return
        } else {
            setCancelAppointErrMsg("")
        }
        setConfirmLoading(true);
        //    alert(reason)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/Appointment?PatientID=" + item.userid + "&AppointmentID=" + deleteitemid + "&Reason=" + reason, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result == "Appointment cancelled successfully.") {
                        openNotificationWithIcon('success', "Appointment cancelled successfully.")
                        props.getAppoint(pageSize, pageNumber);
                        setcancelVisible(false);
                        setConfirmLoading(false);
                        // props.getAppoint(pageSize, pageNumber);
                    }
                    else if (result == "Some Error Occurded.") {
                        openNotificationWithIcon('waring', result)
                        setcancelVisible(false);
                        setConfirmLoading(false);
                    } else {
                        openNotificationWithIcon('waring', result)
                        setcancelVisible(false);
                        setConfirmLoading(false);

                    }

                })
                .catch(error => console.log('error', error));
        })
        document.getElementById('mReason1').innerHTML = ""



    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setcancelVisible(false);
    };
    return (
        <div>
            {
                isGettingFut && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (FutureAppointments) !== 'undefined' && FutureAppointments.length ? (
                    <FlatList
                        style={{
                            flexDirection: 'column', paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        //contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-evenly' }}
                        data={FutureAppointments}
                        renderItem={({ item }) => {
                            const totime = moment(item.resource.period.start).format('HH:mm')
                            const fromtime = moment().format('HH:mm')
                            const toDate = moment(item.resource.period.start).format('YYYY-MM-DD')
                            const fromDate = moment().format('YYYY-MM-DD')

                            const ft = dayjs(`${fromDate} ${fromtime}`);
                            const tt = dayjs(`${toDate} ${totime}`);
                            const minutes = tt.diff(ft, "minutes", true);
                            const totalHours = parseInt(minutes / 60);
                            const totalMinutes = dayjs().minute(minutes).$m;
                            //{console.log(moment(item.resource.period.start).subtract(1, 'days').format("ll"))}
                            return (
                                <AppointmentHandler
                                    styleName={'card-header text-white bg-primary'}
                                    type={"Future"}
                                    appType={item.resource.extension[0].valueString ? item.resource.extension[0].valueString : 'N/A'}
                                    appointmentSatus={item.resource.extension[6].valueString ? item.resource.extension[6].valueString : 'N/A'}
                                    appointmentSatusID={item.resource.extension[16].valueString ? item.resource.extension[16].valueString : 0}
                                    providerName={item.resource.extension[2].valueString ? item.resource.extension[2].valueString : 'N/A'}
                                    appointmentDate={moment(item.resource.period.start).subtract(1, 'days').format("ll")}
                                    remianingtime={moment.duration(moment(item.resource.period.start).diff(moment(new Date())))}
                                    praticeAddress={item.resource.extension[3].hasOwnProperty('valueString') ? item.resource.extension[3].valueString : '(N/A)'}
                                    firstitem={item.resource.extension[5].valueString ? item.resource.extension[5].valueString : 'N/A'}
                                    secondItem={GetDayName(item.resource.period.start.split('T')[0]) + ', ' + FormateUTCTime(item.resource.period.start)}
                                    patientDetails={item.resource.extension[1].valueString} //+ ', ' + item.resource.extension[0].valueString}}
                                    location={item.resource.extension[4].hasOwnProperty('valueString') ? item.resource.extension[4].valueString : '(N/A)'}
                                   // reason={"<b>Appointment Status</b>: " + item.resource.extension[6].valueString}
                                    reasonForCancel={null}
                                    totalHours={totalHours}
                                    totalMinutes={totalMinutes}
                                    canelHandler={() => AppointmentCancelHandler(item)}
                                    videoCounsutlConfig={item.resource.extension[15].hasOwnProperty('valueString') ? item.resource.extension[15].valueString : null}
                                    duration={item.resource.extension[7].valueDecimal}
                                    ////
                                    nhi={Profile.length && Profile[0]?.resource?.extension[3]?.valueString}
                                    // birthdate={Profile.length && Profile[0]?.resource?.birthDate}
                                    birthdate={moment(Profile.length && Profile[0]?.resource?.birthDate).format('DD MMM YYYY')}
                                    address={Profile.length && Profile[0]?.resource?.address[0]?.text}
                                    patientProfile={Profile}
                                    organizationData={Practise}
                                    videoCall={true}
                                />
                            )
                        }}
                        horizontal={false}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={30}

                    />
                ) : !isGettingFut && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                        <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                </View>
            }
            <Modal
                title="Cancel Appointment"
                visible={cancelvisible}
                onOk={handleOk}
                okText={'Yes'}
                cancelText={'No'}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >

                <div className="form-group row ">
                    <div className="col">
                        <h6>Are you sure you want to cancel appointment?</h6>
                        <textarea id="mReason1" className="form-control" rows={3} placeholder="Type Reason here..." />
                        <p className='text-danger'>{cancelAppointErrMsg}</p>
                    </div>

                </div>

            </Modal>

        </div>

    );
}



function mapStateToProps(state) {
    return {
        Profile: state.Profile,
        FutureAppointments: state.FutureAppointments,
        Practise: state.Practise,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAppoint: (pageSize, pageNumber) => dispatch(fetchfAppointmentsFromApi(pageSize, pageNumber))
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FutureAppointmentView)

//export default FutureAppointmentView
