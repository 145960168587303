import React from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.css';
import App from './Entry';

import { Provider } from "react-redux";
import store, { Persister } from "./configureStore";
import { PersistGate } from "redux-persist/integration/react"


// const store = configureStore();
// document.addEventListener('DOMContentLoaded', async () => {
 // const { publishableKey } = await fetch('http://localhost:4242/config').then((r) => r.json());
  const stripePromise = loadStripe('pk_test_jVIOf3mGtJXT1TCLaL3Eqf9s');
 // console.log("captchsitekey:" + process.env.REACT_APP_CAPTCHA_SITEKEY)
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <PersistGate loading={null} persistor={Persister}>
            <App />
          </PersistGate>
        </Elements>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
