import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, ActivityIndicator, TouchableOpacity, View, Image, Dimensions } from 'react-native';


import { Spin, Switch, Alert, Tooltip, Popover, notification } from 'antd';
import { Table, Tag, Space } from 'antd';
import { Modal, Button } from 'antd';
import getUserId from "../../shared/core/GetToken";
import { LoadingOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

import 'antd/dist/antd.css';
import { capitalize } from '../../shared/core/datehelper';
import Settings from '../../shared/config/Settings';
import renderHTML from "react-render-html";

import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import PrintOrganizationHeader from './PrintOrganizationHeader';
import Chart from 'react-google-charts';



const ReportsHandler = props => {


    const [tabledata,setTableData] = useState([]);
    const { Column, ColumnGroup } = Table;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [visible, setVisible] = useState(false);  //for report pdf view 
    const [tableModalVisible, setTableModalVisible] = useState(false); //for
    const [htmlModalVisible, setHtmlModalVisible] = useState(false);
    const [pdfViewerLoading, setPdfViewerLoading] = useState(false);
    const [isReady, setIsReady] = useState(true);
    const [sloading, setSLoading] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null)
    const [isPrintActive, setIsPrintActive] = useState(false)
    const [reportDataType, setReportDataType] = useState("")
    const [chartLoading, setChartLoading] = useState(false)
    const [labChartModalOpen, setLabChartModalOpen] = useState(false)

    const getRandomTag = () => {
        var textArray = [
            'card-header text-white bg-danger',
            'card-header text-white bg-success',
            'card-header text-white bg-primary',
            'card-header text-white bg-info',
            'card-header text-white bg-warning',
        ];
        var randomNumber = Math.floor(Math.random() * textArray.length);
        return textArray[randomNumber]
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }

   
    const loadReport = () => {
        setVisible(true)
        setSLoading(true);

        getUserId().then((userInfo) => {
            //   setPdfViewerLoading(true)
            //    

            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/LabResultByID?PatientID=" + item.userid + "&ResultID=" + props.labid, requestOptions)
                .then(data => data.json())
                .then(json => {
                    localStorage.setItem("selectedResult", JSON.stringify(json))
                    setSLoading(false)
                })
                .catch(error => {
                    // setIsLoadingPdf(false)
                    //console.log(error)
                    setSLoading(false)
                    setPdfViewerLoading(false)
                })
                .finally(() => {
                    // setCheckFile(false)
                    // setIsLoadingPdf(false)
                    setSLoading(false)
                    setPdfViewerLoading(false)


                });
        });
    }
    useEffect(() => {
        localStorage.removeItem("selectedResult")
    }, [])
    useEffect(() => {
        if (localStorage.getItem("selectedResult")) {
            setSelectedResult(JSON.parse(localStorage.getItem("selectedResult")))
        }
    }, [localStorage.getItem("selectedResult")])
    useEffect(() => {
        
        if (selectedResult !== null) {
            if(selectedResult?.length > 0){
            if (selectedResult[0].entry[0].resource.content[0].attachment.contentType == "PDF" || selectedResult[0].entry[0].resource.content[0].attachment.contentType == "pdf") {
                setReportDataType("pdf")
            }
            else if (selectedResult[0].entry[0].resource.content[0].attachment.contentType == "HTML" || selectedResult[0].entry[0].resource.content[0].attachment.contentType == "html") {
                setReportDataType("html")


            }
            else {
                //setTableData([]);
                let tableArray=[];
                let ObservationText = '';
                let ObservationValue = "";
                let AbnormalFlagDesc = "";
                let ObservationSummary="";
                selectedResult[0].entry.map((item, index) => {
                    debugger
                    item.resource.extension.map((value, key) => {
                        if (item.resource.extension[key].url == "ObservationText") {
                            ObservationText = item.resource.extension[key].valueString ? capitalize(item.resource.extension[key].valueString) : "N/A"
                        } else if (item.resource.extension[key].url == "ObservationValue") {
                            ObservationValue = item.resource.extension[key].valueString ? capitalize(item.resource.extension[key].valueString) : "N/A"
                        }
                        else if (item.resource.extension[key].url == "AbnormalFlagDesc") {
                            AbnormalFlagDesc = item.resource.extension[key].valueString ? capitalize(item.resource.extension[key].valueString) : "N/A"
                        }
                        ObservationSummary= item.resource.extension.find(o => o.url === 'ObservationSummary')?.valueString;

                    })
                    let ob = {
                        "ObservationText": ObservationText,
                        "ObservationValue": ObservationValue,
                        "AbnormalFlagDesc": AbnormalFlagDesc,
                        "ObservationSummary": ObservationSummary
                    }
                    tableArray.push(ob);
                })
                setTableData([])
                setTableData(tableArray)
                setReportDataType("table")
                // setIsReady(!isReady)
                //console.log({ tabledata })
                setTimeout(() => {

                }, 3000);
            }
        }else{
            setReportDataType("empty")
        }
    }
    }, [selectedResult])

    const pageStyleCss = `@page {
        size: A4;
      }
      *{
          font-family: sans-serif;
        }
        .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        h3,h4,h5,p{
            margin:0px;
        }
        p span{
            font-weight: 600;
        }
        .repot-details-table{
            width:100%;
        }
        .repot-details-table-heading{
            text-align: left;
        }
        .HTML *{
            font-weight: 300;
        }
      `;
    const cssTable = ' table td { white-space: inherit !important; }'
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: false,
        pageStyle: pageStyleCss
    })
   
    useEffect(() => {
        if (isPrintActive) {
            handlePrint()
            setIsPrintActive(false)
        }
    }, [isPrintActive])
    const formatObservationValue = (value) => {
        return value.replace("Detected   detected", "Detected ")
    }

    const options = {
        vAxis: {
            minValue: 0,
            maxValue: 200,
            format: 0,
            gridlines: { count: 10 }, minorGridlines: { count: 0 }
        },
        chartArea: { width: "70%", height: "70%" },
        pointSize: 5,
        legend: { 'position': 'top', 'alignment': 'center', 'maxLines': 3 },
        hAxis: { format: "MMM d", slantedText: false, maxAlternation: 1 }

    };
    const data = [
        ["Test", "29", "30", '1'],
        ["ALT", 52, 50, 10],
        ["Globulin", 29, 0, 10],
        ["Albumin", 36, 0, 10],
        ["Bilirubin total", 37, 30, 10],
        ["CGT", 6, 0, 10],
        ["Total Protien", 64, 60, 10],
        ["Alk Phosphatase", 73, 0, 10],
        ["Alk Phosphatase", 78, 80, 10],
    ];
    let rawData = []
    const [readings, setReadings] = useState([])
    const [api, contextHolder] = notification.useNotification();

    const openChartErrorNotification = (placement) => {
        api.error({
            message: `No Chart Data Available`,
            placement,
        });
    };
    const getLabChartByCode = (labcode) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = '?PatientID=' + item.userid + '&labcode=' + labcode
            fetch(Settings.apiUrl + "Patient/LabResultChartById" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    rawData = json
                    if (json && json.length > 0) {
                        calculateReadings()
                        setLabChartModalOpen(true)
                    } else {
                        setChartLoading(false)
                        openChartErrorNotification("topRight")
                    }
                })
                .catch(err => {
                    setChartLoading(false)
                    openChartErrorNotification("topRight")
                    console.log("errrrror", err)
                })


        })
    }
    const calculateReadings = () => {
        let datesArray = []
        let testsArray = []
        let readingsVar = []
        let readingsArray = []

        // {
        //     "observationvalue": "6",
        //         "observationtext": "Bilirubin total",
        //             "resultdate": "29/09/2020 2:17:00 pm",
        //                 "messagesubject": "Liver Function Tests"
        // },
        console.log("object")
        rawData?.map((item, index) => {
            if (!testsArray.includes(item.observationtext)) {
                testsArray.push(item.observationtext)
            }
            if (!datesArray.includes(item.resultdate?.split(" ")[0])) {
                datesArray.push(item.resultdate?.split(" ")[0])
            }
        })
        datesArray.map((date, index) => {
            readingsVar.push([date].concat(rawData.filter(item => {
                if (item.resultdate?.split(" ")[0] == date) {
                    return item.observationvalue
                }
            }).map(item => {
                if (testsArray.indexOf(item.observationtext) != -1) {
                    return parseInt(item.observationvalue)
                } else {
                    return 0
                }
            })))
        })

        readingsVar.unshift(["Date"].concat(testsArray))
        setReadings(readingsVar)
        // testsArray.map((testName, index) => {
        //     readings.push([testName].concat(rawData.map(item => {
        //         if (item.observationtext == testName) {
        //             return item.observationvalue
        //         }
        //     })))
        // })
        console.log("readingssss Arr", readingsVar)
        setChartLoading(false)

    }
    const chartPopupContent = (
        <div style={{ width: "400px" }}>
            {chartLoading ? <Spin /> :
                readings && readings.length > 0 ?
                <Chart
                        chartType="LineChart"
                    width="100%"
                        height="100%"
                    data={readings}
                    options={options}
                    legendToggle={true}
                    /> : <span>No Data Available</span>
            }

        </div>)
    useEffect(() => {
        if (chartLoading) {
            getLabChartByCode(selectedResult[0].entry[0].resource.description)
        }
    }, [chartLoading])
    return (

        // <Spin spinning={sloading}>
        <>
            <style>{cssTable}</style>
            <TouchableOpacity onPress={() => sloading ? null : loadReport()}>

                <div className="col-lg-12 ">

                    <div className="card shadow-lg rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                        {/* <Spin indicator={antIcon} tip="Loading..." spinning={sloading}> */}
                        <div className={'card-header text-white ' + (props.isRead ? "bg-warning": "bg-success")}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text style={{ fontWeight: '500', color: '#fff' }}>{props.testName}</Text>
                                <Text style={{ fontWeight: '500', color: '#fff' }}>{props.rptDate}</Text>


                            </View>
                        </div>
                        <div className="card-body">
                            <h4 className="card-title reports-text"><i className="mdi mdi-18px mdi-home-map-marker report-icon-flex" /><span className='pl-1'> {props.author} </span></h4>
                            <p className="card-title reports-text"><i style={{ marginRight: 4, }} className="mdi mdi-18px mdi-calendar-clock report-icon-flex" /><span> {'Laboratory: ' + props.labName} </span> </p>
                            <p className="card-text reports-text" ><i style={{ marginRight: 4 }} className="mdi mdi-18px mdi-folder report-icon-flex" />{'Folder: ' + props.folderName}</p>
                            <p className="card-text reports-text" style={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}><i className="mdi mdi-18px mdi-hospital-marker report-icon-flex" /> <span> {'Comments: ' + props.comments}</span></p>
                          
                        </div>
                      
                    </div>



                </div>

            </TouchableOpacity>
            {/* PDF File modal */}
            <Modal
                title={<div className='d-flex justify-content-between mr-4'>
                    <span className='text-light'>{props.testName + " (" + props.rptDate + ")"}</span>
                    {selectedResult && selectedResult.length > 0 && <>
                        {contextHolder}

                        <div
                            style={{ background: "#ffffff4d", marginRight: "5px", padding: "4px", cursor: "pointer", borderRadius: "3px", pointerEvents: chartLoading ? "none" : "all" }}
                            onClick={() => setChartLoading(true)}>
                            <svg
                                width={20}
                                height={20}
                                fill={chartLoading ? "lightgrey" : 'white'}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512">
                                <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
                            </svg>
                        </div>
                    </>}
                </div>
                }
                destroyOnClose={true}
                centered
                closable={true}
                pageno
                open={visible}
                maskClosable={false}
                onCancel={() => setVisible(false)}
                afterClose={() => { localStorage.removeItem('selectedResult'); props.refreshReports() }}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1500}
                closeIcon={<svg xmlns="http://www.w3.org/2000/svg" width={15} style={{ marginTop: "5px" }} fill='white' viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" /></svg>}

                footer={reportDataType == "pdf" ? [
                    <button className="btn btn-outline" key="back" onClick={() => setVisible(false)}>
                        Close
                    </button>,

                ] : [
                        <button className="btn btn-outline mx-2" key="back" onClick={() => setVisible(false)}>
                            Close
                    </button>,
                        <button className="btn btn-primary" key="print" onClick={() => setIsPrintActive(true)}>
                            Print
                    </button>
                    ]}
            >
                {reportDataType == "" && <div className="d-flex justify-content-center my-3"><Spin /> </div>}
                {reportDataType == "empty" && <div>No Data is Available</div>}
                {reportDataType == "pdf" && <div className="pdf-data">
                <div className="PDF" id="showPDF">
                    {selectedResult ? <object id="pdfObject" data={"data:application/pdf;base64," + selectedResult[0].entry[0].resource.content[0].attachment.data} type="application/pdf"></object> : ""}

                </div>
                <p id="hee">
                    {pdfViewerLoading && <View style={{ justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></View>}
                </p>
                </div>}

                {
                    reportDataType == "html" &&
                        selectedResult ?

                        <div ref={componentRef}>
                            < PrintOrganizationHeader patientProfile={props.patientProfile} organizationData={props.organizationData} isPrintActive={isPrintActive} />
                            <div className="HTML" id="showHTML" dangerouslySetInnerHTML={{ __html: selectedResult[0].entry[0].resource.extension[1].valueString }} />
                        </div>
                        : null
                }

                {reportDataType == "table" && <div id="printModal" className="print-container" ref={componentRef}>
                    <PrintOrganizationHeader patientProfile={props.patientProfile} organizationData={props.organizationData} isPrintActive={isPrintActive} />
                    {/* {console.log("tbleee",tabledata)} */}
                    {tabledata && tabledata.length ?
                        <>

                          <div className="table-responsive">
                            <table className="table table-bordered  repot-details-table w-100">
                                <tr className="repot-details-table-row">
                                    <th className="repot-details-table-heading">Observation Text</th>
                                    <th className="repot-details-table-heading" style={{minWidth:'200px'}}>Observation Value</th>
                                    <th className="repot-details-table-heading">Abnormal Flag desc</th>
                                </tr>
                                {tabledata.map(item => {
                                    let setclassDanger = (item.AbnormalFlagDesc?.toLowerCase() == "abnormal" || item.AbnormalFlagDesc?.toLowerCase() == "low" || item.AbnormalFlagDesc?.toLowerCase() == "high" ? "text-danger" : "");
                                    return (<>
                                        <tr className={`${setclassDanger} repot-details-table-row`}>
                                            <td className="repot-details-table-value">{item.ObservationText}</td>
                                            <td className="repot-details-table-value" dangerouslySetInnerHTML={{ __html: formatObservationValue(item.ObservationValue) }}></td>
                                            <td className="repot-details-table-value">{item.AbnormalFlagDesc}</td>
                                        </tr>

                                        <tr>
                                            <td colSpan={3}>

                                                <div className={`${setclassDanger}`} style={{ paddingLeft: '0px' }} dangerouslySetInnerHTML={{ __html: item.ObservationSummary }} />
                                            </td>
                                        </tr>

                                       
                                    </>)

                                })}                             
                            </table>
                            </div>

                        </>
                        : <p>no data is available</p>
                    }

                </div>}
            </Modal>

            {/* Result Chart Modal */}
            <Modal
                title="Result Chart"
                open={labChartModalOpen}
                onOk={() => setLabChartModalOpen(false)}
                onCancel={() => setLabChartModalOpen(false)}
                width={700}
                height={700}
                cancelButtonProps={{ style: { display: 'none' } }}

            >
                <div>
                    {chartLoading ? <Spin /> :
                        readings && readings.length > 0 ?
                            <Chart
                                chartType="LineChart"
                                width="100%"
                                height="300px"
                                data={readings}
                                options={options}
                                legendToggle={true}
                            /> : <div className='text-center' >No Data Available</div>
                    }

                </div>

            </Modal>
        </>

    );
}

export default ReportsHandler
