import React, { useState, useEffect } from "react";
import { Select, Modal, Button, message, Upload } from "antd";
import { Input, notification, Spin } from "antd";
import { RadiusBottomleftOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { getMessageRecipientsAPI } from "../../../../shared/actions/MessagingActions/ListMessagesRecipients";
import { listMessageRolesAPI } from "../../../../shared/actions/MessagingActions/LoadMessagingRoles";
import { sendMessageAPI, sendMessageAPIRefresher } from "../../../../shared/actions/MessagingActions/SendingMessages";
import { connect } from "react-redux";
import PdfFileComp from "../MessageAttachments/PdfFileComp";
import JpgFileComp from "../MessageAttachments/JpgFileComp";
import AttachmentsHandler from "../MessageAttachments/AttachmentsHandler";
import { deleteMessageAPI, deleteMessageAPIRefresher } from "../../../../shared/actions/MessagingActions/DeleteMessages";
import getUserId from "../../../../shared/core/GetToken";
import Settings from "../../../../shared/config/Settings";
import { listDraftMessageAPI } from "../../../../shared/actions/MessagingActions/ListDraftMesssages";
import { listInboxMessageAPI } from "../../../../shared/actions/MessagingActions/ListInboxMessages";
import { listSendMessageAPI } from "../../../../shared/actions/MessagingActions/ListSendMessages";
import renderHTML from 'react-render-html';

const { Option } = Select;
const { TextArea } = Input;

const DraftEditMsgModal = (props) => {
  const { recipientsData, recipientsDataSuccess } = props.MessageRecipients
  const { rolesData, rolesDataSuccess } = props.MessagingRoles
//   const { sendMessage, sendMessageLoading, sendMessageSuccess, sendMessageFailed } = props.SendingMessage
  const { deleteMessage, deleteMessageLoading, deleteMessageSuccess } = props.DeleteMessage

  const [msgProviderLocaionId, setMsgProviderLocationId] = useState(`${props.msgContentRecipientRoleID}`);
  
  const [msgRecipientId, setMsgRecipientId] = useState(props.msgContentRecipientID? props.msgContentRecipientID.split("/")[1]
    : null);
  const [msgRecipientName, setMsgRecipientName] = useState(props.msgContentRecipientName ? props.msgContentRecipientName
    : null);
  const [msgSubject, setMsgSubject] = useState(props.msgContentSubject?props.msgContentSubject: "");
  const [msgBody, setMsgBody] = useState(props.msgContentBody? props.msgContentBody:"");
  const [msgBodyErrMsg, setMsgBodyErrMsg] = useState("");
  const [msgSubErrMsg, setsetMsgSubErrMsg] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(props.contentPayload ? props.contentPayload : [])
  const [draftLoadingState, setDraftLoadingState] = useState(false);
  const [sendLoadingState, setSendLoadingState] = useState(false);
  const [imageFiles, setImageFiles] = useState([])


  const onRolesChange = (value, option) => {
    setMsgProviderLocationId(value);
    setMsgRecipientId(null);
    const values = {
      roleID: value,
    };
    props.getMsgRecipients(values);
  };
  const onProviderChange = (value, option) => {
    setMsgRecipientId(option.key);
    setMsgRecipientName(option.children);
  };

  const checkValidations = () => {
    let caughtErr = false
    console.log("validatinggg", msgBody)

    if (msgBody == "") {
      setMsgBodyErrMsg("Please type something in the body of the message")
      caughtErr = true
    }
    if (msgSubject == "") {
      console.log("empptyyy boddd")
      setsetMsgSubErrMsg("Please type something in the subject of the message")
      caughtErr = true
    }
    return caughtErr
  }
  const sendMessage = (values) =>{

    getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          myHeaders.append("Token", item.token);
            var raw = JSON.stringify({
                resourceType: "Communication",
                extension: [
                  {
                    url: "MessageBody",
                    valueString: values.message.body,
                  },
                  {
                    url: "MessageType",
                    valueString: values.messageType,
                  },

                ],
                status: "completed",
                recipient: [
                  {
                    reference: `${values.practitioner.id}`,
                    display: values.practitioner.name,
                  },
                ],
                sender: {
                  reference: `Patient/${item.userid}`,
                  display: `${item.username}`,
                },
                payload: values.selectedFiles,
                note: [
                  {
                    text: values.message.subject,
                  },
                ],
              });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            const queryString = `?RoleID=${values.roleID}&CallType=3`
            fetch(Settings.apiUrl + "Patient/Message" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
            
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = `?PatientID=${item.userid}&MessageID=${props.messageID}`
            fetch(Settings.apiUrl + "Patient/DeleteMessage" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                     setSendLoadingState(false)
                    setDraftLoadingState(false)
                    // props.listSendMessages({type: 1, messagingType: 2})
                    // props.listDraftMessages({type: 1, messagingType: 3})
                    openNotification("topRight", values.messageType =="Draft" ? "Message Saved to Draft":"Message Sent Successfully", "success")
                    props.setIsMsgDeleted(true)
                    props.handleCancel()
                })
                .catch(err => console.log(err))

        
                })
                .catch(err => console.log(err))

        })
  }
  const handleSendClick = () => {
    setSendLoadingState(true)
    let validateErr = checkValidations()
    if (validateErr) {
        setSendLoadingState(false)
      return
    } else {
    const values = {
      
      practitioner: {
        id: "Practitioner/" + msgRecipientId,
        name: msgRecipientName,
      },
      message: {
        subject: msgSubject,
        body: msgBody,
      },
      selectedFiles: selectedFiles,
      messageType: "Send Mail",
      roleID: msgProviderLocaionId,
    };
    
    sendMessage(values);

    }
};
  const handleDraftClick = () => {
    setDraftLoadingState(true)
    let validateErr = checkValidations()
    if (validateErr) {
        setDraftLoadingState(false)
      return
    }
    const values = {
      
      practitioner: {
        id: "Practitioner/" + msgRecipientId,
        name: msgRecipientName,
      },

      message: {
        subject: msgSubject,
        body: msgBody,
      },
      selectedFiles: selectedFiles,
      messageType: "Draft",
      roleID: msgProviderLocaionId,

    };
    sendMessage(values);

  };
   const handleRemoveFile =(index)=>{
    if(selectedFiles.length >0){
      setSelectedFiles(selectedFiles.filter((file, fileIndex)=> fileIndex != index))
    }
  }
const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
const pdfTypeRegex = /application\/(pdf)/gm;
const selectFilesHandler = (e) => {
    const { files } = e.target;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match(imageTypeRegex) || file.type.match(pdfTypeRegex)) {
        validImageFiles.push(file);
      }
    }
    if (validImageFiles.length) {
      setImageFiles(validImageFiles);
      return;
    }
    alert("Selected images are not of valid type!");
  };
  useEffect(() => {
    const images = [], fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        let data ={}
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
             data = {
                contentAttachment: {
                  contentType: result.split("/")[1].split(";base64")[0],
                  data: result.split("base64,")[1]
                
              }
            }
            images.push(data)
          }
          if (images.length === imageFiles.length && !isCancel) {
            setSelectedFiles((prev) => prev.concat(images));
          }
        }
        fileReader.readAsDataURL(file);
      })
    };
    return () => {
      isCancel = true;
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFiles]);

const openNotification = (placement, msg, type) => {

    notification[type]({
      message: msg,
      placement,
    });
  };
  useEffect(()=>{
    props.getMsgRecipients({
        roleID: props.msgContentRecipientRoleID ? props.msgContentRecipientRoleID : 0
      })
  },[])
 
  return (
    <>
      <Modal
        title="Draft Message"
        width={800}
        style={{ top: "50px" }}
        open={props.isModalOpen}
        onCancel={props.handleCancel}
        footer={[
          <Button
            key="cancel"
            onClick={props.handleCancel}
            className="cancel-msg-btn"
          >
            Cancel
          </Button>,
          <Button
            key="draft"
            onClick={handleDraftClick}
            className="draft-msg-btn"
            loading={draftLoadingState}
          >
            Draft
          </Button>,
          <Button key="send" onClick={handleSendClick} loading={sendLoadingState} className="send-msg-btn">
            Send
          </Button>,
        ]}
      >
        {props.draftDisclaimerMsg && props.draftDisclaimerMsg != "" &&
                 <div className="row alert alert-warning compose-msg-diclaimer">
                    <h6 className="alert-heading">Disclaimer:</h6>
                    {
                          renderHTML(props.draftDisclaimerMsg)
                    }

        </div>}
        {!rolesDataSuccess ? <Spin /> :
        <div className="row">
        <label style={{fontWeight: 'bolder'}} className="col-3 ">Role</label>
          <Select
            showSearch
          className="col-8"
            placeholder="To"
            optionFilterProp="children"
            style={{ width: "100%", borderRadius: "20px" }}
            onChange={onRolesChange}
            defaultValue={
              msgProviderLocaionId
            }
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {rolesDataSuccess
              ? rolesData[0].entry.map((data) => (
                <Option
                  value={data.resource.id}
                  key={data.resource.id}
                >
                  {data.resource.title}
                </Option>
              ))
              : null}
          </Select></div>}
          
          <div className="row">

<label style={{fontWeight: 'bolder', marginTop: "15px"}} className="col-3 ">Practitioner</label>
        <Select
          showSearch
          className="col-8"
          placeholder="Select Provider"
          optionFilterProp="children"
          style={{ width: "100%", marginTop: "15px" }}
          defaultValue={
            msgRecipientId
          }
          onChange={onProviderChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {recipientsDataSuccess && recipientsData[0].entry
            ? recipientsData[0].entry.map((data) => (
              <Option
                value={data.resource.extension[0].valueString}
                key={data.resource.extension[0].valueString}
              >
                {data.resource.name[0].text}
              </Option>
            ))
            : null}
        </Select>
        </div>

             <div className="row">
              <label style={{fontWeight: 'bolder', marginTop: "15px"}} className="col-3">Subject</label>

        <Input
          className="col-8"
          placeholder="Subject"
          style={{ marginTop: "15px" }}
          value={msgSubject}
          onChange={(e) => { setMsgSubject(e.target.value); setsetMsgSubErrMsg("") }}
        /></div>
        <p className="text-danger">{msgSubErrMsg}</p>
        <TextArea
          // value={value}
          // onChange={(e) => setValue(e.target.value)}
          placeholder="Write your message here"
          autoSize={{ minRows: 10, maxRows: 10 }}
          style={{ width: "100%", marginTop: "15px" }}
          value={msgBody}
          onChange={(e) => { setMsgBody(e.target.value); setMsgBodyErrMsg("") }}
          maxLength={800}
        />
        <p className="text-danger">{msgBodyErrMsg}</p>
      <label htmlFor="file" className="p-2 text-primary fw-bold mt-3 px-3 choose-file">Choose Files
        </label>
        <input
            type="file"
            id="file"
            onChange={selectFilesHandler}
            accept="image/png, image/jpg, image/jpeg,application/pdf"
            multiple
          />
         {
        selectedFiles && selectedFiles.length > 0 ?
          <AttachmentsHandler selectedFiles={selectedFiles} type="composing" handleRemoveFile={handleRemoveFile}/>:null
      }
      </Modal>
    </>
  );
};


function mapStateToProps(state) {
  return {
    TokenValidator: state.TokenValidator,

    MessageRecipients: state.MessagesRecipientsReducer,
    SendingMessage: state.SendingMessagesReducer,
    MessagingRoles: state.LoadMessagingRolesReducer,
    DeleteMessage: state.DeleteMessagesReducer,

  }
}
function mapDispatchToProps(disptach) {
  return {

    getMsgRecipients: (values) => disptach(getMessageRecipientsAPI(values)),
    loadMsgRoles: () => disptach(listMessageRolesAPI()),
     listDraftMessages: (values) => disptach(listDraftMessageAPI(values)),
    listInboxMessages: (values) => disptach(listInboxMessageAPI(values)),
    listSendMessages: (values) => disptach(listSendMessageAPI(values)),
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftEditMsgModal)
// export default
