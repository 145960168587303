import React, { useCallback, useState, useEffect } from 'react';
import { Button, View, Text, SafeAreaView, Dimensions, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity } from 'react-native-web';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { fetchResourcesFromApi, fetchMoreResourcesFromApi } from "../../shared/actions/ResourcesActions";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'

import { Card, Col, Row } from 'antd';
import 'antd/dist/antd.css';
import ResourcesHandler from "../components/ResourcesHandler";
import "../components/css/drive.css";


import { checkToken } from '../../shared/actions/TokenValidActions';

const useNavigate = require("react-router-dom").useNavigate;
const gridStyle = {
    width: '50%',
    textAlign: 'center',
};
const ResourcesHome = props => {
    const { Resources, isGetting } = props.Resources;

    const history = useNavigate()
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);
    const [isloaded, setIsload] = React.useState(false);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])


    useEffect(() => {
        props.getResources(pageSize, pageNumber);
    }, [])
    useEffect(() => {
        console.log("isGetting = ", isGetting)
    }, [isGetting])
    React.useEffect(() => {
        props.tokenVal(history);
    }, [])

    const checkItems = resourceInfo => {
        alert('yooyoyoyoy')
    }
    return (
       
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Resources</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row row-margin-zero">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                           
                                            <div className="item-wrapper">

                                                <div className="drive-wrapper drive-grid-view">
                                                    <div className="grid-items-wrapper">
                                                        {
                                                            isGetting && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                        }
                                                        {
                                                            // Resources != null && Resources != undefined && Resources.map((item) => {
                                                            //     return (
                                                            //         <ResourcesHandler />
                                                            //     )
                                                            // })

                                                            typeof (Resources) !== 'undefined' && Resources.length ? (
                                                                Resources.map((item) => {
                                                                    return (
                                                                        <ResourcesHandler
                                                                            name={item.resource.content.title}
                                                                            responseType={item.resource.content.hasOwnProperty('contentType') ? item.resource.content.contentType === 'PDF' ? 'fa fa-file-pdf-o text-danger' : 'fa fa-link text-warning' : 'fa fa-link text-warning'}
                                                                            contentType={item.resource.content.hasOwnProperty('contentType') ? item.resource.content.contentType === 'PDF' ? 'PDF' : 'URL' : 'URL'}
                                                                            contentFlow={item.resource.content.hasOwnProperty('contentType') ? item.resource.content.contentType === 'PDF' ? item.resource.id : item.resource.content.url : item.resource.content.url}
                                                                        />
                                                                    )
                                                                })
                                                            ) :
                                                                !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                        <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                                                                </View>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                 
    )
}


function mapStateToProps(state) {
    return {
        Resources: state.Resources
    }
}

function mapDispatchToProps(disptach) {
    return {
        getResources: (pageSize, pageNumber) => disptach(fetchResourcesFromApi(pageSize, pageNumber)),
        tokenVal: (history) => disptach(checkToken(history))

        //getMoreResources: (token, pageSize, pageNumber) => disptach(fetchMoreResourcesFromApi(token, pageSize, pageNumber))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourcesHome)
//export default ResourcesHome
