import { MESSAGE_BYID_LISTING, MESSAGE_BYID_LISTING_SUCCESS, MESSAGE_BYID_LISTING_LOADING, MESSAGE_BYID_LISTING_ERROR, MESSAGE_BYID_LISTING_FAILED, MESSAGE_BYID_LISTING_CLEAR } from '../../constants/Constant'
import getUserId from "../../core/GetToken";
import Settings from '../../config/Settings';




/******************************** LT *******************************************/

//return long terms diagnosis
export const getMessageByIdAPI = (values) => {
    return (dispatch) => {
        dispatch(gettingMessageById())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = `?PatientID=${item.userid}&MessageID=${values.messageId}`
            fetch(Settings.apiUrl + "Patient/MessageByID" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                    json[0].total = values.messageNum ? values.messageNum :  1
                    dispatch(gettingMessageByIdSuccess(json))
                })
                .catch(err => dispatch(gettingMessageByIdFailed(err)))

        })
    }
}

export const clearMessageByIdData = ()=>{
    return (dispatch)=>{
        dispatch(gettingMessageByIdClear())
    }
}



//ALL DIAGNOSIS LOADS HERE
const gettingMessageById = () => {
    return {
        type: MESSAGE_BYID_LISTING
    }
}
const gettingMessageByIdClear = () => {
    return {
        type: MESSAGE_BYID_LISTING_CLEAR
    }
}

const gettingMessageByIdSuccess = (data) => {
    return {
        type: MESSAGE_BYID_LISTING_SUCCESS,
        data,
    }
}

const gettingMessageByIdFailed = () => {
    return {
        type: MESSAGE_BYID_LISTING_FAILED
    }
}
