import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Text,
    ActivityIndicator,
    Dimensions
} from 'react-native-web';
import { connect } from 'react-redux'
import ImmuneHandler from "../../components/ImmuneHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchOverdueImmune } from "../../../shared/actions/OverdueImmuneActions";
import { useReactToPrint } from 'react-to-print';
import PrintAppointmentHeader from '../../components/PrintAppointmentHeader';
import { Tooltip } from 'antd';

const OverdueTab = props => {

    const [pageSize, setPageSize] = useState(100);
    const [pageNumber, setPageNumber] = useState(1);
    const { OverdueImmune, isGettingImmuneOverdue } = props.OverdueImmune;
    const { Profile } = props.Profile;
    const { Practise } = props.Practise;
    const [isPrintActive, setIsPrintActive] = useState(false);


    useEffect(() => {
        //alert('Called Future')
        props.getImmuneOverdue(pageSize, pageNumber);
    }, [])
    const componentRef = useRef();
    const pageStyleCss = `@page {
        size: A4 landscape;
      }
      *{
          font-family: sans-serif;
        }
         .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        h3,h4,h5,p{
            margin:0px;
        }
        p span{
            font-weight: 600;
        }
        .ind-med{
            border-bottom: 1px solid grey;
            padding:5px 10px; 
        }
        .card-text, .card-title, .med-date{
            color: black !important;
            font-weight: 300 !important;
        }
        .med-heading{
            color: black !important;
            font-weight: 600 !important;
            margin-bottom: 3px;
        }
     `;
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: false,
        pageStyle: pageStyleCss,
    })
    useEffect(() => {
        if (isPrintActive) {
            handlePrint()
            setIsPrintActive(false)
        }
    }, [isPrintActive])
    return (
        <div className="d-flex flex-column">
            {
                isGettingImmuneOverdue && <View style={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (OverdueImmune) !== 'undefined' && OverdueImmune.length ? (
                    <>
                        <Tooltip title="Print" className="print-icon d-flex align-self-end justify-content-end align-items-center" style={{ marginRight: "20px" }} onClick={() => { setIsPrintActive(true) }}>
                           <div className='print-immune-btn btn btn-primary mr-2'>
                            Print
                           </div>
                            {/* <p className="m-0 print-text">Print</p> */}
                        </Tooltip>
                        {/* <button className="btn btn-danger align-self-end" style={{ marginRight: "20px" }} onClick={() => setIsPrintActive(true)}>Print</button> */}
                        <div id="printModal" ref={componentRef} >
                            <PrintAppointmentHeader organizationData={Practise} patientProfile={Profile} isPrintActive={isPrintActive} />
                    <FlatList
                        style={{
                            flexDirection: 'column',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        data={OverdueImmune}
                        renderItem={({ item }) => (
                            <ImmuneHandler
                                styles={'card-header text-white bg-danger'}
                                date={GetAppointmentDate(item.resource.date) +
                                    " " +
                                    GetMonthName(item.resource.date) +
                                    " " +
                                    GetYear(item.resource.date)}
                                   
                                heading={item.resource.extension[0].valueString} //{GetHeading(item)}
                               // date={GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime)+ ' '+GetYear(item.resource.extension[7].valueDateTime)}
                                month={GetYear(item.resource.extension[12].valueDateTime)}
                                // type_diagnoes={item.resource.extension[0].valueString}
                                //indication={item.resource.extension[5].hasOwnProperty('valueString') ? item.resource.extension[5].valueString : '-'}
                                //vacc={item.resource.extension[6].hasOwnProperty('valueString') ? item.resource.extension[6].valueString : '-'}
                                indication={' N/A '}
                                vacc={' N/A '}
                                datedue={'Was due: ' + GetAppointmentDate(item.resource.date) +
                                " " +
                                GetMonthName(item.resource.date) +
                                " " +
                                GetYear(item.resource.date)}
                            />
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}

                    />
                        </div>
                    </>

                ) : !isGettingImmuneOverdue && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                        <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                </View>

            }
        </div>
    );
}



function mapStateToProps(state) {
    return {
        OverdueImmune: state.OverdueImmune,
        Profile: state.Profile,
        Practise: state.Practise
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getImmuneOverdue: (pageSize, pageNumber) => dispatch(fetchOverdueImmune(pageSize, pageNumber)),
        // getImmuneMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreOverdueImmune(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OverdueTab)

// export default OverdueTab
