import React, { useEffect, useMemo, useState } from "react";
import { Tabs, Spin, notification, Checkbox, Tooltip, Space } from "antd";
import { Button, Modal, Badge } from "antd";
import InboxMessages from "./MessageTypes/InboxMessages";
import DraftMessages from "./MessageTypes/DraftMessages";
import SentMessages from "./MessageTypes/SentMessages";
import ComposeMsgModal from "./Modals/ComposeMsgModal";
import { listDraftMessageAPI } from "./../../../shared/actions/MessagingActions/ListDraftMesssages";
import { listInboxMessageAPI } from "./../../../shared/actions/MessagingActions/ListInboxMessages";
import { listSendMessageAPI } from "./../../../shared/actions/MessagingActions/ListSendMessages";
import { getMessageRecipientsAPI } from "./../../../shared/actions/MessagingActions/ListMessagesRecipients";
import { clearMessageByIdData, getMessageByIdAPI } from "./../../../shared/actions/MessagingActions/ListMessagesById";
import { listMessageAPI } from "./../../../shared/actions/MessagingActions/ListMessages";
import { sendMessageAPI, sendMessageAPIRefresher } from "./../../../shared/actions/MessagingActions/SendingMessages";
import { listMessageRolesAPI } from "./../../../shared/actions/MessagingActions/LoadMessagingRoles";
import { connect } from "react-redux";
import { deleteMessageAPI } from "../../../shared/actions/MessagingActions/DeleteMessages";
import { DownOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { ButtonGroup } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import getUserId from "../../../shared/core/GetToken";
import Settings from "../../../shared/config/Settings";
import { fetchCounterFromApi } from "../../../shared/actions/CounterActions";

const MessageListing = (props) => {
  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false);
    const [isDeleteMultipleModalOpen, setIsDeleteMultipleModalOpen] = useState(false);
    const [selectMsgsArr, setSelectMsgsArr] = useState([]);
    const [currentTabKey, setCurrentTabKey] = useState("1");
  const [messagesRefreshTrigger, setMessagesRefreshTrigger] = useState(0);
    const [messageTypes, setMessageTypes] = useState([
      {key: "1", name: "All"},
      {key: "2", name: "Read"},
      {key: "3", name: "Unread"},
    ]);
  const { Counter } = props.Counter
    const [activeInboxMessageType, setActiveInboxMessageType] = useState("All")
  const [selectAllMsgsChecked, setSelectAllMsgsChecked] = useState(false);
const { inboxMessageListing, inboxMessageListingSuccess, inboxMessageListingLoading } = props.InboxMessages
    const { sendMessageListing, sendMessageListingSuccess, sendMessageListingLoading } = props.SendMessages
    const { draftMessageListing, draftMessageListingSuccess, draftMessageListingLoading } = props.DraftMessages

  const { rolesData, rolesDataSuccess } = props.messageRoles

  const { sendMessage, sendMessageSuccess, sendMessageFailed } = props.SendingMessage
  const { deleteMessage, deleteMessageSuccess, deleteMessageLoading } = props.DeleteMessage

  const listMessageRecipients = () => {

    if (rolesDataSuccess) {
      props.getMsgRecipients({
        roleID: rolesData.length > 0 ? rolesData[0]?.entry[0]?.resource?.id : 0
      })
    }
  }
  const listMessagingRoles = () => {
    props.loadMsgRoles()
  }

  const openNotification = (placement, message, type) => {
    notification[type]({
      message: message,
      placement,
    });
  };

  useEffect(() => {
    if (sendMessageSuccess) {
      openNotification("topRight", sendMessage, "success")
      setIsComposeModalOpen(false)
       props.getMsgRecipients({roleID: rolesData.length > 0 ? rolesData[0]?.entry[0]?.resource?.id : 0 })
    } else if (sendMessageFailed) {
      openNotification("topRight", "Message Sending Failed", "error")
      setIsComposeModalOpen(false)
    }

  }, [sendMessageSuccess])

  useEffect(() => {
    // inboxMessagesAPI()
    // sendMessagesAPI()
    // draftMessagesAPI()

    listMessagingRoles()
  }, []);

 const onSelectedMsgsChange = (value)=>{
    console.log("select msggg  change", value)
    let abc = false;
        if (selectMsgsArr.length == 0) {
            setSelectMsgsArr([...selectMsgsArr, value])
        } else {
            setSelectMsgsArr(selectMsgsArr.filter((item, index) => {
               if(item == value){
                abc=true
                return false
               }else{
                return true
               }
            }))
            if (!abc) {
                setSelectMsgsArr([...selectMsgsArr, value])
            }
        }
  }
  const handleAllMsgSelect = (type = "inbox")=>{
      if(selectAllMsgsChecked){
          if(currentTabKey == "1" && inboxMessageListing && inboxMessageListing[0].entry){
            setSelectMsgsArr([...inboxMessageListing[0].entry])
        }
          if(currentTabKey == "2" && sendMessageListing && sendMessageListing[0].entry){
              
            setSelectMsgsArr([...sendMessageListing[0].entry])
        }
          if(currentTabKey == "3" && draftMessageListing && draftMessageListing[0].entry){
              
            setSelectMsgsArr([...draftMessageListing[0].entry])
        }
    }else{
        setSelectMsgsArr([])
    }
  }
const handleCheckChanged=(val)=>{
        onSelectedMsgsChange(val)
    }
 useEffect(()=>{
        console.log("al messages selcted ", selectAllMsgsChecked)
            handleAllMsgSelect()
        
    },[selectAllMsgsChecked])
useEffect(()=>{
  console.log("select msg array after change, ", selectMsgsArr)
},[selectMsgsArr])
useEffect(()=>{
  console.log("active filter changed, ", activeInboxMessageType)
},[activeInboxMessageType])

    const handleMultipleDeleteBtnClick=()=>{
      console.log("selected msgg arr length => ", selectMsgsArr)
      if(selectMsgsArr.length > 0){
        setIsDeleteMultipleModalOpen(true)
      }else{
      openNotification("topRight", "No Message Selected", "error")

      }
    }

    const dropDownItems=[{
    label: (<p >All</p>),
    key: '0',
  }
    ]
    const handleMultipleDeleteOk = ()=>{
                let strOfIDs = ""
                console.log("stringified arr of ids", selectMsgsArr)
                if(selectMsgsArr.length>0){
                    strOfIDs = strOfIDs + (selectMsgsArr.map(msg => {return msg.resource.id})) + ", "
                }
                console.log("stringified arr of ids", strOfIDs)
                props.deleteMessage({messageID: strOfIDs})
                setIsDeleteMultipleModalOpen(false)
              
              }
  const handleRefreshMessages = () => {
    props.getCounter()
    setMessagesRefreshTrigger(messagesRefreshTrigger + 1)
  };

  const OperationsSlot = {
    left: <div className="d-flex mr-1">
    {/* <Dropdown as={ButtonGroup}> */}
    {/* <Checkbox className=" py-1 px-2 bg-secondary " style={{float:'right'}} checked={selectAllMsgsChecked} onChange={()=>{setSelectAllMsgsChecked(!selectAllMsgsChecked)}}/> */}
    <div  className="py-1 pr3point">

   <Form.Check className="message-list-main-checkbox" checked={selectAllMsgsChecked} onChange={()=>setSelectAllMsgsChecked(!selectAllMsgsChecked)} />
    </div>
     {currentTabKey == "1" ? <>
    <DropdownButton size="sm" variant="Secondary">
    {
          messageTypes.map(type=>{
            return <Dropdown.Item active={activeInboxMessageType == type.name} className={activeInboxMessageType == type.name ? "bg-primary text-light":""} onClick={(e)=>setActiveInboxMessageType(e.target.text)} key={type.key} >{type.name}</Dropdown.Item>
            
          })
        }

      {/* <Dropdown.Item href="#/action-1">All</Dropdown.Item>
      <Dropdown.Item href="#/action-2">Read</Dropdown.Item>
      <Dropdown.Item href="#/action-3">Unread</Dropdown.Item> */}
    </DropdownButton>  </>: <div style={{width:"17px"}}></div>}
     {/* <Dropdown.Toggle split variant="secondary" id="dropdown-basic" style={{float:'right', width:'10px', height:"41px"}} >
      </Dropdown.Toggle>

      <Dropdown.Menu >
        {
          messageTypes.map(type=>{
            return <Dropdown.Item onClick={(e)=>setActiveInboxMessageType(e.target.text)} key={type.key} >{type.name}</Dropdown.Item>
            
          })
        }
      </Dropdown.Menu>
        </>: <div style={{width:"17px"}}></div> */}
     
    {/* </Dropdown> */}
    </div> 
    ,
  right: <div className="d-flex">
    <Tooltip title="Compose Message"><div className="mx-1 compose-msg-icon" onClick={() => setIsComposeModalOpen(true)} style={{width:"30px", height:"30px", backgroundColor:"#88a8d4", cursor:"pointer", borderRadius:"5px"}}>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground: 'new 0 0 64 64'}} xmlSpace="preserve">
  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n    .st0 {\n      fill: #FFFFFF;\n    }\n  " }} />
  <g>
    <g id="Icon-Envelope" transform="translate(78.000000, 232.000000)">
      <path id="Fill-3" className="st0" d="M-22.5-213.2l-1.9-1.9L-42-197.5c-2.2,2.2-5.9,2.2-8.1,0L-67.7-215l-1.9,1.9l13.1,13.1
			l-13.1,13.1l1.9,1.9l13.1-13.1l2.6,2.6c1.6,1.6,3.7,2.5,5.9,2.5s4.3-0.9,5.9-2.5l2.6-2.6l13.1,13.1l1.9-1.9L-35.7-200L-22.5-213.2
			" />
      <path id="Fill-4" className="st0" d="M-26.2-181.6h-39.5c-2.3,0-4.2-1.9-4.2-4.2V-214c0-2.3,1.9-4.2,4.2-4.2h39.5
			c2.3,0,4.2,1.9,4.2,4.2v28.2C-22-183.5-23.9-181.6-26.2-181.6L-26.2-181.6z M-65.8-215.5c-0.8,0-1.4,0.6-1.4,1.4v28.2
			c0,0.8,0.6,1.4,1.4,1.4h39.5c0.8,0,1.4-0.6,1.4-1.4v-28.2c0-0.8-0.6-1.4-1.4-1.4H-65.8L-65.8-215.5z" />
    </g>
  </g>
</svg>


          </div></Tooltip>
    <Tooltip title="Reload"><div style={{ width: "30px", height: "30px", padding: "5px", backgroundColor: "#88a8d4", cursor: "pointer", borderRadius: "5px" }} className="mx-1" onClick={handleRefreshMessages}>
            <svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
            </div></Tooltip>

            <Tooltip title="Delete selected messages">

       <div style={{width:"30px", height:"30px", padding:"4px 7px 8px 8px",backgroundColor:"#88a8d4", cursor:"pointer", borderRadius:"5px"}} className="mx-1" onClick={handleMultipleDeleteBtnClick}>
<svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg>
        </div>
        </Tooltip>
  </div>,
};
 const [position, setPosition] = useState(["left",'right']);
const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {},
    );
  }, [position, selectMsgsArr, selectAllMsgsChecked, currentTabKey, activeInboxMessageType]);

  return (

    <div className="msgListing" >
      <div className="topNav ">
        <Tabs
        tabBarExtraContent={slot}
          defaultActiveKey="1"
          start
          type="card"
        tabBarGutter={0}
          size="large"
          onChange={(event) => {
            setCurrentTabKey(event)
            console.log("tab key`", typeof (event))
            setSelectAllMsgsChecked(false)
            props.messageClearData()
          }}
          items={[
            {
              label: <Badge color="blue" count={Counter ? Counter.InboxCounter : 0}><div style={{ paddingTop: "14px" }}>Inbox</div></Badge>,
              key: '1',
              children: <>
                
                <InboxMessages type="inbox" currentActiveFilter={activeInboxMessageType} handleCheckChanged={handleCheckChanged} selectAllMsgsChecked={selectAllMsgsChecked} refreshTrigger={messagesRefreshTrigger} />
              </>
            },
            {
              label: <div style={{ paddingTop: "14px" }}>Sent</div>,
              key: '2',
              children: <>
              
                <SentMessages type="send" handleCheckChanged={handleCheckChanged} selectAllMsgsChecked={selectAllMsgsChecked} refreshTrigger={messagesRefreshTrigger} />
              </>
            },
            {
              label: <div style={{ paddingTop: "14px" }}>Draft</div>,
              key: '3',
              children: <>
              
                <DraftMessages type="draft" handleCheckChanged={handleCheckChanged} selectAllMsgsChecked={selectAllMsgsChecked} refreshTrigger={messagesRefreshTrigger} />
              </>
            }
          ]}
        />
      </div>
      {/* <div
        className="composeMsgIcon d-flex align-items-center justify-content-center"
        onClick={() => setIsComposeModalOpen(true)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <path fill="white" d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z" /></svg>
        <span className="icon-text">COMPOSE</span>
      </div> */}
      {isComposeModalOpen && (
        <ComposeMsgModal
          handleCancel={() => { 
            props.getMsgRecipients({roleID: rolesData.length > 0 ? rolesData[0]?.entry[0]?.resource?.id : 0 })
            setIsComposeModalOpen(false) }}
          handleOk={() => { setIsComposeModalOpen(false) }}
          isComposeModalOpen={isComposeModalOpen}
          disclaimerMsg = {props.disclaimerMsg}
        />)}
         <Modal
              title="Delete Multiple Messages"
              open={isDeleteMultipleModalOpen}
              onOk={handleMultipleDeleteOk}
              okText="Delete"
              onCancel={()=>setIsDeleteMultipleModalOpen(false)}
              >
                Are you sure you want to delete these messages?
              </Modal>
    </div>

  );
};


function mapStateToProps(state) {
  return {

    InboxMessages: state.InboxMessagesReducer,
    DraftMessages: state.DraftMessagesReducer,
    SendMessages: state.SendMessagesReducer,
    AllMessages: state.ListMessagesReducer,
    messageByID: state.MessageByIdReducer,
    MessageRecipients: state.MessagesRecipientsReducer,
    SendingMessage: state.SendingMessagesReducer,
    DeleteMessage: state.DeleteMessagesReducer,
    messageRoles: state.LoadMessagingRolesReducer,
    Counter: state.Counter,

  }
}
function mapDispatchToProps(disptach) {
  return {

    listDraftMessages: (values) => disptach(listDraftMessageAPI(values)),
    listInboxMessages: (values) => disptach(listInboxMessageAPI(values)),
    listSendMessages: (values) => disptach(listSendMessageAPI(values)),
    listAllMessages: (values) => disptach(listMessageAPI(values)),
    getMsgRecipients: (values) => disptach(getMessageRecipientsAPI(values)),
    messageClearData: ()=> disptach(clearMessageByIdData()),
    sendMessage: (values) => disptach(sendMessageAPI(values)),
    sendMessageRefreshData: () => disptach(sendMessageAPIRefresher()),
        deleteMessage: (values) => disptach(deleteMessageAPI(values)),
    getCounter: () => disptach(fetchCounterFromApi()),

    getMsgByID: (values) => disptach(getMessageByIdAPI(values)),
    loadMsgRoles: () => disptach(listMessageRolesAPI()),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageListing)
// export default
