import { Checkbox, Dropdown, Modal, Space, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import MsgCard from '../MessageListingComp/MsgCard';
import { getMessageByIdAPI } from "../../../../shared/actions/MessagingActions/ListMessagesById";
import { connect } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { deleteMessageAPI } from '../../../../shared/actions/MessagingActions/DeleteMessages';
import { listInboxMessageAPI } from '../../../../shared/actions/MessagingActions/ListInboxMessages';
import InfiniteScroll from "react-infinite-scroll-component";

const InboxMessages = (props) => {
    
    const [activeMessage, setActiveMessage] = useState({});
    const [isComposeModalOpen, setIsComposeModalOpen] = useState(false);
    const { inboxMessageListing, inboxMessageListingSuccess, inboxMessageListingLoading } = props.InboxMessages
      const { sendMessage, sendMessageSuccess, sendMessageFailed } = props.SendingMessage

  const [inboxMessages, setInboxMessages] = useState([]);
  const [totalRecords, setTotalRecords] = useState(10)
    let limit = 25
    let offset = 1
  const { deleteMessage, deleteMessageSuccess } = props.DeleteMessage

//   let selectMsgsArr = []
    // let totalRecords = 100
    const showComposeModal = () => {
        setIsComposeModalOpen(true)
    }
    const getMsgProfileColor = (index)=>{
        let colorList = ["#ffa358","#f65747", "#47b2f6"]
        let colorIndex = index
        if(colorIndex > (colorList.length - 1)){
            colorIndex = index % colorList.length
        }
        return colorList[colorIndex]
    }
     const getInboxMessagesAPI =  () => {
        console.log("totalRecords, ", totalRecords)
         limit = 25;
            offset = Math.ceil(inboxMessages.length / limit)+1;

            props.listInboxMessages({ type: 1, messagingType: 1,  pageNumber:offset, pageSize: limit, readFilter: (props.currentActiveFilter == "Read" ? 2 : props.currentActiveFilter == "Unread" ? 1: 0 ) })
  };
    const getInboxMessagesFromStartAPI = () => {
        limit = 25;
            offset = 1;

            props.listInboxMessages({ type: 1, messagingType: 1,  pageNumber:offset, pageSize: limit, readFilter: (props.currentActiveFilter == "Read" ? 2 : props.currentActiveFilter == "Unread" ? 1: 0 ) })
  };

    // useEffect(()=>{
    //     getInboxMessagesAPI()
    // },[])
    
    
    useEffect(() => { 
        console.log(inboxMessageListing)
        if (inboxMessageListingSuccess && inboxMessageListing) {
            console.log("inbox messagessss", inboxMessageListing)
            setTotalRecords(inboxMessageListing[0].total)
            if(inboxMessageListing[0]?.entry){
                const mergeData= [...inboxMessages,...inboxMessageListing[0]?.entry];
                    console.log("mergeData",mergeData)
                    setInboxMessages(mergeData);

            }
    
    }
        
    }, [inboxMessageListing])

    const fetchmoreInboxData=(ignore = false)=>{
    if(ignore || inboxMessages.length < totalRecords)
    {
      getInboxMessagesAPI();
    }
}
    const handleFilterChange = ()=>{
    props.listInboxMessages({ type: 1, messagingType: 1,  pageNumber:1, pageSize: 10, readFilter: (props.currentActiveFilter == "Read" ? 2 : props.currentActiveFilter == "Unread" ? 1: 0 ) })
    }
    useEffect(()=>{
        if(deleteMessageSuccess){
            // handleDeleteMessage()
            setInboxMessages([])
            getInboxMessagesFromStartAPI()
        }
    },[deleteMessageSuccess])
    useEffect(()=>{
        if(sendMessageSuccess){
            // handleDeleteMessage()
            setInboxMessages([])
            getInboxMessagesFromStartAPI()
        }
    },[sendMessageSuccess])
    useEffect(()=>{
        if (props.refreshTrigger > 0) {

            setInboxMessages([])
            getInboxMessagesFromStartAPI()
        }
    }, [props.refreshTrigger])
    useEffect(() => {
        console.log("child component", props.currentActiveFilter)
        setInboxMessages([])
        setTotalRecords(10)
        getInboxMessagesFromStartAPI(true)
    },[props.currentActiveFilter])
    return (
        <>
        {/* <div id="scrollableDiv" style={{ height: 300, width: 200, overflow: 'auto',  flexDirection: 'column-reverse', }}>
        <InfiniteScroll
          dataLength={passengers.length}
          next={fetchmoreData}
          hasMore={passengers.length < totalRecords}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {passengers && passengers.map((i, index) => (
            <div style={style} key={index}>
              {i.name} - #{index}
            </div>
          ))}
        </InfiniteScroll>
      </div> */}
            <div className="msgList">
                <div id="scrollableSentDiv" style={{ height: "100%", width: "100%", overflow: 'auto',  flexDirection: 'column-reverse', }}>
                {/* {inboxMessageListingLoading && <div style={{justifyContent:"center",alignItems:"center"}} className="d-flex "><Spin /></div>} */}
                {/* {console.log("messages ", (allMessages ? allMessages:"null"))} */}
                {inboxMessageListing !=null && inboxMessages.length == 0 && !inboxMessageListingLoading && <div className="d-flex justify-content-center mt-3 text-secondary">{ props.currentActiveFilter == "All" ? "Inbox is empty": props.currentActiveFilter == "Read" ? "No Read Messages": "No Unread Messages"}</div>}
              
                <InfiniteScroll
          dataLength={inboxMessages.length}
          next={fetchmoreInboxData}
          hasMore={inboxMessages.length < totalRecords}
          loader={<div className='d-flex justify-content-center my-2 mt-3'><Spin/></div>}
          
          scrollableTarget="scrollableSentDiv"
        >
                {inboxMessages.length >0 ? inboxMessages.map((message, index) => (
                    <MsgCard
                        handleCheckChanged = {()=>props.handleCheckChanged(message)}
                        msgChecked={props.selectAllMsgsChecked}
                        key={message.resource.id}
                        sender={message?.resource?.extension[2]?.valueString}
                        subject={message?.resource?.note ? message?.resource?.note[0]?.text : ""}
                        date={
                            message?.resource?.sent?.slice(8, 10) +
                            "-" +
                            message?.resource?.sent?.slice(5, 7) +
                            "-" +
                            message?.resource?.sent?.slice(0, 4)
                        }
                        imgSrc={null}
                        isRead={
                            message?.resource?.extension[5]?.valueString == "2"
                        }
                        onSelect={() => {
                            localStorage.setItem("activeMessage", JSON.stringify(message));
                            setActiveMessage(message);
                            props.getMsgByID({ messageId: message.resource.id, messageNum: (index+1) })
                        }}
                        isActive={
                            localStorage.getItem("activeMessage") &&
                                JSON.parse(localStorage.getItem("activeMessage")).resource.id ===
                                message.resource.id
                                ? true
                                : false
                        }
                        profileColor={getMsgProfileColor(index)}
                    />
                )) : ""}
                </InfiniteScroll>
                </div>
               
            </div>
            {/* <div
              className="composeMsgIcon d-flex align-items-center justify-content-center"
              onClick={showComposeModal}
          >
              <svg
                  id="Plus_-_Free_shapes_and_symbols_icons-2_1_"
                  data-name="Plus - Free shapes and symbols icons-2 (1)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 39.615 39.615"
              >
                  <g id="Group_2985" data-name="Group 2985">
                      <path
                          id="Path_3744"
                          data-name="Path 3744"
                          d="M36.133,16.326H23.289V3.482a3.482,3.482,0,1,0-6.964,0V16.326H3.482a3.482,3.482,0,1,0,0,6.964H16.326V36.133a3.482,3.482,0,1,0,6.964,0V23.289H36.133a3.482,3.482,0,1,0,0-6.964Z"
                          fill="#fff"
                      />
                  </g>
              </svg>
          </div>
          {isComposeModalOpen && (
              <ComposeMsgModal
                  handleCancel={()=>{setIsComposeModalOpen(false)}}
                  handleOk={() => { setIsComposeModalOpen(false) }}
                  isComposeModalOpen={isComposeModalOpen}
              />)} */}
             
        </>
    )
}
function mapStateToProps(state) {
    return {
        InboxMessages: state.InboxMessagesReducer,
        messageByID: state.MessageByIdReducer,
    DeleteMessage: state.DeleteMessagesReducer,
        SendingMessage: state.SendingMessagesReducer,

    }
}
function mapDispatchToProps(disptach) {
    return {
        deleteMessage: (values) => disptach(deleteMessageAPI(values)),
    listInboxMessages: (values) => disptach(listInboxMessageAPI(values)),
        getMsgByID: (values) => disptach(getMessageByIdAPI(values)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InboxMessages)
// export default
