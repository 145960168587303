import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";

import { View, Text, ActivityIndicator, FlatList } from "react-native-web";
import { fetchLongTermFromApi, fetchPeopleFromApi } from '../../../shared/actions/DiagnosisActions';
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper';
import DiagnosisHandler from '../../components/DiagnosisHandler'
import moment from 'moment';

const Recent = props => {
    const [isloaded, setIsload] = React.useState(false);

    const { RecentDiag, isFetching } = props.RecentDiag;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);



    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])


    useEffect(() => {
        //alert('Called Future')
        props.getRecentDiag(pageSize, pageNumber);
    }, [])
    return (
        <div>
            <div className="content-viewport">
                <div className="row row-margin-zero">
                    <div className="col-12">
                        {/* <div className="grid"> */}
                        {/* <div className="grid-body">
                                <h2 className="grid-title">Diagnosis</h2> */}
                        <div className="item-wrapper">
                            {
                                isFetching && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                            }
                            {
                                typeof (RecentDiag) !== 'undefined' && RecentDiag.length ? (
                                    <FlatList
                                        style={{
                                            flexDirection: 'column', paddingLeft: 10,
                                            paddingRight: 10,
                                            paddingBottom: 10
                                        }}
                                        showsVerticalScrollIndicator={true}
                                        data={RecentDiag}
                                        renderItem={({ item }) => (

                                            <DiagnosisHandler
                                                mystyles={'card-header text-white bg-primary'}
                                                date={moment(item.resource.onsetDateTime).format("DD MMM YYYY")}
                                                comments={item.resource.note[0].text}
                                                // displayName={item.resource.code.coding[0].display}
                                            />
                                            // <DiagnosisWebHandler
                                            //     styles={GetTagColor('Long')}
                                            //     date={GetAppointmentDate(item.resource.onsetDateTime) + ' ' + GetMonthName(item.resource.onsetDateTime)}
                                            //     month={GetYear(item.resource.onsetDateTime)}
                                            //     //type_diagnoes={results.resource.resourceType}
                                            //     comments={item.resource.note[0].text}
                                            // />
                                        )}
                                        horizontal={false}
                                        keyExtractor={item => item.resource.id}
                                        initialNumToRender={10}

                                    />
                                ) : !isFetching && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                        <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                                </View>
                            }
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>

        </div>
    )
}
function mapStateToProps(state) {
    return {
        RecentDiag: state.RecentDiag
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRecentDiag: (pageSize, pageNumber) => dispatch(fetchPeopleFromApi(pageSize, pageNumber, 'Not')),
        // getPeopleLongMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreLongTermFromApi(pageSize, pageNumber, 'Not'))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Recent)