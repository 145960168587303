import React, { useState, useEffect } from 'react';

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Button, View, Text, SafeAreaView, Dimensions, StyleSheet, FlatList, ActivityIndicator } from "react-native-web";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAllergyFromApi } from "../../shared/actions/AllergiesActions";
import { capitalize } from "../../shared/core/datehelper";
import AllergiesHandler from "../components/AllergiesHandler";
import { checkToken } from '../../shared/actions/TokenValidActions';
const useNavigate = require("react-router-dom").useNavigate;


const AllergiesHome = props => {
    const history = useNavigate()

    const [isloaded, setIsload] = React.useState(false);
    const { Allergies, isGetting } = props.Allergies;
    const [pageSize, setPageSize] = useState(1);           //state for the page number
    const [pageNumber, setPageNumber] = useState(30);


    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])


    useEffect(() => {
        //alert('Called Future')
        props.getAlg(pageSize, pageNumber);
    }, [])


    React.useEffect(() => {
        props.tokenVal(history);
    }, [])

    //find the tag color
    function GetTagColor(allergyLevel) {
        if (allergyLevel === 'Severe') {
            return 'card-header text-white bg-danger';
            //return { backgroundColor: Colors.highAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        }
        if (allergyLevel === 'Moderate') {
            return 'card-header text-white bg-warning';
            // return { backgroundColor: Colors.midAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        }
        if (allergyLevel === 'Mild') {
            return 'card-header text-white bg-success';
            // return { backgroundColor: Colors.lowAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        }
    }


    //find the tag name
    function GetTagName(allergyLevel) {
        if (allergyLevel === 'Severe') {
            return 'High'
        }
        if (allergyLevel === 'Moderate') {
            return 'Moderate'
        }
        if (allergyLevel === 'mild' || allergyLevel === 'Mild') {
            return 'Low'
        }
    }
    return (
    
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Allergies</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row row-margin-zero h-100">
                                <div className="col-12 h-100">
                                    <div className="grid h-100">
                                        <div className="grid-body h-100">
                                          
                                            <div className="item-wrapper allergies-container">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div style={{ marginLeft: 10, alignItems: 'center' }}>
                                                            <span className="badge badge-success" style={{ marginLeft: 15, marginTop: 10 }}>Mild</span>
                                                            <span className="badge badge-warning" style={{ marginLeft: 15, marginTop: 10 }}>Moderate</span>
                                                            <span className="badge badge-danger" style={{ marginLeft: 15, marginTop: 10 }}>Severe</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                        isGetting && <View style={{ textAlign: 'center', marginBottom: 5, marginTop: "15px" }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                }
                                                {
                                                    typeof (Allergies) !== 'undefined' && Allergies.length ? (
                                                        <FlatList
                                                            style={{ marginTop: 15 }}
                                                            data={Allergies}
                                                            renderItem={({ item }) => (


                                                                <AllergiesHandler
                                                                    // algstyles={item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString == "" ? 'Mild' : item.resource.extension[1].valueString : 'Mild'}

                                                                    // styles={GetTagColor(item.resource.hasOwnProperty('reaction') ? item.resource.reaction[0].severity : 'low')}
                                                                    styles={GetTagColor(item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString == "" ? 'Mild' : item.resource.extension[1].valueString : 'Mild')}
                                                                    // algLevel={GetTagName(item.resource.hasOwnProperty('reaction') ? item.resource.reaction[0].severity : 'low')}
                                                                    // allergyLevel={item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString : 'Low'}
                                                                    algLevel={GetTagName(item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString : 'Low')}
                                                                    // allergyLevel={item.resource.hasOwnProperty('reaction') ? capitalize(item.resource.reaction[0].severity) : 'Low'}
                                                                    allergyLevel={item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString == "" ? capitalize("n/a") : capitalize(item.resource.extension[1].valueString) : 'Low'}
                                                                    algTitle={capitalize(item.resource.code.text)}
                                                                    algType={item.resource.extension[0].valueString}
                                                                    symptoms={item.resource.hasOwnProperty('reaction') ? item.resource.reaction[0].manifestation[0].text : ''}
                                                                    comment={item.resource.hasOwnProperty('note') ? item.resource.note[0].text : ''}
                                                                />
                                                            )}
                                                            keyExtractor={item => item.resource.id}
                                                            initialNumToRender={10}
                                                            maxToRenderPerBatch={10}
                                                            windowSize={7}

                                                        />
                                                    ) : !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                <div className='text-danger' style={{ marginTop: 10, }}>No Records Found</div>
                                                    </View>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                
    )
}




function mapStateToProps(state) {
    return {
        Allergies: state.Allergies
    }
}

function mapDispatchToProps(disptach) {
    return {
        getAlg: (pageNumber, pageSize) => disptach(fetchAllergyFromApi(pageNumber, pageSize)),
        tokenVal: (history) => disptach(checkToken(history))

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllergiesHome)
//export default AllergiesHome
