import { SEND_MESSAGE_LISTING, SEND_MESSAGE_LISTING_SUCCESS, SEND_MESSAGE_LISTING_LOADING, SEND_MESSAGE_LISTING_ERROR, SEND_MESSAGE_LISTING_FAILED } from '../../constants/Constant'


//SET INITAILS STATE
const initialState = {
    sendMessageListing: null,
    sendMessageListingSuccess: false,
    sendMessageListingFailed: false,
    sendMessageListingLoading: false,
    sendMessageListingError: null,
    sendMessageListingStatus: "idle",
  }

//export the defult state

export default function sendMessageReducer(state = initialState, action) {
    switch (action.type) {
        case SEND_MESSAGE_LISTING:
            return {
                ...state,
                sendMessageListingLoading: true,
                sendMessageListingStatus: "loading"
            }
     
        case SEND_MESSAGE_LISTING_SUCCESS:
            return {
                ...state,
                sendMessageListingLoading: false,
                sendMessageListing: action.data,
                sendMessageListingSuccess: true,
                sendMessageListingStatus: "success"
            }

        case SEND_MESSAGE_LISTING_FAILED:
            return {
                ...state,
                sendMessageListingLoading: false,
                sendMessageListingStatus: "failed",
                sendMessageListingError: action.data,
                sendMessageListingFailed: true
            }

        default:
            return state
    }
}