//Proxy.js
import { GET_PROXY_ACCOUNTS, GET_PROXY_ACCOUNTS_SUCCESS, GET_PROXY_ACCOUNTS_FAILED, GET_PROXY_ACCOUNTS_FINISHED, GET_PROXY_ACCOUNTS_REFRESH_DATA } from "../constants/Constant";


//inital state
const initalState = {
    Proxy: [],
    isGettlistPrpxy: false,
    errorProxy: false
}

//export default state
export default function getProxyReduer(state = initalState, action) {
    switch (action.type) {
        case GET_PROXY_ACCOUNTS:
            return {
                ...state,
                isGettlistPrpxy: true
            }
        case GET_PROXY_ACCOUNTS_REFRESH_DATA:
            return {
                ...state,
                Proxy: [],
                isGettlistPrpxy: false,
                errorProxy: false
            }

        case GET_PROXY_ACCOUNTS_SUCCESS:
            return {
                ...state,
                isGettlistPrpxy: false,
                Proxy: action.data

            }

        case GET_PROXY_ACCOUNTS_FAILED:
            return {
                ...state,
                isGettlistPrpxy: false,
                errorProxy: true
            }

        case GET_PROXY_ACCOUNTS_FINISHED:
            return {
                ...state,
                isGettlistPrpxy: false,
                errorProxy: false
            }

        default:
            return state
    }
}