import { DRAFT_MESSAGE_LISTING, DRAFT_MESSAGE_LISTING_SUCCESS, DRAFT_MESSAGE_LISTING_LOADING, DRAFT_MESSAGE_LISTING_ERROR, DRAFT_MESSAGE_LISTING_FAILED } from '../../constants/Constant'


//SET INITAILS STATE
const initialState =  {
    draftMessageListing: null,
    draftMessageListingSuccess: false,
    draftMessageListingFailed: false,
    draftMessageListingLoading: false,
    draftMessageListingError: null,
    draftMessageListingStatus: "idle",
  }

//export the defult state

export default function draftMessageReducer(state = initialState, action) {
    switch (action.type) {
        case DRAFT_MESSAGE_LISTING:
            return {
                ...state,
                draftMessageListingLoading: true,
                draftMessageListingStatus: "loading"
            }

        case DRAFT_MESSAGE_LISTING_SUCCESS:
            return {
                ...state,
                draftMessageListingLoading: false,
                draftMessageListing: action.data,
                draftMessageListingSuccess: true,
                draftMessageListingStatus: "success"
            }

        case DRAFT_MESSAGE_LISTING_FAILED:
            return {
                ...state,
                draftMessageListingLoading: false,
                draftMessageListingStatus: "failed",
                draftMessageListingError: action.data,
                draftMessageListingFailed: true
            }

        default:
            return state
    }
}