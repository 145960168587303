import React, { useEffect } from 'react'
import JpgFileComp from './JpgFileComp'
import PdfFileComp from './PdfFileComp'

const AttachmentsHandler = ({ selectedFiles, type="composed", handleRemoveFile=(index)=>console.log("handle Remove not handled")} ) => {
    useEffect(() => {
        console.log("selected files", selectedFiles.length, " ,  data,", selectedFiles)
        console.log("selected files type of ", type)
    }, [selectedFiles])
    const handleRemove = (index)=>{
        handleRemoveFile(index)
    }
    return (
        <>
        <div className='d-flex flex-wrap'>

            {selectedFiles.map((fileData, index) => {
                console.log("fileType,", selectedFiles.length)
                if (fileData?.contentAttachment.contentType?.toLowerCase() === "pdf") {
                    return <PdfFileComp attachNumber={index + 1} pdfData={fileData?.contentAttachment?.data} type ={type} handleRemove={handleRemove}/>
                } else {
                    return <JpgFileComp attachNumber={index + 1} jpgData={fileData?.contentAttachment?.data} type ={type} handleRemove={handleRemove}/>
                }
            })}
            </div>
        </>
    )
}

export default AttachmentsHandler
