import { GET_APPLICATION_SETTINGS, GET_APPLICATION_SETTINGS_SUCCESS, GET_APPLICATION_SETTINGS_FAILED, GET_APPLICATION_SETTINGS_REFRESH_DATA } from "../constants/Constant";

//inital state
const initalState = {
    isLoadingAppsetting: false,
    ApplicationSettings: [],
    error: false,
    isAvailable: false
}

//export default state
export default function AppSettingReducer(state = initalState, action) {
    switch (action.type) {
        case GET_APPLICATION_SETTINGS:
            return {
                ...state,
                ApplicationSettings: [],
                isLoadingAppsetting: true,
                isAvailable: false,

            }
        case GET_APPLICATION_SETTINGS_REFRESH_DATA:
            return {
                ...state,
                isLoadingAppsetting: false,
                ApplicationSettings: [],
                error: false,
                isAvailable: false
            }

        case GET_APPLICATION_SETTINGS_SUCCESS:

            return {
                ...state,
                isLoadingAppsetting: false,
                ApplicationSettings: action.data,
                isAvailable: true,
            }


        case GET_APPLICATION_SETTINGS_FAILED:
            return {
                ...state,
                isLoadingAppsetting: false,
                error: true,
                isAvailable: false
            }


        default:
            return state
    }
}