import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
    return (
        <>
            <div className='mt-5 d-flex flex-column align-items-center'>
                <h2 className='text-danger'>Failed to load the page</h2>
                <h4 className='text-danger'>Error Code: 404</h4>
                <Link to="/">Go Back</Link>
            </div>
        </>
    )
}

export default ErrorPage
