import React, { useEffect, useState } from "react";
import MessageListing from "../../components/MessagingComponents/MessageListing";
import MessageContent from "../../components/MessagingComponents/MessageContent";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import getUserId from "../../../shared/core/GetToken";
import Settings from "../../../shared/config/Settings";

const Messaging = () => {
  const [disclaimerMsg, setDisclaimerMsg] = useState("");
  const [draftDisclaimerMsg, setDraftDisclaimerMsg] = useState("");

  const getComposeMsgDisclaimer = (loadType)=>{
      getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          myHeaders.append("Token", item.token);
 

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = `?loadtype=${loadType}&PatientID=${item.parentid}`
            fetch(Settings.apiUrl + "Patient/TermsAndCondition" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                  if(loadType == 1){
                    setDisclaimerMsg(json)
                  }else if(loadType==2){
                    setDraftDisclaimerMsg(json)
                  }
                  
                })      
                .catch(error =>{
                  if(loadType == 1){
                    setDisclaimerMsg(null)
                  }else if(loadType==2){
                    setDraftDisclaimerMsg(null)
                  }
                })
      })
  }
  useEffect(()=>{
    getComposeMsgDisclaimer(1)
    getComposeMsgDisclaimer(2)
  },[])
  return (
    
            <div className="page-content-wrapper-inner">
              <div className="viewport-header">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb has-arrow">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Messaging</li>
                  </ol>
                </nav>
              </div>
              <div className="content-viewport">
                <div className="row row-margin-zero h-100">
                  <div className="col-12 h-100">
                    <div className="grid m-0 h-100">
                      {/* <div className="grid-body"> */}
                      <div className="msgBox">
              <div className="mainContent d-flex">
                <MessageListing disclaimerMsg={disclaimerMsg} />
                <MessageContent draftDisclaimerMsg={draftDisclaimerMsg} disclaimerMsg={disclaimerMsg}/>
</div>
              </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
  );
};

export default Messaging;
