import { Checkbox, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import getUserId from "../../shared/core/GetToken";
import Table from 'react-bootstrap/Table';
import moment from "moment";
import Settings from "../../shared/config/Settings";
const UserInvoice = (props) => {
  const [invoiceBase64, setInvoiceBase64] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectAllBtn, setSelectAllBtn] = useState(false)
  const [payNowArr, setPayNowArr] = useState(props.AllInvc.map(invoice=> {
    return { balance: invoice.resource.extension[7].valueString, paynow: invoice?.resource?.extension[8]?.valueDecimal}}))
    // "Amount": 10,
    // "Balance": 110,
    // "Notes": ""
  const [invoicesCheckState, setInvoicesCheckState] = useState(props.AllInvc.map(invoice=> {
    return {InvoiceID:invoice.resource.id,isChecked: (selectAllBtn ? true: false),Amount:invoice.resource.extension[6].valueString,Balance:invoice.resource.extension[7].valueString,Notes:invoice.resource.extension[2].valueString,PayNow: invoice?.resource?.extension[8]?.valueDecimal}
  }))

    useEffect(()=>{
      if(invoicesCheckState[0]){
        props.handleCheckedInvoices(invoicesCheckState)
      }
    },[invoicesCheckState])
    // const handleSelectAll=(e)=>{
    //   setSelectAllBtn(!selectAllBtn)
    //   setInvoicesCheckState(invoicesCheckState.map(data=>{
    //     return {id: data.id, isChecked: e.target.checked}
    //   }))
    // }
  //   const handleInvoiceCheck=(e)=>{
  //     setInvoicesCheckState(invoicesCheckState.map(data=> {
  //       if(data.id == e.target.id){
  //         return {id: data.id, isChecked: e.target.checked}
  //       }else{
  //         return data
  //       }
  //     })
  //     );
  //     // props.handleCheckedInvoices(invoicesCheckState)

  // }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDownloadPDFClick = (transactionID) => {
    showModal()
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo)
      var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Token", item.token);

      var requestOptions = {
        method: 'GET',
        // body: JSON.stringify(raw),
        headers: myHeaders,
        redirect: 'follow'
      };
      let patientID = JSON.parse(localStorage.getItem("@userToken")).parentid;
      fetch(`${Settings.apiUrl}Patient/LoadInvoicePDF?PatientID=${patientID}&InvoiceTransactionID=${transactionID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("loadInvoice API response ", result)
          setInvoiceBase64(result.PDFFileName)
        }
        )

        .catch(error => console.log("loadInvoice API response Error ", error)
        );
    })
  }

  const handleAmountChange= ()=>{
    debugger;
    let enteredAmount = parseFloat(props.enteredAmount)
    if(enteredAmount >0){
      let remainingAmount = enteredAmount
      let invoiceCheckState = invoicesCheckState
      setPayNowArr(payNowArr.map((item,index) => {
        let obj = {...item, paynow: (remainingAmount < parseFloat(item.balance) ? remainingAmount: parseFloat(item.balance))}
        if(obj.paynow > 0){ 
          invoiceCheckState = invoiceCheckState.map((invoice, invInd)=>{
            if(index == invInd){
              return {...invoice, isChecked: true,PayNow:obj.paynow} 
            }else{
              return invoice
            }
          })
        }else{
           invoiceCheckState = invoiceCheckState.map((invoice, invInd)=>{
            if(index == invInd){
              return {...invoice,isChecked:false} 
            }else{
              return invoice
            }
          })
        }
        remainingAmount = remainingAmount - obj.paynow  
        return obj
      } ))
      setInvoicesCheckState(invoiceCheckState)
    }else{
      setPayNowArr(props.AllInvc.map(invoice=> {
        return { balance: invoice.resource.extension[7].valueString, paynow: invoice?.resource?.extension[8]?.valueDecimal}}))

      setInvoicesCheckState(props.AllInvc.map(invoice=> {
    return {InvoiceID:invoice.resource.id,isChecked: (selectAllBtn ? true: false),Amount:invoice.resource.extension[6].valueString,Balance:invoice.resource.extension[7].valueString,Notes:invoice.resource.extension[2].valueString,PayNow: invoice?.resource?.extension[8]?.valueDecimal}
  }))
      }
  }
  useEffect(()=>{
    handleAmountChange()
  },[props.enteredAmount])
  return (
    <>
      <div className="table-responsive" style={{ marginTop: 10, maxHeight: "400px", overflow: "auto" }}>
        <table className="invoice-table table table-striped table-hover" style={{width: "100%" }}>
          <thead style={{ position: "sticky", top: "0", zIndex: "10" }} >
        <tr className="invoice-table-row">
              {props.invoiceType == "pending" && <th ></th>}
          <th className="notes-th">Notes</th>
              <th >Type</th>
          <th >Reference No.</th>
              <th >Amount</th>
              <th >Balance</th>
              <th >Pay Now</th>
          <th >Date</th>
          <th >Action</th>
        </tr>
      </thead>
      <tbody className="invoice-table-body">
        {
        props.AllInvc ?
        props.AllInvc.map((invoice, index)=>{
          let check = selectAllBtn? true : false
         return <tr className="invoice-table-body-row">
           {props.invoiceType == "pending" && <td><Checkbox disabled id={invoice.resource.id} checked={invoicesCheckState[index].isChecked} /></td>}
          <td>{invoice.resource.extension[1].valueString}</td>
           <td>{invoice.resource.extension[2].valueString}</td>
           <td>{invoice.resource.extension[5].valueString}</td>
          <td><b className="text-success">{invoice.resource.extension[6].valueString}</b></td>
           <td>{invoice.resource.extension[7].valueString}</td>
           <td>{payNowArr[index].paynow}</td>
          <td>{moment(invoice.resource.extension[0].valueString).format("ll")}</td>
          <td><span href className="invoice-download-link" style={{cursor: "pointer"}} onClick={()=>handleDownloadPDFClick(invoice.resource.id)}>
                <i className="mdi mdi-download" />
              </span></td>
         
        </tr>
        }) 
        :null
        }
      </tbody>
    </table>
        {/* <div className="invoice-grid">
          <div className="invoice-grid-card shadow">
            <div className="invoice-card-header">
              <span className="invoice-date">{props.invoice_date} {props.invoice_month}</span>
              <span className="incovice-amount">
                Amount : <span>{props.ammount}</span>
              </span>
              <span href className="invoice-download-link" style={{cursor: "pointer"}} onClick={handleDownloadPDFClick}>
                <i className="mdi mdi-download" />
              </span>
            </div>
            <hr />
            <div className="invoice-card-body">
              <div className="cell">
                <h4>Person Name</h4>
                <p>{props.paitent_name}</p>
              </div>
              <div className="cell">
                <h4>Notes</h4>
                <p>{props.notes}</p>
              </div>

              <div className="cell">
                <h4>Type</h4>
                <p>{props.type}</p>
              </div>
              <div className="cell">
                <h4>Payee</h4>
                <p>{props.payename}</p>
              </div>
              <div className="cell">
                <h4>Reference No.</h4>
                <p>{props.reference}</p>
              </div>
              <div className="cell">
                <h4>Pay Now</h4>
                <p>{props.paynow}</p>
              </div>
              <div className="cell">
                <h4>Balance</h4>
                <p>{props.balance}</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Modal title="INVOICE" width={800} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {invoiceBase64 ?
          <div className="PDF" id="showPDF">
            {invoiceBase64 ? <object id="pdfObject" data={"data:application/pdf;base64," + invoiceBase64} type="application/pdf"></object> : "Not Got it"}

          </div> : <div className="d-flex justify-content-center">
            <Spin />
          </div>
        }
      </Modal>
    </>
  );
};

export default UserInvoice;
