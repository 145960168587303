import React from 'react';
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text } from "react-native-web";

import { Tabs } from "antd";
import 'antd/dist/antd.css';
import DueTab from './ImmuneTabs/DueTab';
import OverdueTab from './ImmuneTabs/OverdueTab';
import AdministeredTab from './ImmuneTabs/AdministeredTab';
import AllTabs from './ImmuneTabs/AllTabs';
import { fetchProfileFromApi } from '../../shared/actions/ProfileActions';
import { connect } from "react-redux";
import { ActivityIndicator } from 'react-native';
import { checkToken } from '../../shared/actions/TokenValidActions';
import { fetchAdministeredImmunefromApi } from '../../shared/actions/AdministeredImmuneActions';
import { fetchImmunefromApi } from '../../shared/actions/ImmuneActions';
import { fetchOverdueImmune } from '../../shared/actions/OverdueImmuneActions';
import { fetchDueImmunefromApi } from '../../shared/actions/DueImmuneActions';
const useNavigate = require("react-router-dom").useNavigate;

const { TabPane } = Tabs;

export const ImmuneHomeContext = React.createContext();
const initialState = {
    isLoadedTest: true,
    immuneMg: null,

};
const reducer = (state, action) => {
    switch (action.type) {
        // type 
        case "ISIMMUNELOADED":
            // localStorage.setItem("user", JSON.stringify(action.payload.user));
            // localStorage.setItem("token", JSON.stringify(action.payload.token));
            return {
                ...state,
                isLoadedTest: true,
                immuneMg: null,
                // user: action.payload.user,
                // token: action.payload.token
            };
        case "IMMUNELOAD":
            // localStorage.clear();
            return {
                ...state,
                // actions
                isLoadedTest: false,
                immuneMg: action.immuneMg,
                // user: null
            };

        default:
            return state;
    }
};

const ImmuneHome = props => {
    const history = useNavigate()
    const { TokenValidator, isChecking } = props.TokenValidator;

    const { Profile, isFetching } = props.Profile;
    const [isloaded, setIsload] = React.useState(false);
    const [state, dispatch] = React.useReducer(reducer, initialState);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])

    React.useEffect(() => {

        props.getProfile();
    }, [])
    React.useEffect(() => {
        props.tokenVal(history);
    }, [])

    const immuneValue = (value) => {
        //console.log("test")
    }

    return (
        <ImmuneHomeContext.Provider value={{
            state,
            dispatch
        }}>
          
                        <div className="page-content-wrapper-inner">
                            <div className="viewport-header">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb has-arrow">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Immunisation</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="content-viewport">
                                <div className="row row-margin-zero h-100" >
                                    <div className="col-12 h-100">
                                        <div className="grid h-100">
                                            <div className="grid-body h-100">
                                              
                                                <div className="item-wrapper immunisatoin-container">
                                                    <div className="row mb-2">
                                                        <div className="col-md-8 mx-auto">

                                                        </div>
                                                    </div>
                                        <Tabs defaultActiveKey="1" centered items={[
                                            {
                                                label: <div onClick={() => {
                                                    props.getDueImmune(100, 1)
                                                }}>
                                                    Due
                                                </div>,
                                                key: "1",
                                                children: <DueTab />
                                            },
                                            {
                                                label: <div onClick={() => {
                                                    props.getImmuneOverdue(100, 1)
                                                }}>
                                                    Overdue
                                                </div>,
                                                key: "2",
                                                children: <OverdueTab />
                                            },
                                            {
                                                label: <div onClick={() => {
                                                    props.getAddministratedImmune(100, 1)
                                                }}>
                                                    Administered
                                                </div>,
                                                key: "3",
                                                children: <AdministeredTab />
                                            },
                                            {
                                                label: <div onClick={() => {
                                                    props.getAllImmune(100, 1);
                                                }}>All</div>,
                                                key: "4",
                                                children: <AllTabs />
                                            },

                                        ]}>

                                                    </Tabs>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

        </ImmuneHomeContext.Provider>
    )
}

function mapStateToProps(state) {
    return {
        Profile: state.Profile,
        TokenValidator: state.TokenValidator,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // getProfile:
        getProfile: () => dispatch(fetchProfileFromApi()),
        tokenVal: (history) => dispatch(checkToken(history)),
        getDueImmune: (pageSize, pageNumber) => dispatch(fetchDueImmunefromApi(pageSize, pageNumber)),
        getImmuneOverdue: (pageSize, pageNumber) => dispatch(fetchOverdueImmune(pageSize, pageNumber)),
        getAllImmune: (pageSize, pageNumber) => dispatch(fetchImmunefromApi(pageSize, pageNumber)),
        getAddministratedImmune: (pageSize, pageNumber) => dispatch(fetchAdministeredImmunefromApi(pageSize, pageNumber)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImmuneHome);
// export default ImmuneHome
