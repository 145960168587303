import { GETING_REPORTS, GETING_REPORTS_SUCCESS, GETING_REPORTS_FAILURE, GETTING_MEDS_MORE_SUCCESS, GETTING_ALLERGIES_FINISHED } from "../constants/Constant";
import getPaitentReports from "../services/ReportsService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";


export const fetchReportsFromApi = (pageSize, pageNumber, searchtext, searchFromDate, searchToDate) => {
    return (dispatch) => {
        dispatch(getReports())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };


            // //console.log(data);
            // token = data
            const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&searchText=' + searchtext + '&fromdate=' + searchFromDate + '&todate=' + searchToDate;

            fetch(Settings.apiUrl + "Patient/LabResults" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    //console.log(json)
                    dispatch(getReportsSuccess(json ? json[0].entry : []))
                })
                .catch(err => dispatch(getReportsFailed(err)))

            // getPaitentReports(queryString)
            //     .then(data => data.json())  WithoutAttachment
            //     .then(json => {
            //         //console.log(json)
            //         dispatch(getReportsSuccess(json[0].entry))
            //     })
            //     .catch(err => dispatch(getReportsFailed(err)))
        })


    }
}

export const fetchMoreReportsFromApi = (token, pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getReports())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // //console.log(data);
            // token = data
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;
            getPaitentReports(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(json)
                    // dispatch(getReportsSuccess(json[0].entry))
                    if (json != null) {
                        json[0].hasOwnProperty('entry') ? dispatch(getMoreReportsSuccess(json[0].entry)) : dispatch(getReportFinished())
                    } else {
                        dispatch(getReportFinished())
                    }
                })
                .catch(err => dispatch(getReportsFailed(err)))
        })


    }
}



//start getting data
const getReports = () => {
    return {
        type: GETING_REPORTS
    }
}


//data success
const getReportsSuccess = (data) => {
    return {
        type: GETING_REPORTS_SUCCESS,
        data
    }
}
//data more repoert success
const getMoreReportsSuccess = (data) => {
    return {
        type: GETTING_MEDS_MORE_SUCCESS,
        data
    }
}

//data failed
const getReportsFailed = () => {
    return {
        type: GETING_REPORTS_FAILURE
    }
}

const getReportFinished = () => {
    return {
        type: GETTING_ALLERGIES_FINISHED
    }
}