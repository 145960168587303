// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import Header from "../components/Header";
// import Sidebar from "../components/Sidebar";
// import { View, Text, ActivityIndicator, FlatList } from "react-native-web";
// import Long from "./DiagnosisTabs/Long";
// import Recent from "./DiagnosisTabs/Recent";
// import { fetchLongTermFromApi } from "../../shared/actions/DiagnosisActions";
// import {
//   GetAppointmentDate,
//   GetMonthName,
//   GetYear,
// } from "../../shared/core/datehelper";
// import { Tabs } from "antd";
// const { TabPane } = Tabs;

// const Diagnosis = (props) => {
//   const [isloaded, setIsload] = React.useState(false);

//   const { LongDiag, isFetching } = props.LongDiag;
//   const [pageSize, setPageSize] = useState(100); //state for the page number
//   const [pageNumber, setPageNumber] = useState(1);

//   React.useEffect(() => {

//     // loadjs(['http://indici-pre-production.web.app/assets/js/template.js', 'http://indici-pre-production.web.app/assets/js/dashboard.js'], 'foobar');
//     // loadjs.ready('foobar', function () {
//     //     setIsload(true);
//     // });


//     const script = document.createElement("script");
//     script.async = true;
//     script.src = "https://indici-pre-production.web.app/assets/js/template.js";
//     document.body.appendChild(script)
//     // d.div.appendChild(script);


// }, [])
//   // React.useEffect(() => {
//   //   const script = document.createElement("script");
//   //   script.async = true;
//   //   script.src = "https://indici-pre-production.web.app/assets/js/template.js";
//   //   document.body.appendChild(script);
//   //   // d.div.appendChild(script);
//   // }, []);

//   useEffect(() => {
//     //alert('Called Future')
//     props.getPeopleLong(pageSize, pageNumber);
//   }, []);
//   return (
//     <div>
//       <Header />
//       <div id="myphonemenu" className="page-body">
//         <Sidebar />
//         <div className="page-content-wrapper">
//           <div className="page-content-wrapper-inner">
//             <div className="viewport-header">
//               <nav aria-label="breadcrumb">
//                 <ol className="breadcrumb has-arrow">
//                   <li className="breadcrumb-item">
//                   <Link to="/">Dashboard</Link>
//                   </li>
//                   <li className="breadcrumb-item active" aria-current="page">
//                     Diagnosis
//                   </li>
//                 </ol>
//               </nav>
//             </div>
//             <div className="content-viewport">
//               <div className="row">
//                 <div className="col-12">
//                   <div className="grid">
//                     <div className="grid-body">
//                       {/* <h2 className="grid-title">Appointments</h2> */}
//                       <div className="item-wrapper">
//                         <Tabs defaultActiveKey="1" centered>
//                           <TabPane
//                             tab="Long Term"
//                             key="1"

//                           >
//                             <Long />
//                           </TabPane>
//                           <TabPane tab="Recent Diagnosis" key="2">
//                             <Recent />
//                           </TabPane>
//                         </Tabs>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <footer className="footer-ignore"></footer>
//         </div>
//       </div>
//     </div>
//   );
// };

// function mapStateToProps(state) {
//   return {
//     LongDiag: state.LongDiag,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     getPeopleLong: (pageSize, pageNumber) =>
//       dispatch(fetchLongTermFromApi(pageSize, pageNumber, "Not")),
//     // getPeopleLongMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreLongTermFromApi(pageSize, pageNumber, 'Not'))
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Diagnosis);

// //export default Diagnosis

import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text, StyleSheet } from "react-native-web";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import AllMedications from "./MedicationsTabs/AllMedications";
import LongTermMed from "./MedicationsTabs/LongTermMed";
import RepeatedMedication from "./MedicationsTabs/RepeatedMedication";
import { connect } from "react-redux";

import Long from "./DiagnosisTabs/Long";
import Recent from "./DiagnosisTabs/Recent";
import { checkToken } from "../../shared/actions/TokenValidActions";
import All from "./DiagnosisTabs/All";
const { TabPane } = Tabs;
const useNavigate = require("react-router-dom").useNavigate;

const Diagnosis = (props) => {
  const history = useNavigate()

  const [isloaded, setIsload] = React.useState(false);
  React.useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://indici-pre-production.web.app/assets/js/template.js";
    document.body.appendChild(script);
    // d.div.appendChild(script);
  }, []);



  React.useEffect(() => {
    props.tokenVal(history);
  }, [])

  return (
    
          <div className="page-content-wrapper-inner">
            <div className="viewport-header">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb has-arrow">
                  <li className="breadcrumb-item">
                    {/* <a href=>Dashboard</a> */}
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Diagnosis
                  </li>
                </ol>
              </nav>
            </div>
            <div className="content-viewport">
              <div className="row row-margin-zero h-100">
                <div className="col-12 h-100">
                  <div className="grid h-100">
                    <div className="grid-body h-100">
                      {/* <h2 className="grid-title">Appointments</h2> */}
                      <div className="item-wrapper diagnosis-container">
                        <Tabs defaultActiveKey="1" centered>
                          <TabPane tab="Long Term" key="1">
                            <Long />
                          </TabPane>
                          <TabPane tab="Recent Diagnosis" key="2">
                            <Recent />
                          </TabPane>
                          <TabPane tab="All Diagnosis" key="3">
                            <All />
                          </TabPane>
                        </Tabs>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      

  );
};

const styles = StyleSheet.create({
  textDetails: {
    textAlign: "justify",
    color: "#95989A",
    fontSize: 15,
  },
  textDetailsInner: {
    textAlign: "justify",
    color: "#333333",
    fontSize: 14,
  },
});
function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(disptach) {
  return {
    tokenVal: (history) => disptach(checkToken(history))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Diagnosis)
// export default Diagnosis;

