import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { clearUserState, fetchUserInformation, LogoutUser } from "../../shared/actions/AuthActions";
import { AuthContext } from "../../shared/core/context";
import getUserId from "../../shared/core/GetToken";
import $ from "jquery";
//import FlatList from 'flatlist-react';

import { View, TouchableOpacity, FlatList, ActivityIndicator, Text } from "react-native-web";
import { emailValidator, getInitials, passwordValidator } from '../../shared/core/utils';
import ProxyAccountWebHandler from "../components/ProxyAccountWebHandler";
import { Redirect, useNavigate } from "react-router-dom";

import AsyncStorage from "@react-native-async-storage/async-storage";

//import {  } from "react-bootstrap/Moda";
import { Modal, Button } from "react-bootstrap";
import ForgetPassword from './ForgetPassword';
import changePasswrodService from '../../shared/services/ChangePasswrod';
import Settings from '../../shared/config/Settings';
import indicilogo from '../assets/indicilogo.png'
import covidlogo from '../assets/covidlogo.png'
import LoginHelpModal from './LoginHelpModal';

import ReCAPTCHA from "react-google-recaptcha";
import { fetchApplicationSettingFromApi, RefreshApplicationSettings } from '../../shared/actions/GetApplicationSettingAction';
import { fetchIdentityServerApiUrl, resetIdentityServerData } from '../../shared/actions/IdentityServerActions';
import { refreshTokenValidatorData, validatePatientTokenFromApi } from '../../shared/actions/TokenValidActions';
import { refreshProxyListData } from '../../shared/actions/ProxyActions';
//export default function Login() {
const Login = props => {
  const linkHistory = useNavigate();

  useEffect(() => {


    //$("#email2").bind("paste", function () { return false; });
    // $("#email2").bind("copy", function () { return false; });

    //document.getElementById("mPassword").bind("paste", function () { return false; });
    //document.getElementById("mPassword").bind("copy", function () { return false; });

    //const myInput = document.getElementById('mPassword');
    //myInput.onpaste = e => e.preventDefault();
    //myInput.oncopy = e => e.preventDefault();

    // props.getProxy();
  }, []);

  const people = [
    { firstName: 'Elson', lastName: 'Correia', info: { age: 24 } },
    { firstName: 'John', lastName: 'Doe', info: { age: 18 } },
    { firstName: 'Jane', lastName: 'Doe', info: { age: 34 } },
    { firstName: 'Maria', lastName: 'Carvalho', info: { age: 22 } },
    { firstName: 'Kelly', lastName: 'Correia', info: { age: 23 } },
    { firstName: 'Don', lastName: 'Quichote', info: { age: 39 } },
    { firstName: 'Marcus', lastName: 'Correia', info: { age: 0 } },
    { firstName: 'Bruno', lastName: 'Gonzales', info: { age: 25 } },
    { firstName: 'Alonzo', lastName: 'Correia', info: { age: 44 } }
  ]


  const renderPerson = (person, idx) => {
    return (

      <li onClick={() => listOnClick(person.resource.id)} key={idx}>

        <b>{person.resource.id} </b> (<span>Testing</span>)

      </li >

    );
  }


  const listOnClick = (id) => {
    alert(id);
  }

  const [isRedirected, setIsRedirect] = useState(false)
  const { isLoggedIn, isLoggin, AuthUser, error, passworderror, loginFailed, userPasswordStatus } = props.AuthUser;
  const { isGettlistPrpxy } = props.Proxy
  const { identityApiData, identityApiDataLoading, identityApiDataSuccess } = props.IdentityServerApiData
  const { isAvailable, isLoadingAppsetting } = props.ApplicationSettings
  const { signIn, signOut } = React.useContext(AuthContext);
  const [Proxy, setProxy] = useState('');
  const [userAgrement, setUserAgrement] = useState(false)
  const [privacyStatementCheck, setPrivacyStatemCheck] = useState(false)
  const [firstState, setFirstState] = useState(false);
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false)
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const [wrongAttemptCount, setWrongAttemptCount] = useState(0)
  const [passwordChangeErrMsg, setPasswordChangeErrMsg] = useState("")

  const [username, setUsername] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const processLogin = async (e) => {

    let useName = document.getElementById("mUserName").value;
    let password = document.getElementById("mPassword").value;


    const emailError = emailValidator(useName);
    const pawrdEror = passwordValidator(password);

    if (emailError || pawrdEror) {
      document.getElementById("errorMessage").innerHTML = "You have entered an invalid email address.";
      return;
    }
    document.getElementById("errorMessage").innerHTML = "";
    setUsername(useName)
    setUserPassword(password)
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Access-Control-Allow-Origin", "*");

    // var raw = JSON.stringify({
    //   "UserName": useName,
    //   "Password": password
    // });

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow',
    //   mode: 'cors',

    // };

    // const IdentityCall = await fetch("https://stagingidentityserver.vitonta.com/api/Authentication", requestOptions);
    // const IdentityCallResult = await IdentityCall.json();
    // if (IdentityCallResult && IdentityCallResult.ACCAPIURL) {

    //   sessionStorage.setItem("ACCAPIURL", IdentityCallResult.ACCAPIURL);
    //     Settings.apiUrl = IdentityCallResult.ACCAPIURL;
    //     props.getUserToken(useName, password)
    // }
    // else
    // {
    //   // set
    //   // loginFailed && <p id="userName_pass_Incorrect" style={{ color: 'red' }}>{passworderror}</p>
    // }



    // fetch("https://stagingidentityserver.vitonta.com/api/Authentication", requestOptions
    // )
    //   .then(response => response.json())
    //   .then(result => {
    //     console.log(result)
    //     // setShow(true)  
    //     if (result && result.APIURL) {
    //       Settings.apiUrl = result.APIURL
    //       props.getUserToken(useName, password)
    //     }

    //   })
    //   .catch(error => props.getUserToken(useName, password));

    // .catch(error => console.log('error', error));


    //for normal login without IS
    // props.getUserToken(useName, password)
    props.getIdentityUrl(useName, password)
  }

  useEffect(() => {
    if (identityApiDataSuccess && identityApiData) {
      props.getUserToken(username, userPassword)

    }
  }, [identityApiDataSuccess])

  const [showReset, setShowReset] = useState(false);
  const [show, setShow] = useState(false);

  const [isResetPassword, setIsResetPassword] = useState(false)
  const [termnConditonVisible, setTermnConditonVisible] = useState(false)


  const handleClose = () => setShow(false);
  // this method is used for to show the handleclose terma and conditon modal 
  // this is not used now because we add the check box in the reset passwrod modal.
  const handleCloseTemnCond = () => {
    props.clearResetState()
    setShowReset(false)
    setTermnConditonVisible(false)
    getProxyUsersApiCall(AuthUser[0].extension[0].valueString, AuthUser[0].id)

  };
  const handleCloseReset = () => {
    props.clearResetState()
    setShowReset(false)
    // use for to show the term and condition modal
    // setTermnConditonVisible(true)
    getProxyUsersApiCall(AuthUser[0].extension[0].valueString, AuthUser[0].id)

  };

  const handleShow = () => setShow(true);


  // const getProxyUsersApiCall = () => {
  //     //console.log("Called");
  // }

  useEffect(() => {

  }, [showReset])

  const getProxyUsersApiCall = (userToken, userId) => {
    console.log("inside test function")
    // alert("Proxy Check Called==>" + userToken + ",,," + userId);

    // var formdata = new FormData();
    var myHeaders = new Headers();
    myHeaders.append("Token", userToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(Settings.apiUrl + "Patient/ProxyUsers?PatientID=" + userId, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result[0].total === 1) {
          ////console.log('yes')
          getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            //if the account self is patient
            if (item.userid === result[0].entry[0].resource.id) {
              //save single patient information
              // _saveData(item.token, item.userid, item.email, item.password, "No", item.username, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)
              _saveData(item.token, item.userid, item.email, item.password, "No", item.username, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)

              //console.log('yes')
            }
            else {
              _saveDataSingleProxy(item.token, result[0].entry[0].resource.id, item.email, item.password, "Yes", result[0].entry[0].resource.name[0].text, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)

              //console.log('yes')

            }
          })
        }
        else {
          //console.log('NO')
          setProxy(result[0].entry)
          setShow(true)
          setFirstState(true);
        }
      })
      .catch(error => console.log('error', error));
  }
  const { q } = useParams()

  useEffect(() => {
    props.getUserToken("", "", q)
    console.log("allll propssss =>", q)
  }, [])
  const _setDataState = async (proxyUserId, proxyUserName, proxyid) => {
    let jsonValue = '';
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);
      //console.log("Firsts :" + JSON.stringify(item))
      _saveData(item.token, proxyUserId, item.email, item.password, "Yes", proxyUserName, item.parentid, proxyid, item.parentname)

    })
  };


  const _saveDataSingleProxy = async (token, proxyUserId, pemail, ppassword, isProxy, proxyUserName, parentid, proxyid, parentName) => {

    // alert(isProxy)
    await AsyncStorage.removeItem('@userToken');
    try {
      //console.log(isProxy)
      const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "password": ppassword, "proxy": "Yes", "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentName });
      //console.log(jsonValue)
      await AsyncStorage.setItem('@userToken', jsonValue)
      loadNext();
    } catch (error) {
      // Error saving data
    }
  }

  const _saveData = async (token, proxyUserId, pemail, ppassword, isProxy, proxyUserName, parentid, proxyid, parentName) => {

    // alert(isProxy)
    await AsyncStorage.removeItem('@userToken');
    try {
      //console.log(isProxy)
      const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "password": ppassword, "proxy": isProxy, "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentName });
      //console.log(jsonValue)
      await AsyncStorage.setItem('@userToken', jsonValue)
      loadNext();
    } catch (error) {
      // Error saving data
    }
  }
  //************************************** End Proxey account **************************************/

  const loadReset = () => {
    // return (<Redirect to={"/resetpassword"} />)
    setShowReset(!showReset)
  }
  const closeResetModal = () => {
    // setShowReset(() => {
    //     return !showReset
    // })
    props.clearResetState()
    props.endUserSession();
    setShowReset(false)
    setShowReset((state) => {
      return state
    })

    signOut();
    // window.location.reload();




  }
  // let history = useNavigate()
  const loadNext = () => {

    setIsRedirect(true);
    setIsRedirect((state) => {
      return state;
    });
    signIn('baseuser');
    // linkHisto  linkHistory.push("/")
    // window.location.reload()
    props.validateToken(linkHistory);

    // return (<Redirect to={"/"} />)
  }



  const resetPasswordFunc = async () => {
    //    alert("ok")

    let password = document.getElementById("password").value;
    let confirmPassword = document.getElementById("confirmPassword").value;

    const passwordError = passwordValidator(password);
    const cnfrmPaswrd = passwordValidator(confirmPassword);
    if (passwordError) {
      setPasswordChangeErrMsg("Password is Required ");
      return;
    }
    if (cnfrmPaswrd) {
      setPasswordChangeErrMsg("Confirm Password is Required ");
      return;
    }
    if (password !== confirmPassword) {
      setPasswordChangeErrMsg("Password and Confirm Password does not match");
      return;
    }

    //check for lower case
    if (!password.match(/[a-z]/)) {
      setPasswordChangeErrMsg("Password must contain at least one lower case letter.");
      // passForm.passInput.focus();
      return;
    }

    //Validating length
    if (password.length < 8) {
      setPasswordChangeErrMsg("Your password has less than 8 characters.");
      return;
    }

    //check for upper ase
    if (!password.match(/[A-Z]/)) {
      setPasswordChangeErrMsg("Password must contain at least one upper case letter.");
      // passForm.passInput.focus();
      return;
    }
    var patt = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,}$");
    //check for number
    if (!patt.test(password)) {
      setPasswordChangeErrMsg("Password must contain at least two number.");
      // passForm.passInput.focus();
      return;
    }
    // if (!privacyStatementCheck) {
    //   document.getElementById("errorMessageReset").innerHTML =
    //     "You need to mark that you agree before you can proceed.";
    //   return;
    // }
    if (!userAgrement) {
      setPasswordChangeErrMsg("You need to mark that you agree before you can proceed.");
      return;
    }
    document.getElementById("errorMessageReset").innerHTML = "";
    setIsResetPassword(true);
    var userInfo = (await AsyncStorage.getItem("@userPassordReset")) || "none";
    // check remining confirm and passweordk
    const item = JSON.parse(userInfo);
    var myHeaders = new Headers();
    myHeaders.append("Token", item.token);
    myHeaders.append("Content-Type", "application/json");
    const queryString = "?Token=" + item.token;
    //console.log("ResetPasswrod===>" + JSON.stringify(item));
    // const queryString = '?Token=' + item.token;
    // //console.log("ResetPasswrod===>" + password)


    var jsonPa = JSON.stringify({ PatientID: item.userid, Password: password });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: jsonPa,
      redirect: 'follow'
    };

    fetch(Settings.apiUrl + "Patient/ChangePassword", requestOptions)
      .then((data) => data.json())
      .then((json) => {
        // {"StatusCode":1,"StatusMessage":"Password updated successfully."}
        if (json.StatusCode == "1") {
          props.clearResetState();
          setShowReset(false);
          // for activity indicatorr
          setIsResetPassword(false);
          document.getElementById("errorMessageReset").innerHTML = "";
          handleCloseReset();
          //console.log("statscode==1==>" + JSON.stringify(json));
          // handleCloseReset()
        }
        // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
        if (json.StatusCode == "3") {
          setIsResetPassword(false);
          document.getElementById("errorMessageReset").innerHTML =
            json.StatusMessage;
          //console.log("statscode==2==>" + JSON.stringify(json));
        }
      })
      .catch((err) => {
        setIsResetPassword(false);
        handleCloseReset();
        document.getElementById("errorMessageReset").innerHTML =
          "Some thing went wrong please try agian.";
        //console.log("resetPasswrodError==>" + err);
      });

    // var jsonPa = JSON.stringify({ PatientID: item.userid, Password: password });

    // changePasswrodService(queryString, jsonPa,myHeaders)
    //   .then((data) => data.json())
    //   .then((json) => {
    //     // {"StatusCode":1,"StatusMessage":"Password updated successfully."}
    //     if (json.StatusCode == "1") {
    //       props.clearResetState();
    //       setShowReset(false);
    //       // for activity indicatorr
    //       setIsResetPassword(false);
    //       document.getElementById("errorMessageReset").innerHTML = "";
    //       handleCloseReset();
    //       //console.log("statscode==1==>" + JSON.stringify(json));
    //       // handleCloseReset()
    //     }
    //     // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
    //     if (json.StatusCode == "3") {
    //       setIsResetPassword(false);
    //       document.getElementById("errorMessageReset").innerHTML =
    //         json.StatusMessage;
    //       //console.log("statscode==2==>" + JSON.stringify(json));
    //     }
    //   })
    //   .catch((err) => {
    //     setIsResetPassword(false);
    //     handleCloseReset();
    //     document.getElementById("errorMessageReset").innerHTML =
    //       "Some thing went wrong please try agian.";
    //     //console.log("resetPasswrodError==>" + err);
    //   });
  };



  const clearCache = async () => {
    try {
      await AsyncStorage.clear()
    } catch (e) {
      // clear error
    }

    //console.log('Done.')
  }



  const [showincon, setshowicon] = useState('mdi mdi-eye')
  const [showReseticon, setshowReseticon] = useState('mdi mdi-eye')
  const [showconfirmResticcon, setshowconfirmResticcon] = useState('mdi mdi-eye')
  const showPassword = () => {
    var x = document.getElementById("mPassword");
    if (x.type === "password") {
      setshowicon('mdi mdi-eye-off')
      x.type = "text";
    } else {
      setshowicon('mdi mdi-eye')
      x.type = "password";
    }
  }
  const resetPasswordShow = (elementId) => {
    var x = document.getElementById(elementId);
    if (x.type === "password") {

      setshowReseticon('mdi mdi-eye-off')
      x.type = "text";
    } else {
      setshowReseticon('mdi mdi-eye')
      x.type = "password";
    }
  }
  const resetConfirmPasswordShow = (elementId) => {
    var x = document.getElementById(elementId);
    if (x.type === "password") {

      setshowconfirmResticcon('mdi mdi-eye-off')
      x.type = "text";
    } else {
      setshowconfirmResticcon('mdi mdi-eye')
      x.type = "password";
    }
  }
  // if (isRedirected === true) {
  //     return <Redirect to={'/'} />
  // }
  const handleCheckTermandCondition = () => {
    setUserAgrement(!userAgrement)
    //console.log('CHANGE!');
  }
  const handlePrivacyStatement = () => {
    setPrivacyStatemCheck(!privacyStatementCheck)
    //console.log('CHANGE!');
  }
  const loginOnEnter = e => {
    if (e.keyCode === 13) {

      e.preventDefault();
      processLogin(e)
      //alert("Enter Key is done")
      // handleSubmit();
    }
  };
  const handleHelpOk = () => {
    setHelpModalOpen(false)
  }
  const handleHelpCancel = () => {
    setHelpModalOpen(false)
  }
  useEffect(() => {
    if (loginFailed) {
      setWrongAttemptCount(wrongAttemptCount + 1)
    }
    if (isLoggedIn) {
      setWrongAttemptCount(0)
    }
  }, [loginFailed])
  useEffect(() => {
    if (isLoggedIn) {
      props.getAppSetting()

    }
  }, [isLoggedIn])
  useEffect(() => {
    if (!userPasswordStatus && !show && isLoggedIn && isAvailable) {
      console.log("calling proxy")
      getProxyUsersApiCall(AuthUser[0].extension[0].valueString, AuthUser[0].id)
    }

  }, [userPasswordStatus, show, isLoggedIn, isAvailable])
  const search = useLocation().search
  useEffect(() => {
    const q = new URLSearchParams(search).get('q');
    if (q) {
      props.getUserToken("", "", q)
      console.log("searchhhhh", q)

    }
  }, [])
  useEffect(() => {
    props.resetAuthUserData()
    props.resetProxyData()
    props.resetAppSettings()
    props.resetIdentityData()
    props.resetTokenData()
    localStorage.clear()
  }, [])
  return (
    <div>

    </div>
  );
}



function mapStateToProps(state) {
  return {
    AuthUser: state.AuthUser,
    ApplicationSettings: state.ApplicationSettings,
    Proxy: state.Proxy,
    IdentityServerApiData: state.IdentityServerReducer,
  }
}

function mapDispatchToProps(disptach) {
  return {
    getUserToken: (email, password, q = "") => disptach(fetchUserInformation(email, password, q)),
    clearResetState: () => disptach(clearUserState()),
    endUserSession: () => disptach(LogoutUser()),
    getAppSetting: () => disptach(fetchApplicationSettingFromApi()),
    getIdentityUrl: (email, password) => disptach(fetchIdentityServerApiUrl(email, password)),
    validateToken: (history) => disptach(validatePatientTokenFromApi(history)),

    resetTokenData: () => disptach(refreshTokenValidatorData()),
    resetProxyData: () => disptach(refreshProxyListData()),
    resetAppSettings: () => disptach(RefreshApplicationSettings()),
    resetIdentityData: () => disptach(resetIdentityServerData()),
    resetAuthUserData: () => disptach(clearUserState()),
  }
}


//export default Login;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)