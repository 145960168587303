import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { getformatedDate } from "../../shared/core/datehelper";
import { View, Text, ActivityIndicator, FlatList } from "react-native-web";
import LetterHandler from '../components/LetterHandler';
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime } from '../../shared/core/datehelper';
import { fetchLettersFromApi, searchLettersFromApi } from "../../shared/actions/LettersActions";
import getUserId from "../../shared/core/GetToken";


import { DatePicker } from "antd";
import 'antd/dist/antd.css';
const { RangePicker } = DatePicker;

const LettersDocs = props => {

    const [isloaded, setIsload] = React.useState(false);
    const { Letters, isGettingLetters } = props.Letters
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])


    useEffect(() => {
        props.getLetters(pageSize, pageNumber);
    }, [])





    const dateFormat = 'DD-MM-YYYY';
    const dateChange = (date, dateString) => {
        if (date === null) {
            props.getLetters(pageSize, pageNumber);
        }
        else {

            var str = dateString.toString();

            str = str.split(",");

            // props.searchLetters(JSON.stringify(date[0]).replace(/['"]+/g, ''), JSON.stringify(date[1]).replace(/['"]+/g, ''))
            // alert(getformatedDate(str[0]) + " 00:00:00" + ",," + getformatedDate(str[1]) + " 23:59:59")
            //

            props.searchLetters(getformatedDate(str[0]) + " 00:00:00", getformatedDate(str[1]) + " 23:59:59")

        }

    }
    return (
       
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                    <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Letter & Documents</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Letter & Documents</h2>
                                            <div className="item-wrapper">
                                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
                                                    <Text>Select Date to Search </Text>
                                                    <RangePicker format={dateFormat} onChange={dateChange} />
                                                </View>
                                                <div className="drive-wrapper drive-grid-view">
                                                    <div className="grid-items-wrapper">
                                                        {
                                                            isGettingLetters && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                        }
                                                        {
                                                            typeof (Letters) !== 'undefined' && Letters.length ? (
                                                                Letters.map((item) => {
                                                                    return (
                                                                        <LetterHandler
                                                                            letterDate={GetAppointmentDate(item.resource.extension[1].valueDateTime) + ' ' + GetMonthName(item.resource.extension[1].valueDateTime) + ' ' + GetYear(item.resource.extension[1].valueDateTime)}
                                                                            letterDetail={item.resource.type.coding[0].display}
                                                                            letterId={item.resource.id}
                                                                        />
                                                                    )
                                                                })
                                                            ) : !isGettingLetters && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                        <div className='text-danger' style={{ marginTop: 10, }}>No Records Found</div>
                                                            </View>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

    )
}


function mapStateToProps(state) {
    return {
        Letters: state.Letters
    }
}

function mapDispatchToProps(disptach) {
    return {
        getLetters: (pageSize, pageNumber) => disptach(fetchLettersFromApi(pageSize, pageNumber)),
        searchLetters: (fromDate, toDate) => disptach(searchLettersFromApi(fromDate, toDate)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LettersDocs)




