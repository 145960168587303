import { MESSAGE_LISTING, MESSAGE_LISTING_SUCCESS, MESSAGE_LISTING_LOADING, MESSAGE_LISTING_ERROR, MESSAGE_LISTING_FAILED } from '../../constants/Constant'


//SET INITAILS STATE
const initialState = {
    messageListing: null,
    messageListingSuccess: false,
    messageListingFailed: false,
    messageListingLoading: false,
    messageListingError: null,
    messageListingStatus: "idle",
}

//export the defult state

export default function ListMessagesReducer(state = initialState, action) {
    switch (action.type) {
        case MESSAGE_LISTING:
            return {
                ...state,
                messageListingLoading: true,
                messageListingStatus: "loading"
            }

        case MESSAGE_LISTING_SUCCESS:
            return {
                ...state,
                messageListingLoading: false,
                messageListing: action.data,
                messageListingSuccess: true,
                messageListingStatus: "success"
            }

        case MESSAGE_LISTING_FAILED:
            return {
                ...state,
                messageListingLoading: false,
                messageListingStatus: "failed",
                messageListingError: action.data,
                messageListingFailed: true
            }

        default:
            return state
    }
}