import React from 'react'
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined, HeatMapOutlined } from '@ant-design/icons';
const PrintAppointmentHeader = (props) => {
    return (
        <>
            {props.organizationData.length > 0 && props.patientProfile.length > 0 ?
            <div className={"" + (props.isPrintActive ? "d-flex" : "d-none")}>
                <div className="print-header">

                    <div className="print-header-left ">
                        <div>
                            {/* <Avatar size={100} icon={<HeatMapOutlined />} /> */}

                        </div>
                        {/* <img /> */}
                        <div>
                            <h4>{props.organizationData[0]?.resource?.name}</h4>
                            <p>{props.organizationData[0]?.resource?.address[0]?.text}</p>
                        </div>
                    </div>
                    <div className="print-header-center">
                        <p>{props.patientProfile[0].resource?.name[0].text}</p>
                        <p><span>NHI:</span> {props.patientProfile[0].resource?.extension[3].valueString}</p>

                    </div>
                    <div className="print-header-right">

                        <p><span>Phone:</span> {props.patientProfile[0].resource?.telecom?.map(item => {
                            if (item?.use === "home" && item?.system === "phone") {
                                return item.value
                            }
                        })}</p>
                        <p><span>Email:</span> {props.patientProfile[0].resource?.telecom?.map(item => {
                            if (item?.system === "email") {
                                return item.value
                            }
                        })}</p>
                    </div>
                </div>

                <hr />
            </div>
                : null}
       </>
    )
}

export default PrintAppointmentHeader
