import { IDENTITY_SERVER_APIURL_FAILED, IDENTITY_SERVER_APIURL_SUCCESS, IDENTITY_SERVER_APIURL_LOADING, IDENTITY_SERVER_APIURL_RESET } from "../constants/Constant";




export function fetchIdentityServerApiUrl(username, password) {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var raw = JSON.stringify({
        "UserName": username,
        "Password": password
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
        mode: 'cors',

    };
    return (dispatch) => {
        dispatch(identityServerLoading())

        fetch("https://stagingidentityserver.vitonta.com/api/Authentication", requestOptions)
            .then(res => res.json())
            .then(json => {

                if (json) {
                    dispatch(identityServerSuccess(json))

                }
            })
            .catch(err => {
                dispatch(identityServerFailed(err))
            })
    }
}

export function resetIdentityServerData() {
    return (dispatch) => {
        dispatch(identityServerRefresh())
    }
}
const identityServerLoading = () => {
    return {
        type: IDENTITY_SERVER_APIURL_LOADING
    }
}
const identityServerRefresh = () => {
    return {
        type: IDENTITY_SERVER_APIURL_RESET
    }
}

const identityServerSuccess = (data) => {
    return {
        type: IDENTITY_SERVER_APIURL_SUCCESS,
        data: data
    }
}
const identityServerFailed = (err) => {
    return {
        type: IDENTITY_SERVER_APIURL_FAILED,
        err: err
    }
}
