import { MESSAGE_BYID_LISTING, MESSAGE_BYID_LISTING_SUCCESS, MESSAGE_BYID_LISTING_LOADING, MESSAGE_BYID_LISTING_ERROR, MESSAGE_BYID_LISTING_FAILED, MESSAGE_BYID_LISTING_CLEAR } from '../../constants/Constant'


//SET INITAILS STATE
const initialState = {
    messageData: null,
    messageDataLoading: false,
    messageDataSuccess: false,
    messageDataFailed: false,
    messageDataError: null,
    messageDataStatus: "idle",
  }

//export the defult state

export default function gettingMessageByIdReducer(state = initialState, action) {
    switch (action.type) {
        case MESSAGE_BYID_LISTING:
            return {
                ...state,
                messageDataLoading: true,
                messageDataStatus: "loading",
                messageDataSuccess: false,
                messageDataFailed: false,
                messageDataError: null,
                messageData: null,
            }
        case MESSAGE_BYID_LISTING_CLEAR:
            return {
                ...state,
                messageData: null,
                messageDataLoading: false,
                messageDataSuccess: false,
                messageDataFailed: false,
                messageDataError: null,
                messageDataStatus: "idle",
            }

        case MESSAGE_BYID_LISTING_SUCCESS:
            return {
                ...state,
                messageDataLoading: false,
                messageData: action.data,
                messageDataSuccess: true,
                messageDataStatus: "success"
            }

        case MESSAGE_BYID_LISTING_FAILED:
            return {
                ...state,
                messageDataLoading: false,
                messageDataStatus: "failed",
                messageDataError: action.data,
                messageDataFailed: true
            }

        default:
            return state
    }
}