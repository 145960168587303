import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import UserInvoice from "../../components/UserInvoice";
import StatusMessages, { useMessages } from "../../components/StatusMessages";
import { connect } from "react-redux";
import { fetchBalanceFromApi } from "../../../shared/actions/GetBalanceAction";
import { Input, Radio, Select, Spin, notification, Tooltip } from 'antd'

import {
  View,
  ActivityIndicator,
} from "react-native-web";

import {
  fetchPendingInvoiceFromApi
} from "../../../shared/actions/PendingInvoiceActions";
import getUserId from "../../../shared/core/GetToken";
import GetAppSettings from "../../../shared/core/GetAppSettings";
import { ProgressBar } from "react-bootstrap";
import Settings from "../../../shared/config/Settings";
let pageNumber = 1;
const PendingInvoice = (props) => {
  const { PendingInvc, isGetting } = props.PendingInvc;
  const stripe = useStripe();
  const elements = useElements();
  const [messages, addMessage] = useMessages();
  const [pageSize, setPageSize] = useState(100); //state for the page number
  const [isReady, setIsReady] = React.useState(false); //state for check loading time
  const { Balance, isLoadoing } = props.Balance;
  const [selectedOption, setSelectedOption] = useState(2);
  const [isPaying, setIsPaying] = useState(false);
  const [amount, setAmount] = useState(Balance ? Balance : "")
  const [lookupData, setLookupData] = useState(null)
  const [invoiceNote, setInvoiceNote] = useState("");
  const [cardtypename, setCardTypeName] = useState(lookupData && lookupData[0]?.CardFee);
  const [disablePayClick, setDisablePayClick] = useState(false);
  const [progressbarNumber, setProgressbarNumber] = useState(0);
  const [paymentMethodType, setPaymentMethodType] = useState("2");

  const [validationErrorMsgs, setValidationErrorMsgs] = useState({
    amountErr: "Please enter some amount",
    cardNumberCompleteError: "Please enter correct card number",
    onceValidated: false
  })
  // const [checked, setChecked] = React.useState('first');
  // const [notes, setNotes] = React.useState('')
  // const [otheramount, setOtherAmount] = React.useState('')
  const [checkedInvoiceIDrows, setCheckedInvoiceIDrows] = useState([])

  const openNotificationWithIcon = (type, value,msg) => {
    notification[type]({
        message:msg,
        description: value,
    });
};

  const HandleCardChangeElement = (e) => {
    if (e.empty)
      setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "Card number is required" })
    if (!e.complete)
      setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "Your card number is incomplete." })

    if (!e.empty)
      setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: e?.error?.message })

      if (!e.empty && e.complete)
      setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "accomplished" })
  }

  const handleCheckedInvoices = (invoiceIDsList) => {
    let arr = invoiceIDsList.filter(item => {
      if (item.isChecked) {
        return item;
      }
    })//.map(item=> item.InvoiceID)
    setCheckedInvoiceIDrows(arr)
  }
  useEffect(() => {
    console.log("checked list ", checkedInvoiceIDrows)
  }, [checkedInvoiceIDrows])

  const handlePayNowValidations = () => {
    debugger
    let errCaught = false
    if (amount == "" || amount.length == 0) {
      errCaught = true
    }

    return errCaught
  }
  const handleCardNumberValidations = () => {
    let errCaught = false

    if (validationErrorMsgs.cardNumberCompleteError == "" ||
      validationErrorMsgs.cardNumberCompleteError == null ||
      validationErrorMsgs.cardNumberCompleteError == "Your card number is invalid." ||
      validationErrorMsgs.cardNumberCompleteError == "Your card number is incomplete." ||
      validationErrorMsgs.cardNumberCompleteError == 'Please enter correct card number') {
      errCaught = true
    }
    if (validationErrorMsgs.cardNumberCompleteError == "accomplished") {
      errCaught = false;
    }
    return errCaught

  }

  ////////PAy Now Click//////////////////
  const handleSubmit = async (e) => {

    e.preventDefault();
    
    let isAmountValidated = handlePayNowValidations()
    let isCardValidated = handleCardNumberValidations()
    setValidationErrorMsgs({ ...validationErrorMsgs, onceValidated: true });

    if (isAmountValidated || isCardValidated) {
       return
    }
    setProgressbarNumber(25)
    // setIsPaying(true);
    setDisablePayClick(true)

    const itemUserInfo = await getUserId().then((userInfo) => {
      return JSON.parse(userInfo);
    });
    //console.log("iteminfo",itemUserInfo.parentname);

    //////////////////////////////////////temp payment call start//////////////////////////////////////////////////////
    let invoiceGridDetail = checkedInvoiceIDrows;
    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Token": itemUserInfo.token
      },
      body: JSON.stringify(invoiceGridDetail),
      redirect: 'follow'
    };
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');
    const payloadqueryString = `?PatientID=${itemUserInfo.parentid}&CardType=${cardType?.trim()}&cardCharges=${cardCharges.trim()}&amount=${amount}&notes=${invoiceNote}`;

    const responseTempPayment = await fetch(`${Settings.apiUrl}patient/SaveTempPayment${payloadqueryString}`, requestOptions);
    const temppaymentResult = await responseTempPayment.json();

    if(temppaymentResult=="-1"){
      setDisablePayClick(false)
      setProgressbarNumber(100)
      /////failed temp payment
      return;
    }

    //////////////////////////////////////////////////send stripe call////////////////////////////////////////////////////////////////////////////
     if (temppaymentResult > 0) {
      // if (!stripe || !elements) {
      //   addMessage("Stripe.js has not yet loaded.");
      //   return;
      // }

      const { error: backendError, clientSecret } = await fetch("https://us-central1-myindici-nz.cloudfunctions.net/indiciportalpayments/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodType: "card",
          currency: "nzd",
          amount: amount*100,
        }),
      }
      ).then((r) => r.json());

      if (backendError) {
        console.log("card paymentzzzzzzzzzzzz" + backendError.message)
        //addMessage(backendError.message);
        setIsPaying(false)
       setDisablePayClick(false)
        setProgressbarNumber(100)

        return;
      }
       setProgressbarNumber(45)

      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: localStorage.getItem("@userToken").parentname, // send actual name here
              email: localStorage.getItem("@userToken").email,
            },
          },
        });

      if (stripeError) {
        console.log("final confirm card payment error: " + stripeError.message)
        setIsPaying(false)
       setDisablePayClick(false)
        setProgressbarNumber(100)

        //addMessage(stripeError.message);
        return;
      }

      // Show a success message to your customer, There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the payment_intent.succeeded event that handles any business critical post-payment actions.
      //addMessage(`Payment succeed : ${paymentIntent.status}: ${paymentIntent.id}`);
    //////////////////////////////////////////////////final stripe call end////////////////////////////////////////////////////////////////////////////
   if(paymentIntent?.status=="succeeded"){ 
          ///////////////////////////final payment call//////////////////////////////////////////////////////
          //openNotificationWithIcon('success') // alert('payment succeeeded')

     setProgressbarNumber(90)

          const payloadqueryStringFinal = `?PatientID=${itemUserInfo.parentid}&electronicPaymentID=${temppaymentResult}`
     const responsePaymentFinal = await fetch(`${Settings.apiUrl}patient/SavePayment${payloadqueryStringFinal}`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "Token": itemUserInfo.token
            },
            redirect: 'follow'
          });

          const finalpaymentResult = await responsePaymentFinal.json();

          if (finalpaymentResult) {
            props.getPendingInvoice(pageSize, pageNumber);
            setAmount("");
            setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "Please enter amount", onceValidated: false })
            openNotificationWithIcon('success','Payment succeeded successfully')
            setDisablePayClick(false)

            //setSelectedOption(2)
            //setInvoiceNote("")
            //elements.getElement(CardElement).clear();
          }
          else
          {
            //final payment not saved successfully
            openNotificationWithIcon('failed','Payment unsucessfull')
          }
          ///////////////////////////final payment call END//////////////////////////////////////////////////////
        }
        else{

        }
    };
    setProgressbarNumber(100)

    props.getBalance()
    setDisablePayClick(false)
    setIsPaying(false)
  }

  const handleInvoiceReset = (e) => {
    e.preventDefault()
    setAmount("")
    setSelectedOption(2)
    setInvoiceNote("")
    elements.getElement(CardElement).clear();

  }
  useEffect(() => {
    props.getPendingInvoice(pageSize, pageNumber);
  }, []);

  useEffect(() => {
    props.getBalance()
  }, [])
  const radioChanged = (e) => {
    if (e.target.value === 1) {
      setAmount(Balance?.split(" ")[0]?.replace("$", ""))
    } else {
      setAmount("")
    }
    setSelectedOption(e.target.value)
  }
  useEffect(() => {
 
    GetAppSettings().then((info) => {

      const set = JSON.parse(info)
      setPaymentMethodType(set.PaymentMethodType ? set.PaymentMethodType : "2")
      setLookupData(JSON.parse(set.PaymentCardTypes))
      setCardTypeName(JSON.parse(set.PaymentCardTypes)[0].CardFee)
   
    })
  }, [])
  useEffect(() => { }, [amount])
  return (
    <div className="accounts-info-container">
      <div className="payment-details-container mb-4">
      
      </div>
      <div className="payment-input-controls-container grid mb-4">
        <div className="grid-body">
          <form style={{ opacity: (paymentMethodType != "2" ? 0.5 : 1), pointerEvents: (paymentMethodType != "2" ? "none" : "all") }} action className="payment-details-form">
            <div className="form-group d-inline d-lg-flex">
              <div className="form-control-container mb-2 mb-lg-0">
                <Radio.Group onChange={(e) => radioChanged(e)} value={selectedOption}>
                  <Radio value={1}>Pay total outstanding balance</Radio>
                  <Radio value={2}>Pay another amount</Radio>
                </Radio.Group>
              </div>
              <div className="form-control-container mb-2 mb-lg-0 ml-lg-auto">

                <div className="account-balance payment-card py-2 d-flex align-items-center">
                  <p className="payment-label m-0 mr-2">Account Balance:</p>
                  <h4 className="payment-amount m-0">{Balance}</h4>
                </div>
              </div>

            </div>
            <div className="form-group row">
              <div className="form-item col-6">

                <label className="mb-0">Amount</label>
                <div className="form-control-container d-flex flex-column align-items-start">
               
                  <Input
                    placeholder="Amount"
                    disabled={selectedOption != 2}
                    value={amount}
                    type="number"
                    onChange={(e) => {
                      setAmount(e.target.value);
                      if (e.target.value.length == 0)
                        setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "Please enter amount" })
                      else
                        setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "" })
                    }}
                    name="amountErr"
                  />
                  <p className="text-danger">{validationErrorMsgs.onceValidated && selectedOption != 1 && validationErrorMsgs.amountErr}</p>
                </div>
              </div>
              <div className="form-item col-6">

                <label className="mb-0">Card Type</label>
                <div className="form-control-container">
                
                  {lookupData ?

                    <Select
                      onChange={(e) => { setCardTypeName(e) }}
                      value={cardtypename}
                      style={{
                        width: '100%',
                      }}
                      // disabled
                      options={lookupData.map(item => {
                        return {
                          value: item.CardFee,
                          label: item.CardFee,
                        }
                      })
                      }
                    /> : <Spin />
                  }

                </div>
              </div>

            </div>
          
            <div className="form-group row">
              <div className="form-item col-6">

                <div id="payment-form">
                  <label htmlFor="card">Card</label>
                  <div className="border border-secondary p-2">

                    <CardElement

                      name="cardNumberCompleteError"
                      id="card"
                      options={{
                        style: {
                          base: {
                            fontSize: "16px",
                            color: "#424770",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                            lineHeight: "32px",

                          },

                          invalid: {
                            color: "#9e2146",
                          },
                        },
                        hidePostalCode: true
                      }}
                      onChange={HandleCardChangeElement}
                    />

                  </div>
                </div>
                <p className="text-danger">{validationErrorMsgs.onceValidated  && validationErrorMsgs.cardNumberCompleteError?.replace("accomplished","")}</p>

                <StatusMessages messages={messages} />
              </div>

              <div className="form-item col-6">

                <label className="mb-0">Notes</label>
                <div className="form-control-container">
                  <textarea
                    name
                    id
                    placeholder="Notes"
                    className="form-control"
                    rows={2}
                    style={{
                      marginTop: "5px"
                    }}
                    defaultValue={""}
                    value={invoiceNote}
                    onChange={(e) => setInvoiceNote(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
            </div>

            <div className="form-group">
              <div className="form-submit-btn d-flex justify-content-end">
                <button onClick={handleInvoiceReset} className="btn btn-sm amount-reset-btn ml-2 ml-lg-4">
                  Reset
                </button>

                <Tooltip title={typeof PendingInvc !== "undefined" && PendingInvc.length > 0 ? "Pay Now" : "No invoices to pay"}>

                  <button onClick={(e) => handleSubmit(e)} style={{ opacity: disablePayClick || typeof PendingInvc == "undefined" || PendingInvc.length == 0 ? "0.3" : "1" }} disabled={disablePayClick || typeof PendingInvc == "undefined" || PendingInvc.length == 0} className="btn btn-sm pay-with-stripe-btn ml-2 ml-lg-4">
                  {isPaying ? <ActivityIndicator size="small" color="#fff" /> : 'Pay'}
                </button>
                </Tooltip>
              </div>
            </div>
          </form>

          {paymentMethodType != "2" ? <p className="text-danger text-center">{paymentMethodType == "3" ? "Poli" : "Payment Express"} is not set up for this app</p> : null}
          {progressbarNumber != 100 && progressbarNumber != 0 ? <ProgressBar style={{ height: "1.5px" }} now={progressbarNumber} animated /> : null}
        </div>
        <strong>
          <strong></strong>
        </strong>
      </div>
      {isGetting && (
        <View style={{ textAlign: "center", marginBottom: 5 }}>
          <ActivityIndicator size="small" color="#00A1DE" />
        </View>
      )}
      {typeof PendingInvc !== "undefined" && PendingInvc.length ? (
        <UserInvoice AllInvc={PendingInvc} enteredAmount={amount} invoiceType="pending" handleCheckedInvoices={handleCheckedInvoices} />
      
      ) : !isGetting && <div style={{ overflowX:'auto' }}>
        <table className="invoice-table table table-striped table-hover" style={{ width: "100%" }}>
          <thead  >
            <tr className="invoice-table-row">
                {props.invoiceType == "pending" && <th style={{width:"100px"}}></th>}
                <th className="notes-th" style={{width:"300px"}}>Notes</th>
                <th style={{width:"150px"}}>Type</th>
                <th style={{width:"150px"}}>Reference No.</th>
                <th style={{ width: "150px" }}>Amount</th>
                <th style={{ width: "150px" }}>Balance</th>
                <th style={{ width: "100px" }}>Pay Now</th>
                <th style={{width:"100px"}}>Date</th>
                <th style={{width:"37px"}}>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>

              <td colSpan={11} className="alert alert-warning" style={{ textAlign: "center" }}>
                No Record Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>}


    </div>
  );
};

function mapStateToProps(state) {
  return {
    PendingInvc: state.PendingInvc,
    Balance: state.Balance,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPendingInvoice: (pageSize, pageNumber) =>
      dispatch(fetchPendingInvoiceFromApi(pageSize, pageNumber)),
    getBalance: () => dispatch(fetchBalanceFromApi()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PendingInvoice);
// export default PendingInvoice;
