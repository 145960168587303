import React, { useEffect, useState } from 'react';
import { View, Text, ActivityIndicator, FlatList } from "react-native-web";
import { DatePicker } from "antd";
import 'antd/dist/antd.css';
import { connect } from 'react-redux'

import AppointmentHandler from '../../components/AppointmentHandler';
import { searchAppointmentFromApi, searchFilteredAppointmentFromApi, showALlAppointmentFromApi } from "../../../shared/actions/SearchAppointmentActions";
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime, GetMonthNumber } from '../../../shared/core/datehelper'

import moment from "moment";

const { RangePicker } = DatePicker;
const SearchAppointmentView = props => {
    // const [filteredData, setFilteredData] = useState(null)
    const [activeStatus, setActiveStatus] = useState("All");
    const [appointsDisplayed, setAppointsDisplayed] = useState([])
    const { Profile } = props.Profile
    const { Practise } = props.Practise
    useEffect(() => {
        // props.searchAppointment()
        props.getAllAppointments()

          
    }, [])
    const { SearchAppointment, isGettingSearch } = props.SearchAppointment;
    const dateFormat = 'DD-MM-YYYY';
    //const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const dateChange = (date, dateString) => {
        if (date === null) {
            // props.searchAppointment()
            props.getAllAppointments()
        }
        else {

            //console.log(date)
            //console.log(JSON.stringify(date[0]))




            // // alert(dateString)
            // var str = dateString.toString();
            // //alert(str)
            // str = str.split(",");

            // let sDate = GetMonthNumber(str[0])// GetYear(str[0]) + '-' + GetMonthNumber(str[0]) + '-' + GetAppointmentDate(str[0])
            props.searchAppointment(JSON.stringify(date[0]).replace(/['"]+/g, ''), JSON.stringify(date[1]).replace(/['"]+/g, ''))
            setActiveStatus("All")

            // //console.log(str);


            // //console.log(moment(str[0]).format())

            // //var d = new Date(str[0]);

            // ////console.log(d.getDate())
            // // var D = new Date(str[0]).toString().split("-");
            // ////console.log(D[2] + "-" + D[1] + "-" + D[3]);
            // //alert(str[0] + '-----' + d.getFullYear())
        }

    }

    const getTagStyles = (type) => {
        if (type === "Cancelled") {
            return 'card-header text-white bg-warning'
        }
        if (type === "Missed") {
            return 'card-header text-white bg-danger'
        }
        if (type === "Future") {
            return 'card-header text-white bg-primary'
        }
        if (type === "Completed") {
            return 'card-header text-white bg-success'
        }
        if (type === "Other") {
            return 'card-header text-white bg-success'
        }
    }
    const filterFinishedAppoints = () => {
        console.log("all appointments", SearchAppointment)
    }
    const filterMissedAppoints = () => {
        // props.searchFilteredAppointment("2")
        setActiveStatus("Missed")
        console.log("SearchAppointment",SearchAppointment[0].resource.period.start)
                
        if (SearchAppointment !== "undefined" && SearchAppointment.length) {
            setAppointsDisplayed(SearchAppointment.filter(item => item.resource.extension[6].valueString === "Missed" || moment(SearchAppointment[0]?.resource?.period?.start)._d < new Date() ))
        }
    }
    const filterCompletedAppoints = () => {
        // props.searchFilteredAppointment("8")
        setActiveStatus("Completed")
        if (SearchAppointment !== "undefined" && SearchAppointment.length) {
            setAppointsDisplayed(SearchAppointment.filter(item => item.resource.extension[6].valueString === "Completed"))
        }
    }
    const filterCancelledAppoints = () => {
        // props.searchFilteredAppointment("4")
        setActiveStatus("Cancelled")
        if (SearchAppointment !== "undefined" && SearchAppointment.length) {
            setAppointsDisplayed(SearchAppointment.filter(item => item.resource.extension[6].valueString === "Cancelled"))
                        }
    }
    const filterAllAppoints = () => {
        // props.searchFilteredAppointment("0")
        setActiveStatus("All")
        if (SearchAppointment !== "undefined" && SearchAppointment.length) {
            setAppointsDisplayed(SearchAppointment)
        }

    }
    useEffect(() => {
        // console.log(props.status)
    }, [appointsDisplayed])
    useEffect(() => {
        if (SearchAppointment !== "undefined" && SearchAppointment.length) {
            setAppointsDisplayed(SearchAppointment);
        }
    }, [SearchAppointment])
    return (
        <div>
            <div className="row row-margin-zero">
                <div className="col-12">
                    <div style={{ marginLeft: 10, alignItems: 'center' }}>
                        {/* <span className="badge badge-primary" style={{ marginLeft: 15, marginTop: 10 }}>Future</span> */}
                        <span className="appoint-status-badge badge badge-success " style={{ marginLeft: 15, marginTop: 10, boxShadow: (activeStatus === "Completed" ? "0 0 0 1px white,0 0 0 3px #00e093" : "") }} onClick={filterCompletedAppoints}>Completed</span>
                        <span className="appoint-status-badge badge badge-danger" style={{ marginLeft: 15, marginTop: 10, boxShadow: (activeStatus === "Missed" ? "0 0 0 1px white,0 0 0 3px #ff5f66" : "") }} onClick={filterMissedAppoints}>Missed</span>
                        <span className="appoint-status-badge badge badge-warning" style={{ marginLeft: 15, marginTop: 10, boxShadow: (activeStatus === "Cancelled" ? "0 0 0 1px white,0 0 0 3px #f0825f" : "") }} onClick={filterCancelledAppoints}>Cancelled </span>
                        <span className="appoint-status-badge badge badge-primary" style={{ marginLeft: 15, marginTop: 10, boxShadow: (activeStatus === "All" ? "0 0 0 1px white,0 0 0 3px #4d8af0" : "") }} onClick={filterAllAppoints}>All </span>
                    </div>

                </div>
            </div>
            <div style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
            <Text style={{ marginRight: "5px", fontSize: "16px", fontWeight: "600" }}>Filter Appointments History by: </Text>
            <RangePicker

                className="appointment-datepicker"
                format={dateFormat}
                    disabledDate={(current) => {
                        return current && current < (moment().subtract(1, "year"))
                    }}
                    getPopupContainer={(trigger) =>
                        trigger.parentNode
                    }
                    onChange={dateChange} />

            </div>


            {
                isGettingSearch && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (SearchAppointment) !== 'undefined' && SearchAppointment.length ? (
                    <FlatList
                        style={{
                            flexDirection: 'column', paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}

                        //contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-evenly' }}
                        data={appointsDisplayed}
                        renderItem={({ item }) => (
                            item.resource.extension[6].valueString != "Future" &&
                            <AppointmentHandler
                                providerName={item.resource.extension[2].valueString ? item.resource.extension[2].valueString : 'N/A'}
                                styleName={getTagStyles(item.resource.extension[6].valueString)}
                                appType={item.resource.extension[0].valueString ? item.resource.extension[0].valueString : 'N/A'}
                                nhi={Profile.length && Profile[0]?.resource?.extension[3]?.valueString}
                                appointmentSatus={item.resource.extension[6].valueString ? item.resource.extension[6].valueString : 'N/A'}
                               // appointmentDate={GetAppointmentDate(item.resource.period.start) + ' ' + GetMonthName(item.resource.period.start) + ' ' + GetYear(item.resource.period.start)}
                                appointmentDate={GetAppointmentDate(item.resource.period.start.split('T')[0]) + ' ' + GetMonthName(item.resource.period.start.split('T')[0]) + ' ' + GetYear(item.resource.period.start.split('T')[0])}
                                praticeAddress={item.resource.extension[3].hasOwnProperty('valueString') ? item.resource.extension[3].valueString : '(N/A)'}
                                firstitem={item.resource.extension[5].valueString ? item.resource.extension[5].valueString : 'N/A'}
                                secondItem={GetDayName(item.resource.period.start.split('T')[0]) + ', ' + FormateUTCTime(item.resource.period.start)}
                                patientDetails={item.resource.extension[1].valueString} //+ ', ' + item.resource.extension[0].valueString}}
                                location={item.resource.extension[4].hasOwnProperty('valueString') ? item.resource.extension[4].valueString : '(N/A)'}
                                icTpye={'mdi mdi-18px mdi-alert-circle'} //this icons for cancel reason
                               // reason={"Appointment Status: " + item.resource.extension[6].valueString}
                                status={item.resource.extension[6].valueString}
                                type={item.resource.extension[6].valueString}
                                icTpyeCancel={'mdi mdi-18px mdi-close-circle'}
                                reasonForCancel={item.resource.extension[6].valueString === "Cancelled" ? item.resource.extension[9].valueString !== undefined || item.resource.extension[9].valueString !== " " ? 'Reason: ' + item.resource.extension[9].valueString : 'Reason: N/A' : null}
                                patientProfile={Profile}
                                organizationData={Practise}
                                birthdate={moment(Profile.length && Profile[0]?.resource?.birthDate).format('DD MMM YYYY')}
                                address={Profile.length && Profile[0]?.resource?.address[0]?.text}
                                duration={item.resource.extension[7].valueDecimal}
                                videoCall={false}
                            />
                        )}
                        horizontal={false}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={30}

                    />
                ) : !isGettingSearch && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                        <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                </View>
            }

            {/* <AppointmentHandler styleName={'card-header text-white bg-warning'} />
            <AppointmentHandler styleName={'card-header text-white bg-danger'} />
            <AppointmentHandler styleName={'card-header text-white bg-warning'} />
            <AppointmentHandler styleName={'card-header text-white bg-primary'} />
            <AppointmentHandler styleName={'card-header text-white bg-primary'} />
            <AppointmentHandler styleName={'card-header text-white bg-success'} /> */}
        </div>
    );
}


function mapStateToProps(state) {
    return {
        SearchAppointment: state.SearchAppointment,
        Profile: state.Profile,
        Practise: state.Practise,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        searchAppointment: (fromDate, toDate) => dispatch(searchAppointmentFromApi(fromDate, toDate)),
        searchFilteredAppointment: (appointType) => dispatch(searchFilteredAppointmentFromApi(appointType)),
        getAllAppointments: () => dispatch(showALlAppointmentFromApi()),
        //getMoreMissedAppoint: (pageSize, pageNumber) => dispatch(fetchMoreAppointmentsFromApi(pageSize, pageNumber))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(SearchAppointmentView)

//export default SearchAppointmentView
