import { LISTING_MESSAGE_ROLES, LISTING_MESSAGE_ROLES_SUCCESS, LISTING_MESSAGE_ROLES_LOADING, LISTING_MESSAGE_ROLES_ERROR, LISTING_MESSAGE_ROLES_FAILED } from '../../constants/Constant'


//SET INITAILS STATE
const initialState = {
    rolesData: null,
    rolesDataLoading: null,
    rolesDataSuccess: null,
    rolesDataFailed: null,
    rolesDataError: null,
    rolesDataStatus: "idle",
}

//export the defult state

export default function LoadMessagingRolesReducer(state = initialState, action) {
    switch (action.type) {
        case LISTING_MESSAGE_ROLES:
            return {
                ...state,
                rolesDataLoading: true,
                rolesDataStatus: "loading"
            }

        case LISTING_MESSAGE_ROLES_SUCCESS:
            return {
                ...state,
                rolesDataLoading: false,
                rolesData: action.data,
                rolesDataSuccess: true,
                rolesDataStatus: "success"
            }

        case LISTING_MESSAGE_ROLES_FAILED:
            return {
                ...state,
                rolesDataLoading: false,
                rolesDataStatus: "failed",
                rolesDataError: action.data,
                rolesDataFailed: true
            }

        default:
            return state
    }
}