import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';

const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
const MsgCard = ({
  imgSrc = "",
  sender = "",
  subject = "",
  date = "",
  isRead = false,
  onSelect = () => console.log("No On click Listener"),
  isActive = false,
  profileColor = "#47b2f6",
  msgChecked = false,
  handleCheckChanged = ()=>{console.log("not handled")},
  setIndeterminate = ()=>{console.log("indeterminate state")}
}) => {

  const [thisMessageRead, setThisMessageRead] = useState(isRead ? true : false);
    const [thisMessageChecked, setThisMessageChecked] = useState(msgChecked ? true : false);
    useEffect(()=>{
      setThisMessageChecked(msgChecked)
    },[msgChecked])
  useEffect(() => {
    console.log("this mesage read", thisMessageRead)
  }, [thisMessageRead])
  return (
    <>
      <div
        className={
          "msgCard d-flex align-items-center px-2 justify-content-center gap3 " +
          (isActive ? " active " : "") +
          (thisMessageRead ? " isRead " : " ")
        }
        
      >
        {/* <Checkbox className="mr-2" onClick={()=>handleCheckChanged()} checked={thisMessageChecked} onChange={()=>setThisMessageChecked(!thisMessageChecked)} /> */}
        <Form.Check className="message-list-main-checkbox" checked={thisMessageChecked} onChange={() => setThisMessageChecked(!thisMessageChecked)} onClick={() => {
          handleCheckChanged()
        }} />
        <div className="profileImg d-flex justify-content-center align-items-center p-3" style={{backgroundColor: profileColor }}>
          {/* <img src={imgSrc} alt="" /> */}
          {sender.slice(0, 1)}
        </div>
        <div className="msgDetails w-100" onClick={() => {
          console.log("inside onClick")
          setThisMessageRead(true)
          onSelect()
        }}>
          <div className="detailsTop">
            <p className="senderName">{sender} 
              <span className="msgDate">{date}</span>
            </p>
           
          </div>
          <p className="subjectContent">{subject}</p>
        </div>
      </div>
      <hr className={"m-0 mx-2 " + (isActive ? "d-none" : "d-block")} />
    </>
  );
};

export default MsgCard;
