import { IDENTITY_SERVER_APIURL_FAILED, IDENTITY_SERVER_APIURL_SUCCESS, IDENTITY_SERVER_APIURL_LOADING, IDENTITY_SERVER_APIURL_RESET } from "../constants/Constant";

//inital state
const initalState = {
    identityApiData: null,
    identityApiDataLoading: false,
    identityApiDataFailed: false,
    identityApiDataSuccess: false,
    identityApiDataError: false,
}
export const identityData = initalState.identityApiData
//export default state
export default function identityServerReducer(state = initalState, action) {
    switch (action.type) {
        case IDENTITY_SERVER_APIURL_LOADING:
            return {
                ...state,
                identityApiData: null,
                identityApiDataLoading: true,
                identityApiDataFailed: false,
                identityApiDataSuccess: false,
                identityApiDataError: false,
            }
        case IDENTITY_SERVER_APIURL_RESET:
            return {
                ...state,
                identityApiData: null,
                identityApiDataLoading: false,
                identityApiDataFailed: false,
                identityApiDataSuccess: false,
                identityApiDataError: false,
            }

        case IDENTITY_SERVER_APIURL_SUCCESS:
            return {
                ...state,
                identityApiData: action.data,
                identityApiDataLoading: false,
                identityApiDataFailed: false,
                identityApiDataSuccess: true,
                identityApiDataError: false,
            }

        case IDENTITY_SERVER_APIURL_FAILED:
            return {
                ...state,
                identityApiData: null,
                identityApiDataLoading: false,
                identityApiDataFailed: true,
                identityApiDataSuccess: false,
                identityApiDataError: action.err,
            }

        default:
            return state
    }
}