import {
    START_SEARCH_APPOINTMENTN,
    START_SEARCH_APPOINTMENTN_SUCCESS,
    START_SEARCH_APPOINTMENTN_FAILURE,
    START_SEARCH_APPOINTMENTN_FINISHED,

} from "../constants/Constant";
import getPaitentAppointments from "../services/AppointmentServices";
import getUserId from "../core/GetToken";


export const searchAppointmentFromApi = (fromDate, toDate) => {
    return (dispatch) => {
        dispatch(searchAppointment())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            const queryString = '?PatientID=' + item.userid + '&PageSize=200&PageNumber=1&FromDate=' + fromDate + '&ToDate=' + toDate
          //  alert(queryString)
           //const queryString = '?PatientID=' + item.userid + '&PageSize=999&PageNumber=1&AppointmentType=0'
            //console.log("SearchString==>" + queryString)
            getPaitentAppointments(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    //console.log(JSON.stringify(json));
                    json[0].hasOwnProperty('entry') ? dispatch(searchAppointmentSucess(json[0].entry)) : dispatch(searchAppointmentFinished())
                })
                .catch(err => dispatch(searchAppointmentFail(err)))
        })
    }
}
export const showALlAppointmentFromApi = () => {
    return (dispatch) => {
        dispatch(searchAppointment())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            const queryString = '?PatientID=' + item.userid + '&PageSize=200&PageNumber=1&AppointmentType=0'
            //  alert(queryString)
            //const queryString = '?PatientID=' + item.userid + '&PageSize=999&PageNumber=1&AppointmentType=0'
            //console.log("SearchString==>" + queryString)
            getPaitentAppointments(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    //console.log(JSON.stringify(json));
                    json[0].hasOwnProperty('entry') ? dispatch(searchAppointmentSucess(json[0].entry)) : dispatch(searchAppointmentFinished())
                })
                .catch(err => dispatch(searchAppointmentFail(err)))
        })
    }
}
export const searchFilteredAppointmentFromApi = (appointType) => {
    return (dispatch) => {
        dispatch(searchAppointment())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            const queryString = '?PatientID=' + item.userid + '&PageSize=200&PageNumber=1&AppointmentType=' + appointType
            //  alert(queryString)
            //const queryString = '?PatientID=' + item.userid + '&PageSize=999&PageNumber=1&AppointmentType=0'
            //console.log("SearchString==>" + queryString)
            getPaitentAppointments(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    //console.log(JSON.stringify(json));
                    json[0].hasOwnProperty('entry') ? dispatch(searchAppointmentSucess(json[0].entry)) : dispatch(searchAppointmentFinished())
                })
                .catch(err => dispatch(searchAppointmentFail(err)))
        })
    }
}

const searchAppointment = () => {
    return {
        type: START_SEARCH_APPOINTMENTN
    }
}

//data success
const searchAppointmentSucess = (data) => {
    return {
        type: START_SEARCH_APPOINTMENTN_SUCCESS,
        data,
    }
}

//data failed
const searchAppointmentFail = () => {
    return {
        type: START_SEARCH_APPOINTMENTN_FAILURE
    }
}
const searchAppointmentFinished = () => {
    return {
        type: START_SEARCH_APPOINTMENTN_FINISHED
    }
}