import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Text,
    ActivityIndicator,
    Dimensions
} from 'react-native-web';
import { connect } from 'react-redux'
import ImmuneHandler from "../../components/ImmuneHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'

import { fetchAdministeredImmunefromApi, fetchMoreAdministeredImmunefromApi } from '../../../shared/actions/AdministeredImmuneActions';
import { useReactToPrint } from 'react-to-print';
import PrintAppointmentHeader from '../../components/PrintAppointmentHeader';
import { Tooltip } from 'antd';

const AdministeredTab = props => {


    const [pageSize, setPageSize] = useState(100);
    const [pageNumber, setPageNumber] = useState(1);
    const { AddImmune, isGettingImmune } = props.AddImmune;
    const { Profile } = props.Profile;
    const { Practise } = props.Practise;
    const [isPrintActive, setIsPrintActive] = useState(false)


    useEffect(() => {
        //alert('Called Future')
        props.getAddministratedImmune(pageSize, pageNumber);
    }, [])

    const componentRef = useRef();
    const pageStyleCss = `@page {
        size: A4 landscape;
      }
      *{
          font-family: sans-serif;
        }
         .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        h3,h4,h5,p{
            margin:0px;
        }
        p span{
            font-weight: 600;
        }
        .ind-med{
            border-bottom: 1px solid grey;
            padding:5px 10px; 
        }
        .card-text, .card-title, .med-date{
            color: black !important;
            font-weight: 300 !important;
        }
        .med-heading{
            color: black !important;
            font-weight: 600 !important;
            margin-bottom: 3px;
        }
     `;
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: false,
        pageStyle: pageStyleCss,
    })
    useEffect(() => {
        if (isPrintActive) {
            handlePrint()
            setIsPrintActive(false)
        }
    }, [isPrintActive])
    return (
        <div className="d-flex flex-column">
            {
                isGettingImmune && <View style={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (AddImmune) !== 'undefined' && AddImmune.length ? (
                    <>
                        <Tooltip title="Print" className="print-icon d-flex align-self-end justify-content-end align-items-center" style={{ marginRight: "20px" }} onClick={() => { setIsPrintActive(true) }}>
                            <div className='print-immune-btn btn btn-primary mr-2'>
                            Print
                           </div>
                            {/* <p className="m-0 print-text">Print</p> */}
                        </Tooltip>
                        {/* <button className="btn btn-success align-self-end" style={{ marginRight: "20px" }} onClick={() => setIsPrintActive(true)}>Print</button> */}
                        <div id="printModal" ref={componentRef} >
                            <PrintAppointmentHeader organizationData={Practise} patientProfile={Profile} isPrintActive={isPrintActive} />
                    <FlatList
                        style={{
                            flexDirection: 'column',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        data={AddImmune}
                        renderItem={({ item }) => (
                            // <ImmuneWebHandler
                            //     styles={{ backgroundColor: Colors.lowAllergy }}
                            //     heading={'Adminstered on: '}
                            //     date={GetAppointmentDate(item.resource.extension[12].valueDateTime) + ' ' + GetMonthName(item.resource.extension[12].valueDateTime) + ' ' + GetYear(item.resource.extension[12].valueDateTime)}
                            //     type_diagnoes={item.resource.extension[0].valueString}
                            //     indication={item.resource.extension[5].hasOwnProperty('valueString') ? item.resource.extension[5].valueString : ''}
                            //     vacc={item.resource.extension[6].hasOwnProperty('valueString') ? item.resource.extension[6].valueString : ''}
                            //     datedue={'Due Date: ' + GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}
                            // />
                            <ImmuneHandler
                                styles={'card-header text-white bg-success'}
                                heading={item.resource.extension[0].valueString}//{'Adminstered on: '}
                                date={GetAppointmentDate(item.resource.date) + ' ' + GetMonthName(item.resource.date) + ' ' + GetYear(item.resource.date)}
                                month={GetYear(item.resource.extension[12].valueDateTime)}
                                //   date={GetAppointmentDate(item.resource.extension[12].valueDateTime) + ' ' + GetMonthName(item.resource.extension[12].valueDateTime) + ' ' + GetYear(item.resource.extension[12].valueDateTime)}
                                //type_diagnoes={item.resource.extension[0].valueString}
                                indication={item.resource.extension[5].hasOwnProperty('valueString') ? item.resource.extension[5].valueString : 'N/A'}
                                vacc={item.resource.extension[6].hasOwnProperty('valueString') ? item.resource.extension[6].valueString : 'N/A'}
                                datedue={'Due Date: ' + GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}
                            />
                            )}
                            keyExtractor={item => item.resource.id}
                            initialNumToRender={10}
                            maxToRenderPerBatch={10}
                            windowSize={7}
                            />
                        </div>
                    </>
                ) : !isGettingImmune && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                        <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                </View>

            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        AddImmune: state.AdministeredImmune,
        Profile: state.Profile,
        Practise: state.Practise
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAddministratedImmune: (pageSize, pageNumber) => dispatch(fetchAdministeredImmunefromApi(pageSize, pageNumber)),
        //getAddministratedImmuneMore: (pageSize, pageNumber) => dispatch(fetchMoreAdministeredImmunefromApi(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdministeredTab)

// export default AdministeredTab
