import React, { useEffect, useState } from "react";
import {
	StyleSheet,
	Text,
 	View,
	TouchableOpacity,
} from "react-native";
import {  Modal, Tooltip, Divider } from "antd";
import { useReactToPrint } from 'react-to-print';
import { useRef } from "react";
import PrintAppointmentHeader from "./PrintAppointmentHeader";


//export default function AppointmentHandler() {
const AppointmentHandler = (props) => {

	const [isPrintActive, setIsPrintActive] = useState(false)
	//const [zoomMeetModalVisible, setZoomMeetModalVisible] = useState(false)
	const componentRef = useRef();
	const pageStyleCss = `@page {
        size: A4 landscape;
      }
      *{
          font-family: sans-serif;
        }
        .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        h3,h4,h5,p{
            margin:0px;
        }
        p span{
            font-weight: 600;
        }`;
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: false,
		pageStyle: pageStyleCss,
		onAfterPrint: () => setIsPrintActive(false)
	})
	// const handlePrint = () => {
	// 	var mywindow = window.open('', 'PRINT', 'height=400,width=600');

	// 	mywindow.document.write('<html><head><title>' + document.title + '</title>');
	// 	mywindow.document.write('</head><body >');
	// 	mywindow.document.write('<h1>' + document.title + '</h1>');
	// 	mywindow.document.write(document.getElementById("printModal").innerHTML);
	// 	mywindow.document.write('</body></html>');

	// 	mywindow.document.close(); // necessary for IE >= 10
	// 	mywindow.focus(); // necessary for IE >= 10*/

	// 	mywindow.print();
	// 	mywindow.close();

	// 	return true;
	// }

	// useReactToPrint({
	// 	content: () => componentRef.current,
	// });
	//const [meetingId, setMeetingId] = useState()
	//	const [meetingPass, setPass] = useState()
	const [meetingJoinUrl, setMeetingJoinUrl] = useState()
	const [printModalVissible, setPrintModalVissible] = useState(false)
	//console.log(process.env.REACT_APP_CAPTCHA_SITEKEY)
	useEffect(() => {
		if (props.videoCounsutlConfig) {
			let json = JSON.parse(props.videoCounsutlConfig)
			//setMeetingId(json.MeetingID)
			//setPass(json.PassCode)
			setMeetingJoinUrl(json.JoinUrl)
			// alert(json.MeetingID +"----"+json.PassCode)
		}
	}, [])
	useEffect(() => {
		if (isPrintActive) {
			handlePrint()
			setIsPrintActive(false)
		}
	}, [isPrintActive])
	const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
	var MapLocation = "https://maps.google.com/maps?q=" + props.location + "&t=&z=13&ie=UTF8&iwloc=&output=embed";
	const handleZoomCall = (url) => {
		//window.location = url
		window.open(url, '_blank');

	}
	return (
		<div className="col-lg-12 ">
			<div
				className="card shadow p-0 mb-3 rounded"
				style={{ marginTop: 15, marginBottom: 10 }}>
				<div className={props.styleName}>
					<View
						style={{ flexDirection: "row", justifyContent: "space-between" }}>
						<Text style={{ fontWeight: "500", color: "#fff" }}>
							{props.providerName !== null ? props.providerName : " "}
						</Text>

						{/* <Link to="/zoom">
							Start Call
						</Link> */}

						<View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
							<Text
								style={{
									fontWeight: "500",
									color: "#fff",
									marginRight: 10,
								}}>
								{props.remianingtime ? parseInt(props?.remianingtime?.asDays()) == 0 ? props.totalHours + " Hours " + props.totalMinutes + " Minutes" : parseInt(props?.remianingtime?.asDays()) + " Day(s)" : ""}
							</Text>
							<Text
								style={{
									fontWeight: "500",
									color: "#fff",
									marginRight: 10,
								}}>
								{props.appointmentDate !== null
									? props.appointmentDate
									: " "}
							</Text>

						</View>
					</View>
					{/* <ReactToPrint
						trigger={() => <Button>Print this out!</Button>}
						content={() => componentRef.current}
					/> */}
				</div>
				<div className="card-body">
					{/* <h4 className="card-title"><i className="mdi mdi-18px mdi-home-map-marker" /> {props.praticeAddress}</h4> */}

					<div className="row">
						<div className="col-md-4">
							<h4 className="card-title">
								<i className="mdi mdi-18px mdi-home-map-marker" />{" "}
								{props.firstitem}{"  "}
							</h4>
						</div>

						<div className="col-md-3">
							<p className="card-text">
								<i
									style={{ marginRight: 4 }}
									className="mdi mdi-18px mdi-calendar-clock"
								/>
								{props.secondItem}
							</p>
						</div>
						<div className="col-md-5">
							<p className="card-text">
								Appointment Type:
								<strong>{props.appType} {
									props.appType == 'Virtual Consult - Video' && props.videoCall &&

									// <Link to={`/zoom?meetingid=${meetingId}&passcode=${meetingPass}`}>
									// <Link to={{
									// 	pathname: `/zoom`,
									// 	query: { meetId: meetingId, passCode: meetingPass }
									// }}>
									// 	(Start Call)
									// </Link>
									<div className="text-primary" style={{ cursor: "pointer" }} onClick={() => {
										handleZoomCall(meetingJoinUrl)
									}}>
										(Start Call)
										</div>
									//  {/* <p className="text-primary" style={{ cursor: "pointer" }} onClick={() => setZoomMeetModalVisible(true)}>(Start Call)</p> */}
								}</strong>
							</p>
						</div>

					</div>

					<div className="row">
						<div className="col-6">

							<p className="card-text" onClick={() => setIsLocationModalOpen(true)}>
								<i className="mdi mdi-18px mdi-hospital-marker" />
								{props.location}
							</p>

							{props.reason !== undefined && (
								<div>
									<View
										style={{
											width: "100%",
											marginTop: 0,
											borderTopWidth: 1,
											borderTopColor: "#DDDDDD",
										}}></View>
									<p className="card-text">
										<i style={{ marginRight: 4 }} className={props.icTpye} />
										{props.reason}
									</p>
									{props.reasonForCancel !== null && (
										<p className="card-text">
											<i
												style={{ marginRight: 4 }}
												className={props.icTpyeCancel}
											/>
											{props.reasonForCancel}
										</p>
									)}
								</div>
							)}
						</div>
						<div className="col-6 position-relative">
							<div className="d-flex justify-content-end">

								<div className="print-icon d-flex justify-content-end align-items-center mr-3" onClick={() => { setPrintModalVissible(true) }}>
								<svg className="mx-2 print-svg"
									width="20" height="20" viewBox="0 0 512.000000 512.000000"
									preserveAspectRatio="xMidYMid meet">

									<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
										fill="#000000" stroke="none">
										<path d="M2330 5110 c-494 -48 -950 -230 -1350 -538 -195 -150 -448 -432 -594
-662 -63 -99 -186 -351 -230 -471 -49 -134 -102 -340 -128 -499 -31 -195 -31
-565 0 -760 45 -276 116 -498 237 -745 132 -269 269 -460 489 -681 221 -220
412 -357 681 -489 247 -121 469 -192 745 -237 195 -31 565 -31 760 0 276 45
498 116 745 237 269 132 460 269 681 489 220 221 357 412 489 681 88 179 132
296 180 476 63 240 78 371 78 649 0 278 -15 409 -78 649 -48 180 -92 297 -180
476 -132 269 -269 460 -489 681 -221 220 -412 357 -681 489 -246 121 -474 193
-740 235 -147 23 -475 34 -615 20z m1253 -1080 c15 -12 31 -38 37 -58 5 -21
10 -150 10 -299 l0 -261 158 -4 c153 -3 159 -4 224 -35 77 -36 156 -105 193
-169 55 -94 58 -112 62 -484 3 -202 1 -375 -5 -417 -28 -203 -173 -351 -369
-378 l-53 -7 0 214 0 214 32 9 c90 28 94 164 6 194 -43 15 -2593 15 -2636 0
-88 -30 -84 -166 6 -194 l32 -9 0 -214 0 -214 -52 7 c-197 27 -342 175 -370
378 -6 42 -8 215 -5 417 4 372 7 390 62 484 37 64 116 133 193 169 65 31 71
32 225 35 l157 4 0 261 c0 149 5 278 10 299 6 20 22 46 37 58 25 20 33 20
1023 20 990 0 998 0 1023 -20z m45 -2250 c2 -311 0 -585 -3 -609 -5 -30 -16
-54 -35 -73 l-28 -28 -1000 0 c-992 0 -1000 0 -1025 20 -15 12 -31 38 -37 58
-11 41 -15 1184 -3 1195 3 4 484 6 1067 5 l1061 -3 3 -565z"/>
										<path d="M3694 3190 c-35 -14 -64 -59 -64 -101 0 -29 7 -45 31 -68 81 -82 218
15 165 118 -26 49 -82 71 -132 51z"/>
										<path d="M2005 2023 c-88 -23 -111 -140 -37 -190 25 -17 61 -18 592 -18 531 0
567 1 592 18 58 39 58 135 0 174 -25 17 -62 18 -577 20 -302 0 -559 -1 -570
-4z"/>
										<path d="M1978 1584 c-65 -35 -73 -121 -17 -168 l31 -26 568 0 568 0 31 26
c57 48 47 135 -19 169 -43 22 -1121 22 -1162 -1z"/>
									</g>
								</svg>
								<p className="m-0 print-text">Print</p>
							</div>
								{props.type == "Future" ? (
									<div className="print-icon d-flex justify-content-end align-items-center" onClick={() => {
										props.canelHandler();
									}}>

										<>{
											props.appointmentSatusID == 1 || props.appointmentSatusID == 2 || props.appointmentSatusID == 3 ||
												props.appointmentSatusID == 11 ? <TouchableOpacity
													style={{ flexDirection: "row", alignItems: "center" }}
												>


												<Tooltip placement="topLeft" title={"Cancel Appointment"}>
													<svg className="mx-2" fill="white" style={{ padding: "3px 3.5px", background: "black", borderRadius: "50%" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={20} height={20}><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
												</Tooltip>
											</TouchableOpacity> : null
										}</>


										<p className="m-0 print-text">Cancel</p>
									</div>)
									: null}
							</div>

						</div>
					</div>
					{/* <button className="" >Print Appointment</button> */}
					<div className="row mt-2">
						{/* <div className="col-6"> */}
						<div className="col-md-4">
							<p className="card-text">
								Appointment Status:
								<strong>{props.appointmentSatus}</strong>
							</p>
						</div>
						{/* </div> */}
					</div>
				</div>
			</div>
		
			<Modal
				visible={isLocationModalOpen}
				onOk={() => setIsLocationModalOpen(false)}
				onCancel={() => setIsLocationModalOpen(false)}
			>
				<p>{props.location}</p>
				<iframe src={MapLocation} style={{ width: "100%", height: "300px", border: "0px" }}></iframe>
			</Modal>
			<Modal
				// ref={componentRef}
				visible={printModalVissible}
				onOk={() =>
					setIsPrintActive(true)} //setPrintModalVissible(false)
				onCancel={() => setPrintModalVissible(false)}
				okText="Print"
			>
				<div id="printModal" ref={componentRef} >
					<PrintAppointmentHeader
						organizationData={props.organizationData}
						patientProfile={props.patientProfile}
						isPrintActive={isPrintActive}
					/>
					<h1 style={{}}>Appointment Slip</h1>
					<b>Patient Information</b>
					<Divider style={{ margin: 0, marginBottom: 10 }} />
					<table>
						<tbody>
							<tr>
								<td style={{ width: '200px' }}>Name</td>
								<td style={{ width: '200px' }}>{props?.patientDetails}</td>
							</tr>
							<tr>
								<td style={{ width: '200px' }}>NHI Number</td>
								<td style={{ width: '200px' }}>{props?.nhi}</td>
							</tr>
							<tr>
								<td style={{ width: '200px' }}>Date of Birth</td>
								<td style={{ width: '200px' }}>{props?.birthdate}</td>
							</tr>
							<tr>
								<td style={{ width: '200px' }}>Address</td>
								<td style={{ width: '200px' }}>
									<span className=""
										style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal', width: 200 }}>
										{props?.address}</span>
								</td>
							</tr>
						</tbody>
					</table>
					<div>
						<br />
						<b>Appointment Information</b>
						<Divider style={{ margin: 0, marginBottom: 10 }} />
						<table>
							
							<tbody>
								<tr>
									<td style={{ width: '200px' }}>Appointment Date/Time</td>
									<td style={{ width: '200px' }}>{props?.appointmentDate + ' ' + props?.secondItem}</td>
								</tr>
								<tr>
									<td style={{ width: '200px' }}>Provider Name</td>
									<td style={{ width: '200px' }}>{props?.providerName}</td>
								</tr>
								<tr>
									<td style={{ width: '200px' }}>Appointment Duration</td>
									<td style={{ width: '200px' }}>{props?.duration}</td>
								</tr>
								<tr>
									<td style={{ width: '200px' }}>Appointment Service </td>
									<td style={{ width: '200px' }}>N/A</td>
								</tr>
								<tr>
									<td style={{ width: '200px' }}>Practice Location </td>
									<td style={{ width: '200px' }}>{props.firstitem} </td>
								</tr>
								<tr>
									<td style={{ width: '200px' }}></td>
									<td style={{ width: '200px' }}>{props.location} </td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</Modal>
		</div>
	);
};

const styles = StyleSheet.create({
	listItems: {
		flex: 0.5,
		//    alignSelf:'baseline',
		//s  alignItems: 'space-between',
	},
	listRow: {
		flexDirection: "row",
		justifyContent: "flex-start",
		padding: 5,
		marginTop: 10,
		marginLeft: 5,
		marginBottom: 3,
		marginRight: 5,
		borderRadius: 10,
		borderWidth: 0,
		borderColor: "#D1D1D6",
		backgroundColor: "white",
	},
	doctorTilteFont: {
		color: "#212121",
		fontSize: 17,
	},
	patientFont: {
		marginTop: 3,
		fontSize: 16,
		color: "#455A64",
	},
	dateSectionMainStyle: {
		opacity: 1,
		position: "relative",
		backgroundColor: "transparent",
		width: 50,
		height: 75,
		marginTop: 10,
		marginBottom: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dateSectionBackground: {
		opacity: 1,
		backgroundColor: "#1c4490",
		borderRadius: 5,
		width: 55,
		height: 65,
	},
	dateSectionTextPlacement: {
		opacity: 1,
		position: "absolute",
		height: "100%",
		width: "100%",
		borderRadius: 10,
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	appointmentTypeStyle: {
		marginTop: 5,
		marginBottom: 5,
		marginRight: 35,
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},

	appointmentTypeBox: {
		backgroundColor: "#C5C5C5",
		borderRadius: 5,
		marginBottom: 5,
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
	appointmentTypeStyleText: {
		textAlign: "center",
		width: 80,
		marginBottom: 5,
		color: "#00A500",
		fontWeight: "400",
		fontSize: 16,
	},
	toptextDate: {
		fontSize: 14,
		fontWeight: "400",
		color: "#fff",
	},
});


export default AppointmentHandler;
