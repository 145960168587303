import { MESSAGE_LISTING, MESSAGE_LISTING_SUCCESS, MESSAGE_LISTING_LOADING, MESSAGE_LISTING_ERROR, MESSAGE_LISTING_FAILED } from '../../constants/Constant'
import getUserId from "../../core/GetToken";
import Settings from '../../config/Settings';




/******************************** LT *******************************************/

//return long terms diagnosis
export const listMessageAPI = (values) => {
    return (dispatch) => {
        dispatch(listingMessages())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = `?PatientID=${item.userid}&Type=${values.type}&MessagingType=${values.messagingType}&PageSize=100&PgeNumber=1`
            fetch(Settings.apiUrl + "Patient/Messaging" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                    dispatch(listingMessagesSuccess(json))
                })
                .catch(err => dispatch(listingMessagesFailed(err)))

        })
    }
}





//ALL DIAGNOSIS LOADS HERE
const listingMessages = () => {
    return {
        type: MESSAGE_LISTING
    }
}

const listingMessagesSuccess = (data) => {
    return {
        type: MESSAGE_LISTING_SUCCESS,
        data,
    }
}

const listingMessagesFailed = () => {
    return {
        type: MESSAGE_LISTING_FAILED
    }
}
