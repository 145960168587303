
import React from 'react';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import { ZoomMtg } from "@zoomus/websdk";

const ZoomMeeting = props => {



    //create clinet
    const client = ZoomMtgEmbedded.createClient();

    //end point for the API call
    var signatureEndpoint = 'https://us-central1-myindici-nz.cloudfunctions.net/indiciZoom'

    console.log("Asyncccc", JSON.parse(localStorage.getItem("@userToken")).email)

    

    var registrantToken = ''
    
    var sdkKey = "jEeuoUTffy33DEgUf1dKYQITIVnrOdNHArcz";
    var apiSecret = "F0IhZmqsLR2P97kH3666Kf6okGA4WgqxZM74";
    var meetingNumber = props.location.query.meetId;
    var leaveUrl = `${process.env.REACT_APP_ZOOMLEAVEURL}appointments`; // our redirect url
    var userName = JSON.parse(localStorage.getItem("@userToken")).parentname;
    var userEmail = "";
    var passWord = props.location.query.passCode;
    var role = 0
    var signature = "";

    //get the sigatures from NODEJS API call
    function getSignature(e) {
        e.preventDefault();

        fetch(signatureEndpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                meetingNumber: meetingNumber,
                role: role
            })
        }).then(res => res.json())
            .then(response => {
                signature = response.signature
                handleClick(response.signature)
            }).catch(error => {
                console.error(error)
            })
    }

    const handleClick = () => {
        showZoomDIv();
        ZoomMtg.setZoomJSLib("https://source.zoom.us/2.9.5/lib", "/av");
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();
        initiateMeeting();
    }

    const showZoomDIv = () => {
        document.getElementById("zmmtg-root").style.display = "block";
    };
    const initiateMeeting = () => {
        ZoomMtg.init({
            leaveUrl: leaveUrl,
            isSupportAV: true,
            success: (success) => {
                console.log(success);

                ZoomMtg.join({
                    signature: signature,
                    meetingNumber: meetingNumber,
                    userName: userName,
                    sdkKey: sdkKey,
                    userEmail: userEmail,
                    passWord: passWord,

                    success: (success) => {
                        console.log(success);
                    },
                    error: (error) => {
                        console.log(error);
                    },
                });
            },
            error: (error) => {
                console.log(error);
            },
        });
    };
   

    return (
       
                <div id="meetingSDKElement" style={{ width: "90%" }} className="page-content-wrapper-inner d-flex flex-column align-items-center">
                    <h2 className="meeting-header">Join Meeting</h2>
                    <div className="meeting-container d-none">
                       
                    </div>
                    <div className="action-continer d-flex justify-content-center">
                        <button
                            className="join-meeting-button btn btn-primary"
                            onClick={getSignature}
                        >
                            Start Meeting
                        </button>
                    </div>

                </div>
            
    );

}

export default ZoomMeeting