import { DatePicker, Input } from 'antd';
import indicilogo from "../assets/indicilogo.png";
import signupBanner from "../assets/signup-page.png";
import profileplace from "../assets/profileplace.png";
import { useState } from 'react';

const Signup = props => {
    const [familyName, setFamilyName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [nhiNum, setNhiNum] = useState("")
    const [profileImage, setProfileImage] = useState("")
    const [practiceName, setPracticeName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")

    const handleSubmit = () => {
        let raw = {
            "resourceType": "string",
            "entry": [
                {
                    "fullUrl": "",
                    "resource": {
                        "id": "string",
                        "resourceType": "string",
                        "identifier": [
                            {
                                "use": "string",
                                "type": {
                                    "coding": [
                                        {
                                            "system": "string",
                                            "code": "string",
                                            "display": "string"
                                        }
                                    ],
                                    "text": "string"
                                },
                                "system": "string",
                                "value": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.135Z",
                                    "end": "2022-11-17T07:32:29.135Z"
                                },
                                "assigner": {
                                    "alias": [
                                        "JTest"
                                    ],
                                    "contact": [
                                        {
                                            "purpose": {
                                                "coding": [
                                                    {
                                                        "system": "string",
                                                        "code": "string",
                                                        "display": "string"
                                                    }
                                                ],
                                                "text": "string"
                                            },
                                            "name": [
                                                {
                                                    "text": "Wwss  xxss",
                                                    "family": "xxss",
                                                    "given": [
                                                        "Wwssv"
                                                    ],
                                                    "prefix": [
                                                        "Dr"
                                                    ]
                                                }
                                            ],
                                            "telecom": {
                                                "system": "string",
                                                "value": "string",
                                                "use": "string",
                                                "rank": 0,
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.135Z",
                                                    "end": "2022-11-17T07:32:29.135Z"
                                                }
                                            },
                                            "address": {
                                                "use": "string",
                                                "type": "string",
                                                "text": "string",
                                                "line": [
                                                    "string"
                                                ],
                                                "city": "string",
                                                "district": "string",
                                                "state": "string",
                                                "postalCode": "string",
                                                "country": "string",
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.135Z",
                                                    "end": "2022-11-17T07:32:29.135Z"
                                                }
                                            }
                                        }
                                    ],
                                    "resourceType": "string",
                                    "identifier": {

                                    },
                                    "active": true,
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    },
                                    "name": "string",
                                    "partOf": {

                                    },
                                    "endpoint": {
                                        "identifier": [
                                            {

                                            }
                                        ],
                                        "connectionType": {
                                            "system": "string",
                                            "version": "string",
                                            "code": "string",
                                            "display": "string",
                                            "userSelected": true
                                        },
                                        "managingOrganization": {

                                        },
                                        "contact": [
                                            {
                                                "system": "string",
                                                "value": "string",
                                                "use": "string",
                                                "rank": 0,
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.135Z",
                                                    "end": "2022-11-17T07:32:29.135Z"
                                                }
                                            }
                                        ],
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        },
                                        "payloadType": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "string",
                                                        "code": "string",
                                                        "display": "string"
                                                    }
                                                ],
                                                "text": "string"
                                            }
                                        ],
                                        "payloadMimeType": [
                                            "string"
                                        ],
                                        "resourceType": "string",
                                        "status": "string",
                                        "name": "string",
                                        "address": "string",
                                        "header": [
                                            "string"
                                        ]
                                    }
                                }
                            }
                        ],
                        "status": "string",
                        "code": {
                            "coding": [
                                {
                                    "system": "string",
                                    "code": "string",
                                    "display": "string"
                                }
                            ],
                            "text": "string"
                        },
                        "active": true,
                        "name": [
                            {
                                "use": "string",
                                "text": firstName + " " + familyName,
                                "family": familyName,
                                "given": [
                                    firstName
                                ],
                                "prefix": "string",
                                "suffix": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.136Z",
                                    "end": "2022-11-17T07:32:29.136Z"
                                }
                            }
                        ],
                        "telecom": [

                            {
                                "system": "email",
                                "value": emailAddress,
                                "use": "home"
                            }
                        ],
                        "gender": "string",
                        "birthDate": dateOfBirth,
                        "effectiveDateTime": "2022-11-17T07:32:29.136Z",
                        "issued": "2022-11-17T07:32:29.136Z",
                        "authoredOn": "2022-11-17T07:32:29.136Z",
                        "receivedTime": "2022-11-17T07:32:29.136Z",
                        "basedOn": [
                            {
                                "reference": "string"
                            }
                        ],
                        "specimen": [
                            {
                                "reference": "string"
                            }
                        ],
                        "subject": {
                            "reference": "string"
                        },
                        "deceasedBoolean": true,
                        "deceasedDateTime": "2022-11-17T07:32:29.136Z",
                        "address": [
                            {
                                "use": "string",
                                "type": "string",
                                "text": "string",
                                "line": [
                                    "string"
                                ],
                                "city": "string",
                                "district": "string",
                                "state": "string",
                                "postalCode": "string",
                                "country": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.136Z",
                                    "end": "2022-11-17T07:32:29.136Z"
                                }
                            }
                        ],
                        "maritalStatus": {
                            "coding": [
                                {
                                    "system": "string",
                                    "code": "string",
                                    "display": "string"
                                }
                            ],
                            "text": "string"
                        },
                        "multipleBirthBoolean": true,
                        "multipleBirthInteger": 0,
                        "photo": [
                            {
                                "contentType": "string",
                                "language": "string",
                                "data": "string",
                                "url": "string",
                                "size": 0,
                                "hash": "string",
                                "title": "string",
                                "creation": "2022-11-17T07:32:29.136Z"
                            }
                        ],
                        "contact": [
                            {
                                "relationship": [
                                    {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    }
                                ],
                                "name": {
                                    "use": "string",
                                    "text": "string",
                                    "family": "string",
                                    "given": [
                                        "string"
                                    ],
                                    "prefix": "string",
                                    "suffix": "string",
                                    "period": {
                                        "start": "2022-11-17T07:32:29.136Z",
                                        "end": "2022-11-17T07:32:29.136Z"
                                    }
                                },
                                "telecom": [
                                    {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        }
                                    }
                                ],
                                "address": {
                                    "use": "string",
                                    "type": "string",
                                    "text": "string",
                                    "line": [
                                        "string"
                                    ],
                                    "city": "string",
                                    "district": "string",
                                    "state": "string",
                                    "postalCode": "string",
                                    "country": "string",
                                    "period": {
                                        "start": "2022-11-17T07:32:29.136Z",
                                        "end": "2022-11-17T07:32:29.136Z"
                                    }
                                },
                                "organization": {
                                    "alias": [
                                        "string"
                                    ],
                                    "contact": [
                                        {
                                            "purpose": {
                                                "coding": [
                                                    {
                                                        "system": "string",
                                                        "code": "string",
                                                        "display": "string"
                                                    }
                                                ],
                                                "text": "string"
                                            },
                                            "name": {
                                                "use": "string",
                                                "text": "string",
                                                "family": "string",
                                                "given": [
                                                    "string"
                                                ],
                                                "prefix": "string",
                                                "suffix": "string",
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                }
                                            },
                                            "telecom": {
                                                "system": "string",
                                                "value": "string",
                                                "use": "string",
                                                "rank": 0,
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                }
                                            },
                                            "address": {
                                                "use": "string",
                                                "type": "string",
                                                "text": "string",
                                                "line": [
                                                    "string"
                                                ],
                                                "city": "string",
                                                "district": "string",
                                                "state": "string",
                                                "postalCode": "string",
                                                "country": "string",
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                }
                                            }
                                        }
                                    ],
                                    "resourceType": "string",
                                    "identifier": {
                                        "use": "string",
                                        "type": {
                                            "coding": [
                                                {
                                                    "system": "string",
                                                    "code": "string",
                                                    "display": "string"
                                                }
                                            ],
                                            "text": "string"
                                        },
                                        "system": "string",
                                        "value": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        },
                                        "assigner": {

                                        }
                                    },
                                    "active": true,
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    },
                                    "name": "string",
                                    "partOf": {

                                    },
                                    "endpoint": {
                                        "identifier": [
                                            {
                                                "use": "string",
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "string",
                                                            "code": "string",
                                                            "display": "string"
                                                        }
                                                    ],
                                                    "text": "string"
                                                },
                                                "system": "string",
                                                "value": "string",
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                },
                                                "assigner": {

                                                }
                                            }
                                        ],
                                        "connectionType": {
                                            "system": "string",
                                            "version": "string",
                                            "code": "string",
                                            "display": "string",
                                            "userSelected": true
                                        },
                                        "managingOrganization": {

                                        },
                                        "contact": [
                                            {
                                                "system": "string",
                                                "value": "string",
                                                "use": "string",
                                                "rank": 0,
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                }
                                            }
                                        ],
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        },
                                        "payloadType": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "string",
                                                        "code": "string",
                                                        "display": "string"
                                                    }
                                                ],
                                                "text": "string"
                                            }
                                        ],
                                        "payloadMimeType": [
                                            "string"
                                        ],
                                        "resourceType": "string",
                                        "status": "string",
                                        "name": "string",
                                        "address": "string",
                                        "header": [
                                            "string"
                                        ]
                                    }
                                },
                                "period": {
                                    "start": "2022-11-17T07:32:29.136Z",
                                    "end": "2022-11-17T07:32:29.136Z"
                                },
                                "gender": "string"
                            }
                        ],
                        "communication": [
                            {
                                "language": {
                                    "coding": [
                                        {
                                            "system": "string",
                                            "code": "string",
                                            "display": "string"
                                        }
                                    ],
                                    "text": "string"
                                },
                                "preferred": true
                            }
                        ],
                        "generalPractitioner": {
                            "alias": [
                                "string"
                            ],
                            "contact": [
                                {
                                    "purpose": {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    },
                                    "name": {
                                        "use": "string",
                                        "text": "string",
                                        "family": "string",
                                        "given": [
                                            "string"
                                        ],
                                        "prefix": "string",
                                        "suffix": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        }
                                    },
                                    "telecom": {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        }
                                    },
                                    "address": {
                                        "use": "string",
                                        "type": "string",
                                        "text": "string",
                                        "line": [
                                            "string"
                                        ],
                                        "city": "string",
                                        "district": "string",
                                        "state": "string",
                                        "postalCode": "string",
                                        "country": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        }
                                    }
                                }
                            ],
                            "resourceType": "string",
                            "identifier": {
                                "use": "string",
                                "type": {
                                    "coding": [
                                        {
                                            "system": "string",
                                            "code": "string",
                                            "display": "string"
                                        }
                                    ],
                                    "text": "string"
                                },
                                "system": "string",
                                "value": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.136Z",
                                    "end": "2022-11-17T07:32:29.136Z"
                                },
                                "assigner": {

                                }
                            },
                            "active": true,
                            "type": {
                                "coding": [
                                    {
                                        "system": "string",
                                        "code": "string",
                                        "display": "string"
                                    }
                                ],
                                "text": "string"
                            },
                            "name": "string",
                            "partOf": {

                            },
                            "endpoint": {
                                "identifier": [
                                    {
                                        "use": "string",
                                        "type": {
                                            "coding": [
                                                {
                                                    "system": "string",
                                                    "code": "string",
                                                    "display": "string"
                                                }
                                            ],
                                            "text": "string"
                                        },
                                        "system": "string",
                                        "value": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        },
                                        "assigner": {

                                        }
                                    }
                                ],
                                "connectionType": {
                                    "system": "string",
                                    "version": "string",
                                    "code": "string",
                                    "display": "string",
                                    "userSelected": true
                                },
                                "managingOrganization": {

                                },
                                "contact": [
                                    {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    }
                                ],
                                "period": {
                                    "start": "2022-11-17T07:32:29.137Z",
                                    "end": "2022-11-17T07:32:29.137Z"
                                },
                                "payloadType": [
                                    {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    }
                                ],
                                "payloadMimeType": [
                                    "string"
                                ],
                                "resourceType": "string",
                                "status": "string",
                                "name": "string",
                                "address": "string",
                                "header": [
                                    "string"
                                ]
                            }
                        },
                        "managingOrganization": {
                            "alias": [
                                "string"
                            ],
                            "contact": [
                                {
                                    "purpose": {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    },
                                    "name": {
                                        "use": "string",
                                        "text": "string",
                                        "family": "string",
                                        "given": [
                                            "string"
                                        ],
                                        "prefix": "string",
                                        "suffix": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    },
                                    "telecom": {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    },
                                    "address": {
                                        "use": "string",
                                        "type": "string",
                                        "text": "string",
                                        "line": [
                                            "string"
                                        ],
                                        "city": "string",
                                        "district": "string",
                                        "state": "string",
                                        "postalCode": "string",
                                        "country": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    }
                                }
                            ],
                            "resourceType": "string",
                            "identifier": {
                                "use": "string",
                                "type": {
                                    "coding": [
                                        {
                                            "system": "string",
                                            "code": "string",
                                            "display": "string"
                                        }
                                    ],
                                    "text": "string"
                                },
                                "system": "string",
                                "value": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.137Z",
                                    "end": "2022-11-17T07:32:29.137Z"
                                },
                                "assigner": {

                                }
                            },
                            "active": true,
                            "type": {
                                "coding": [
                                    {
                                        "system": "string",
                                        "code": "string",
                                        "display": "string"
                                    }
                                ],
                                "text": "string"
                            },
                            "name": "string",
                            "partOf": {

                            },
                            "endpoint": {
                                "identifier": [
                                    {
                                        "use": "string",
                                        "type": {
                                            "coding": [
                                                {
                                                    "system": "string",
                                                    "code": "string",
                                                    "display": "string"
                                                }
                                            ],
                                            "text": "string"
                                        },
                                        "system": "string",
                                        "value": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        },
                                        "assigner": {

                                        }
                                    }
                                ],
                                "connectionType": {
                                    "system": "string",
                                    "version": "string",
                                    "code": "string",
                                    "display": "string",
                                    "userSelected": true
                                },
                                "managingOrganization": {

                                },
                                "contact": [
                                    {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    }
                                ],
                                "period": {
                                    "start": "2022-11-17T07:32:29.137Z",
                                    "end": "2022-11-17T07:32:29.137Z"
                                },
                                "payloadType": [
                                    {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    }
                                ],
                                "payloadMimeType": [
                                    "string"
                                ],
                                "resourceType": "string",
                                "status": "string",
                                "name": "string",
                                "address": "string",
                                "header": [
                                    "string"
                                ]
                            }
                        },
                        "link": [
                            {
                                "other": {

                                },
                                "type": "string"
                            }
                        ],
                        "collection": {
                            "collectedDateTime": "2022-11-17T07:32:29.137Z"
                        },
                        "presentedForm": [
                            {
                                "contentType": "string",
                                "data": "string",
                                "FilePath": "string"
                            }
                        ],
                        "result": [
                            {
                                "ObservationList": [
                                    {

                                    }
                                ],
                                "reference": "string"
                            }
                        ],
                        "extension": [
                            {
                                "url": "NHI",
                                "valueString": nhiNum
                            },
                            {
                                "url": "MiddleName",
                                "valueString": "TestMiddle"
                            }
                        ],
                        "valueString": "string"
                    }
                }
            ],
            "Token": "string"
        }
    }
    return (
        <div className="signup-page">
            <img className="signup-logo" src={indicilogo} alt="Logo" />
            <div className="sign-content row row-cols-2">
                <div className="sign-left col-4 d-none d-md-flex">
                    <img src={signupBanner} alt="" />
                </div>
                <div className="sign-right col-12 col-md-8 p-4">
                    <h3 className="header text-primary">Signup</h3>
                    <div className="divider"></div>
                    <h5 className="my-3 fs-2 fw-bold">Please Enter Your Details Here</h5>
                    <div className="form-content row row-cols-2 gap-4">
                        <div className="form-content-right col">
                            <Input value={familyName} onChange={(e) => setFamilyName(e.target.value)} className="form-item my-2 py-2" placeholder="Family Name" />
                            <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="form-item my-2 py-2" placeholder="First Name" />
                            <Input value={middleName} onChange={(e) => setMiddleName(e.target.value)} className="form-item my-2 py-2" placeholder="Middle Name" />
                            <DatePicker value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} className="form-item my-2 py-2 bg-white" placeholder="Date of Birth" />
                            <Input value={nhiNum} onChange={(e) => setNhiNum(e.target.value)} className="form-item my-2 py-2" placeholder="NHI Number" />
                        </div>
                        <div className="form-content-left col">
                            <div className="profile-img form-item my-2 d-flex align-items-center justify-content-between">
                                <div className="selected-profile-img m-0">
                                    <img src={profileplace} alt="" />
                                </div>
                                <div className="img-picker">
                                    <input type="file" name="" id="" />
                                </div>
                            </div>
                            <Input value={practiceName} onChange={(e) => setPracticeName(e.target.value)} className="form-item my-2 py-2" placeholder="Practice Name" />
                            <Input value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} className="form-item my-2 py-2" placeholder="Email Address" />
                        </div>

                    </div>
                    <div className="sign-btn w-100 d-flex justify-content-center mt-5">
                        <button onClick={handleSubmit} type="submit" className="btn btn-primary btn-block w-25"> Signup </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;

