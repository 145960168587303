
import { GET_ROLES, GET_ROLES_SUCCESS, GET_ROLES_FAILED } from "../constants/Constant";

import getUserId from "../core/GetToken";
import getAllRoles from "../services/RolesService";

export const fetchRolesFromApi = (PracticeLocation = null) => {
    return async (dispatch) => {
        // const networkCheck = await isNetworkAvilable();
        // if (networkCheck) {
        dispatch(getRoles())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            let queryString = '?PatientID=' + item.userid + '&TypeID=2';

            if (PracticeLocation) {
                queryString = queryString + '&PracticeLocation=' + PracticeLocation;

            }
            //console.log(queryString)
            getAllRoles(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    //console.log(json[0]);
                    // alert(JSON.stringify(json[0]));
                    json[0].hasOwnProperty('entry') ? dispatch(getRolesSuccess(json[0].entry)) : dispatch(getRolesFailed())
                })
                .catch((err) => {
                    //console.log(err)
                    dispatch(getRolesFailed(err))
                })
        })
        // } else {
        //     showAlert(MessageConstant.networkMessage)
        //     dispatch(getRolesFailed())

        // }

    }
}


const getRoles = () => {
    return {
        type: GET_ROLES
    }
}

//load data
const getRolesSuccess = (data) => {
    return {
        type: GET_ROLES_SUCCESS,
        data,
    }
}


//data failure
const getRolesFailed = () => {
    return {
        type: GET_ROLES_FAILED,
    }
}




