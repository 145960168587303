import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { ActivityIndicator, View, FlatList, Text } from "react-native";
import AppointmentHandler from '../../components/AppointmentHandler';
import { fetchAppointmentsFromApi } from "../../../shared/actions/CompleteAppontmentActions";
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime } from '../../../shared/core/datehelper';

const CompleteAppointmentView = props => {

    const { CompletedAppointment, isGetting } = props.CompletedAppointment;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number

    useEffect(() => {
        //alert('Called Future')
        props.getCompAppoint(pageSize, pageNumber);
    }, [])
    return (
        <div>
            {
                isGetting && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (CompletedAppointment) !== 'undefined' && CompletedAppointment.length ? (
                    <FlatList
                        style={{
                            flexDirection: 'column', paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        //contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-evenly' }}
                        data={CompletedAppointment}
                        renderItem={({ item }) => (
                            <AppointmentHandler
                                styleName={'card-header text-white bg-success'}
                                providerName={item.resource.extension[2].valueString ? item.resource.extension[2].valueString : 'N/A'}
                             
                                appointmentDate={GetAppointmentDate(item.resource.period.start) + ' ' + GetMonthName(item.resource.period.start) + ' ' + GetYear(item.resource.period.start)}
                                praticeAddress={item.resource.extension[3].hasOwnProperty('valueString') ? item.resource.extension[3].valueString : '(N/A)'}
                                firstitem={item.resource.extension[5].valueString ? item.resource.extension[5].valueString : 'N/A'}
                                secondItem={GetDayName(item.resource.period.start) + ', ' + FormateUTCTime(item.resource.period.start)}
                                patientDetails={item.resource.extension[1].valueString} //+ ', ' + item.resource.extension[0].valueString}}
                                location={item.resource.extension[4].hasOwnProperty('valueString') ? item.resource.extension[4].valueString : '(N/A)'}
                                reasonForCancel={null}
                            />
                        )}
                        horizontal={false}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={30}

                    />
                ) : !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                        <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                </View>
            }

        </div>

    );
}



function mapStateToProps(state) {
    return {
        CompletedAppointment: state.CompletedAppointment
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCompAppoint: (pageSize, pageNumber) => dispatch(fetchAppointmentsFromApi(pageSize, pageNumber)),

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompleteAppointmentView)

//export default FutureAppointmentView
