import { LISTING_MESSAGE_ROLES, LISTING_MESSAGE_ROLES_SUCCESS, LISTING_MESSAGE_ROLES_LOADING, LISTING_MESSAGE_ROLES_ERROR, LISTING_MESSAGE_ROLES_FAILED } from '../../constants/Constant'
import getUserId from "../../core/GetToken";
import Settings from '../../config/Settings';




/******************************** LT *******************************************/

//return long terms diagnosis
export const listMessageRolesAPI = (values) => {
    return (dispatch) => {
        dispatch(listingMessageRoles())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = `?PatientID=${item.userid}&TypeID=1`
            fetch(Settings.apiUrl + "Patient/LoadRoles" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                    dispatch(listingMessageRolesSuccess(json))
                })
                .catch(err => dispatch(listingMessageRolesFailed(err)))

        })
    }
}





const listingMessageRoles = () => {
    return {
        type: LISTING_MESSAGE_ROLES
    }
}

const listingMessageRolesSuccess = (data) => {
    return {
        type: LISTING_MESSAGE_ROLES_SUCCESS,
        data,
    }
}

const listingMessageRolesFailed = () => {
    return {
        type: LISTING_MESSAGE_ROLES_FAILED
    }
}
