import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { View, Text, TouchableOpacity, Button, StyleSheet, StatusBar, Dimensions, ActivityIndicator, FlatList } from 'react-native-web';
import { connect } from "react-redux";
import { message, Modal, Radio } from 'antd';
import { fetchPharmcyFromApi } from '../../../shared/actions/GetPharmacyActions'
import { fetchAllMedsFromApi } from "../../../shared/actions/AllMedicationActions";
import LongMedicationHandler from "../../components/LongMedicationHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchRecentMedsFromApi } from '../../../shared/actions/RecentMedAction';
import getUserId from '../../../shared/core/GetToken';
import Settings from '../../../shared/config/Settings';
import { Select, notification, Checkbox } from 'antd';

import GetAppSettings from '../../../shared/core/GetAppSettings';
import PrintOrganizationHeader from '../../components/PrintOrganizationHeader';
import { useReactToPrint } from 'react-to-print';
import Tooltip from 'antd/es/tooltip';
import { fetchApplicationSettingFromApi } from '../../../shared/actions/GetApplicationSettingAction';

let array = [];
const { Option, OptGroup } = Select;
const LongTermMed = props => {

    const { AllMedeications, isGetting } = props.AllMedeications;
    const { isAvailable } = props.ApplicationSettings;
    const { Pharmacy } = props.Pharmacy
    const { Practise } = props.Practise;
    const { Profile } = props.Profile;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    const [list, setList] = useState([])
    const [repeadtedmodalVisible, setrepeadtedmodalVisible] = useState(false);
    const [portalSetting, setPortalSetting] = useState(null);
    /////////////////////
    const [isSelected, setIsSelected] = useState(false);
    const [Pharmacyname, setPharmacyName] = React.useState({ id: '', name: 'Select Pharmacy' })
    const [isPickupSelected, setIsPickupSelected] = useState(false);
    const [isPharmacySelected, setIsPharmacySelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [ordertype, setOrderType] = useState('Pharmacy');
    const [ready, setReady] = useState(false);


    const [isSelectedBtnActive, setIsSelectedBtnActive] = useState(false)
    const [isSelectAllMedEnable, setIsSelectAllMedEnable] = useState(false)

    const [printtMedsModal, setPrintMedsModal] = useState(false)
    const [isPrintActive, setIsPrintActive] = useState(false)
    const [providerLeaveChoice, setProviderLeaveChoice] = useState("other")
    const [prefferedPharmacyID, setPrefferedPharmacyID] = useState("")
    const [repeatMedNote, setRepeatMedNote] = useState("")
    const [isRepeatFavChecked, setIsRepeatFavChecked] = useState(false)

    const openNotificationWithIcon = (type, value) => {
        notification[type]({
            message: 'Repeat Medication Request',
            description:
                value,
        });
    };
    // useEffect(() => {
    //     GetAppSettings().then((info) => {
    //         const items = JSON.parse(info)
    //         let ob = {
    //             id: items.PharmacyId,
    //             name: items.PrefPharmacy,
    //         }
    //         // setPharmacyName({ id: items.PharmacyId, name: items.PrefPharmacy });
    //         // alert(JSON.stringify(ob))
    //         //   selectedItems.push(items.PharmacyId)
    //     })
    // }, [])
    ////////////////////////
    function capitalize(word) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }

    useEffect(() => {
        // alert('Called Future')
        props.getMed(pageSize, pageNumber);
        props.getPharmacy()
        array = []

    }, [])
    const hanldeSlectedList = (value) => {
        console.log("vallllll", value)
        /*
            ye code basically selected item ki list ko update kr rha yahan pe start pe pehli condioton chlay gi 
            us k bd pehly map chaly ga test ho ga k ye item pehly add tu ni agr hoga tu abc ki value true kr us item ko remove kr dain gy
            other wise map k end pe if ki conditionme ja k item list mei add ho jy ga
        */

        let abc = false;
        if (array.length == 0) {
            array = [...array, value]
        } else {
            array.map((item, index) => {
                if (item == value) {
                    array.splice(index, 1)
                    abc = true
                }
            })
            if (!abc) {
                array = [...array, value]
            }
        }
        ////console.log({ array })
        setIsReady(!isReady)
    }
    const handleSelectAllMed = () => {
        if (isSelectedBtnActive) {
            array = [...AllMedeications]
            setIsSelectAllMedEnable(true)
        } else {
            array = []
            setIsSelectAllMedEnable(false)
        }
    }
    useEffect(() => {
        handleSelectAllMed()
    }, [isSelectedBtnActive])
   
    const repeadtedmedicationHandler = () => {

       

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            let obj = [];
            let medcnlist = ''
            array.map((item, index) => {
                medcnlist = medcnlist + item.resource.medicationCodeableConcept.coding[0].display + ','
                let ob = {
                    "MedicationRepeatRequestID": 0,
                    "MessageID": 0,
                    "MedicationID": item.resource.id,
                    "SCTID": null,
                    "Type": null,
                    "PracticeName": null,
                    "FullySpecifiedName": item.resource.medicationCodeableConcept.coding[0].display,
                    "MedicineID": 0,
                    "Form": null,
                    "MedicineName": null,
                    "Take": null,
                    "Duration": null,
                    "StartDate": null,
                    "ProviderID": 0,
                    "Directions": item.resource.dosageInstruction[0].text,
                    "IsLongTerm": item.resource.extension[0].valueBoolean,
                    "IsActive": false,
                    "IsDeleted": false,
                    "InsertedBy": 2232675,
                    "UpdatedBy": 0,
                    "InsertedAt": null,
                    "UpdatedAt": null,
                    "TotalRecords": 0,
                    "PracticeID": 0,
                    "PatientID": 0,
                    "Comments": repeatMedNote,
                    "Quantity": null,
                    "ProviderName": null,
                    "PharmacyId": Pharmacyname.id,
                    "PickupTypeId": 2,
                    "OtherPharmacy": null,
                    "PrefPharmacyId": Pharmacyname.id,
                    "IsUrgent": isSelected,
                    "PharmacyLocationId": 0
                };
                obj.push(ob)
            })

            let raw = {
                "extension": [
                    {
                        "url": "MessageBody",
                        "valueString": isPharmacySelected ? "Notes:Type: Pharmacy Pharmacy on :" + Pharmacyname.name + "Requested Medicine:" + medcnlist : "Notes:Type: Pickup Pharmacy on :" + Pharmacyname.name + "Requested Medicine:" + medcnlist
                        // "valueString": "Notes:Type: Pharmacy \n Pharmacy on :AKL Pharmacys \n Requested Medicine:Depo-Testosterone (testosterone cipionate 1 g/10 mL) injection: solution, vial"
                    },
                    {
                        "url": "MessageType",
                        "valueString": "Send Mail"
                    },
                    {
                        "url": "MedicationList",
                        "valueString": JSON.stringify(obj),

                    }
                ],
                "note": [
                    {
                        "text": "Repeat Rx Request"
                    }
                ],
                "recipient": [
                    {
                        "display": Practise && Practise.length ? Practise[0].resource.extension[2].valueString : "N/A",
                        "reference": Practise && Practise.length ? "Practitioner/" + Practise[0].resource.extension[3].valueString : "N/A",//"/284389"
                    }
                ],
                "resourceType": "Communication",
                "sender": {
                    "display": item.username,
                    "reference": "Patient/" + item.userid
                },
                "status": "completed"
            }

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(raw),
                redirect: 'follow'
            };

            //console.log({ raw })
            fetch(Settings.apiUrl + "Patient/Message?RoleID=1&CallType=3", requestOptions)
                .then(response => response.json())
                .then(result => {
                   
                    
                    // Alert.alert(
                    //     "",
                    //     result,
                    //     [

                    //         {
                    //             text: "OK", onPress: () => {
                    //                 setLoading(false)
                    //                 props.navigation.navigate('Medications')
                    //                 ////console.log("OK Pressed")
                    //             }
                    //         }
                    //     ]
                    // );
                    array = [];
                    // window.location.reload()
                    //console.log(result)
                    setLoading(false)
                    openNotificationWithIcon('success')
                    handleRepeatMedCancel()

                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);

                })
                .catch(error => {
                    setLoading(false)
                    ////console.log('error', error)
                });
        })

    }

    const [response, setResponse] = useState(false);

    const [newModalVissible, setNewModalVissible] = useState(false)
    const [providerOnLeaveModalVissible, setProviderOnLeaveModalVissible] = useState(false)
    const [mesagestate, setMessageState] = useState('')
    const [providerOnLeaveState, setProviderOnLeaveState] = useState([])
    const RepeatedMedicationAdding = (ignoreLeave = false) => {
        let idString = ''
       
        getUserId().then(userInfo => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setLoading(true)
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            // //console.log(props.route.params.med)
            array.map((item, i) => {
                idString = idString + item.resource?.id + ","
            })
            var raw;
            {
                isPickupSelected ?
                    raw = JSON.stringify({
                        "PatientID": item.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 1,
                        "PharmacyID": 2,
                        "PharmacyName": "",
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": false,
                        "Notes": repeatMedNote,
                        "PharmacyLocationId": 0,
                    })
                    :
                    raw = JSON.stringify({
                        "PatientID": item.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 2,
                        "PharmacyID": Pharmacyname.id,
                        "PharmacyName": Pharmacyname.name,
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": isRepeatFavChecked,
                        "Notes": repeatMedNote,
                        "PharmacyLocationId": 0,
                        "IgnoreLeave": ignoreLeave

                    })

            }
            // = JSON.stringify({
            //     "PatientID": item.userid,
            //     "MedicationID": idString,
            //     "PickUpTypeID": 2,
            //     "PharmacyID": Pharmacyname.id,
            //     "PharmacyName": Pharmacyname.name,
            //     "IsUrgent": isSelected ? true : false,
            //     "IsPreferredPharmacy": true,
            //     "Notes": notes,
            //     "PharmacyLocationId": 0
            // });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            //console.log({ raw })
            let statusMed
            fetch(Settings.apiUrl + "Patient/SendRepeatMedicationRequest", requestOptions)
                .then(response => {
                    // //console.log("status resulttt", response.status)
                    statusMed = response.status
                    return response.json()
            })
            .then((result ) => {

                if(statusMed == 302){
                    //console.log("inside 302", result)
                    setProviderOnLeaveState(JSON.parse(result))
                    setProviderOnLeaveModalVissible(true)
                }else{
                    
                    setNewModalVissible(true)
                    setMessageState(result)
                }
                  
                    // openNotificationWithIcon('success', result)
                    // setResponse(true)

                    ///hihih
                    // props.getMed(pageSize, pageNumber);
                    // setLoading(false)
                    // setTimeout(() => {
                    //     window.location.reload(false);
                    // }, 1500);
                    ///end
                    // window.location.reload()
                    //   openNotificationWithIcon('success', result)
                    // array = [];
                    //   setReady(!ready)
                    // Alert.alert(
                    //     "",
                    //     result,
                    //     [
                    //         {
                    //             text: "OK", onPress: () => {
                    //                 setLoading(false)
                    //                 props.navigation.navigate('Medications')
                    //                 //console.log("OK Pressed")
                    //             }
                    //         }
                    //     ]
                    // );
                    //  alert(result)

                })
                .catch(error => {
                    setLoading(false)
                    //  //console.log('error', error)
                }).finally(()=>{
                    setLoading(false);
                    handleRepeatMedCancel()
                });
        })



    }

    function handleChange(value, obj) {
        setPharmacyName({ id: value, name: obj.children });
    }
    function handleChangetype(value) {
        console.log(`selected ${value}`);
        // alert(value)
        if(value == "Pick up"){
            setIsPickupSelected(true)
        }else{
            
            setIsPickupSelected(false)
        }
        setOrderType(value)
    }
    const selectAllMeds = () => {
        setIsSelectedBtnActive(!isSelectedBtnActive)

    }
    const handleRepeatMedCancel = ()=>{
        setrepeadtedmodalVisible(false)
        setRepeatMedNote("")
        setIsSelected(false)
        setIsRepeatFavChecked(false)
        setPharmacyName({ id: (portalSetting.PharmacyId && portalSetting.PharmacyId != "0" ? portalSetting.PharmacyId : ""), name: (portalSetting.PrefPharmacy && portalSetting.PrefPharmacy != "N/A" ? portalSetting.PrefPharmacy : "Select Pharmacy") })
    }
    //console.log("pharmacyName", Pharmacyname)
    // useEffect(() => {

    // },[ready])
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, message) => {
        api.info({
            message: `${message}`,

            placement,
            style: {
                color: "#fff",
                fontWeight: "bold",

            },
        });
    };
    const hanlePrintMeds = () => {
        if (array.length === 0) {
            openNotification("topRight", "Select at least one Medicine")
        }
        else {
            setPrintMedsModal(true)
        }
    }
    const pageStyleCss = `@page {
        size: A4;
      }
      *{
          font-family: sans-serif;
        }
        .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        h3,h4,h5,p{
            margin:0px;
        }
        table {
  border-collapse: collapse;
  font-size:16px;
}
        th, td {
	border: 1px solid black;
	padding: 8px;
}
.print-med-table{

}
      `;
    const cssTable = ' table td { white-space: inherit !important; }'
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: false,
        pageStyle: pageStyleCss
    })

    useEffect(() => {
        if (isPrintActive) {
            handlePrint()
            setIsPrintActive(false)
        }
    }, [isPrintActive])

    useEffect(()=>{
        GetAppSettings().then((info) => {
            const set = JSON.parse(info);
            setPortalSetting(set)
            if (!set.IsRxFax) {
                setOrderType("Pick Up")
                // setIsPickupSelected(true)
                setIsPharmacySelected(false)
            }

            setPrefferedPharmacyID(set.PharmacyId && set.PharmacyId != "0" ? set.PharmacyId : "")
            // //console.log("set.PharmacyId",set.PharmacyId)
            // //console.log('set.set.PrefPharmacy',set.PrefPharmacy)
            //setPharmacyName({id:set.PharmacyId, name:  set.PrefPharmacy.toString()})

            setPharmacyName({ id: (set.PharmacyId && set.PharmacyId != "0" ? set.PharmacyId : ""), name: (set.PrefPharmacy ? set.PrefPharmacy : "") })

        })
    }, [isAvailable])
    return (
        <div className="position-relative">
            {
                isGetting && <View style={{ textAlign: 'center', justifyContent: 'center', marginBottom: 1, marginTop: 2 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }


            {
                typeof (AllMedeications) !== 'undefined' && AllMedeications.length ? (
                    <>
                    <div className='d-flex justify-content-between align-items-center pl-2 flex-wrap'>
                        <div className='d-flex align-items-center'>
                        <Tooltip title="Select All Medications">

                        <Checkbox onClick={selectAllMeds} style={{ padding: "8px", background: array && array.length >= 1 ? '#195faa' : '#Dedede', borderRadius: "5px 0px 0px 5px", borderRight: "1px solid " + (array && array.length >= 1 ? 'cadetblue' : 'lightgrey')}} checked={isSelectedBtnActive}></Checkbox>
                        </Tooltip>
                        <TouchableOpacity
                            disabled={array.length === 0}
                            style={{ height: 38, borderRadius: "0px 5px 5px 0px", width: "100%", justifyContent: 'center', alignItems: 'center', backgroundColor: array && array.length >= 1 ? '#195faa' : '#Dedede' }}
                            onPress={() => { setrepeadtedmodalVisible(true) }}>
                            <Text style={{ color: 'white', fontSize: 15 }}>Repeat Selected Medication</Text>
                        </TouchableOpacity>
                        </div>
                        {/* <TouchableOpacity
                            disabled={array.length === 0}
                            style={{ height: 40, width: 250, marginTop: 20, marginLeft: 20, marginBottom: 10, borderRadius: 5, justifyContent: 'center', alignItems: 'center', backgroundColor: array && array.length >= 1 ? '#195faa' : '#Dedede' }}
                            onPress={() => { hanlePrintMeds() }}> */}

                            <div className='d-flex'>

                        {contextHolder}
                        {/* <div className="d-flex justify-content-end">
                            <Tooltip title="Print Selected Medicines">
                                <svg onClick={() => { hanlePrintMeds() }} className="mx-3 my-2 print-svg"
                                    width="30" height="30" viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet">

                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M2330 5110 c-494 -48 -950 -230 -1350 -538 -195 -150 -448 -432 -594
-662 -63 -99 -186 -351 -230 -471 -49 -134 -102 -340 -128 -499 -31 -195 -31
-565 0 -760 45 -276 116 -498 237 -745 132 -269 269 -460 489 -681 221 -220
412 -357 681 -489 247 -121 469 -192 745 -237 195 -31 565 -31 760 0 276 45
498 116 745 237 269 132 460 269 681 489 220 221 357 412 489 681 88 179 132
296 180 476 63 240 78 371 78 649 0 278 -15 409 -78 649 -48 180 -92 297 -180
476 -132 269 -269 460 -489 681 -221 220 -412 357 -681 489 -246 121 -474 193
-740 235 -147 23 -475 34 -615 20z m1253 -1080 c15 -12 31 -38 37 -58 5 -21
10 -150 10 -299 l0 -261 158 -4 c153 -3 159 -4 224 -35 77 -36 156 -105 193
-169 55 -94 58 -112 62 -484 3 -202 1 -375 -5 -417 -28 -203 -173 -351 -369
-378 l-53 -7 0 214 0 214 32 9 c90 28 94 164 6 194 -43 15 -2593 15 -2636 0
-88 -30 -84 -166 6 -194 l32 -9 0 -214 0 -214 -52 7 c-197 27 -342 175 -370
378 -6 42 -8 215 -5 417 4 372 7 390 62 484 37 64 116 133 193 169 65 31 71
32 225 35 l157 4 0 261 c0 149 5 278 10 299 6 20 22 46 37 58 25 20 33 20
1023 20 990 0 998 0 1023 -20z m45 -2250 c2 -311 0 -585 -3 -609 -5 -30 -16
-54 -35 -73 l-28 -28 -1000 0 c-992 0 -1000 0 -1025 20 -15 12 -31 38 -37 58
-11 41 -15 1184 -3 1195 3 4 484 6 1067 5 l1061 -3 3 -565z"/>
                                        <path d="M3694 3190 c-35 -14 -64 -59 -64 -101 0 -29 7 -45 31 -68 81 -82 218
15 165 118 -26 49 -82 71 -132 51z"/>
                                        <path d="M2005 2023 c-88 -23 -111 -140 -37 -190 25 -17 61 -18 592 -18 531 0
567 1 592 18 58 39 58 135 0 174 -25 17 -62 18 -577 20 -302 0 -559 -1 -570
-4z"/>
                                        <path d="M1978 1584 c-65 -35 -73 -121 -17 -168 l31 -26 568 0 568 0 31 26
c57 48 47 135 -19 169 -43 22 -1121 22 -1162 -1z"/>
                                    </g>
                                </svg>

                            </Tooltip>

                        </div> */}

                        {/* </TouchableOpacity> */}
                        <TouchableOpacity
                            style={{ paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", borderRadius: 5,  backgroundColor: array && array.length >= 1 ? '#195faa' : '#Dedede', }}
                            onPress={hanlePrintMeds}>
                            <Text style={{ color: 'white', fontSize: 15 }}>Print</Text>
                        </TouchableOpacity>
                            </div>

                    </div>

                        <FlatList
                            style={{
                                flexDirection: 'column',
                                // paddingLeft: 10,
                                // paddingRight: 10,
                                paddingBottom: 10
                            }}
                            data={AllMedeications}
                            renderItem={({ item }) => (


                                <LongMedicationHandler
                                    options={() => hanldeSlectedList(item)}
                                    cardStyle={'card-header text-white bg-danger'}
                                    checkvalue={false}
                                    medDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                    //medMonth={GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                    medicationDetails={item.resource.hasOwnProperty('medicationCodeableConcept') ? capitalize(item.resource.medicationCodeableConcept.coding[0].display) : 'N/A'} //
                                    directions={item.resource.hasOwnProperty('dosageInstruction') ? item.resource.dosageInstruction[0].text : 'No'} // item.resource.dosageInstruction[0].text
                                    checking={item.resource.extension[1].valueString}
                                    quantity={item.resource.dispenseRequest.quantity.value + " " + (item.resource.extension[5] != undefined && item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : '')}
                                    endDate={item.resource.extension[4] != undefined ? (GetAppointmentDate(item.resource.extension[4].valueDateTime) + ' ' + GetMonthName(item.resource.extension[4].valueDateTime) + ' ' + GetYear(item.resource.extension[4].valueDateTime)) : "-"}
                                    startDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                    isSelected={isSelectedBtnActive}
                                    setIsSelected={setIsSelectedBtnActive}
                                    prescribedBy={item.resource.extension[2].valueString}
                                    period={item.resource.extension[1].valueString}
                                    status={item.resource.extension[0].valueBoolean ? "Long Term" : "Recent"}
                                    practiceName={item.resource.extension[3].valueString}
                                    dose={item?.resource?.extension[4]?.valueString}
                                    medid={item?.resource?.medicationCodeableConcept.coding[0]?.code}
                                />

                                // <MedicationWebHandler
                                //     medDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                //     medMonth={GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                //     medicationDetails={capitalize(item.resource.medicationCodeableConcept.coding[0].display)}
                                //     directions={item.resource.dosageInstruction[0].text}
                                //     checking={'Period: ' + item.resource.extension[1].valueString}
                                //     quantity={'Quantity: ' + item.resource.dispenseRequest.quantity.value + " " + (item.resource.extension[5] != undefined && item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : '')}
                                //     presby={item.resource.extension[4] != undefined ? (GetAppointmentDate(item.resource.extension[4].valueDateTime) + ' ' + GetMonthName(item.resource.extension[4].valueDateTime) + ' ' + GetYear(item.resource.extension[4].valueDateTime)) : "-"}
                                //     pratname={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                // />
                            )}
                            keyExtractor={(item, index) => index}
                            //    extraData={ready}
                            initialNumToRender={10}
                            maxToRenderPerBatch={10}
                            windowSize={7}

                        />
                    </>
                ) : !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                        <div className='text-danger' style={{ marginTop: 10 }}>No Records Found</div>
                </View>

            }

            {/* Edit start */}
            <Modal
                title="Repeat Medication"
                centered
                visible={repeadtedmodalVisible}
                onOk={() => {

                    debugger
                    if (ordertype?.toLowerCase() != "pick up" && (Pharmacyname.id == "" || Pharmacyname.name == "Select Pharmacy")) {
                        openNotificationWithIcon('warning', "Please select pharmacy")
                    }
                    else if(ordertype?.toLowerCase() == "pick up" && (portalSetting.IsOutstandingBalance && portalSetting.OutstandingBalance>0))
                    {
                        openNotificationWithIcon('warning', "Cannot send pickup request patient has outstanding balance")
                        return
                    }
                    else {

                        setLoading(true);
                        RepeatedMedicationAdding()
                    }


                }}
                confirmLoading={loading} 
                onCancel={() => {
                    handleRepeatMedCancel()
                }}
                //afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1000}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">

                        <div className="form-group mb-0">
                            <p style={{ textAlign: "justify" }}><strong>Provider Name:
                                {Practise && Practise.length ? Practise[0].resource.extension[2].valueString : "N/A"}</strong> </p>

                        </div>

                        <div className="form-group mb-0">
                            {portalSetting && portalSetting.PortalRepeatMedSetting &&
                                <p style={{ textAlign: "justify" }}>{portalSetting.PortalRepeatMedSetting}</p>}

                        </div>

                        <div className="form-group row mb-0">
                            <div className="col-md-12">
                                <label htmlFor="validate-internet-ip-field">Notes</label>
                                <textarea id="mNotes" type="text" value={repeatMedNote} onChange={(e)=>setRepeatMedNote(e.target.value)} style={{ backgroundColor: '#fff', resize: "none" }} className="form-control" line="3" placeholder="Notes" ></textarea>
                            </div>

                        </div>

                        <div style={{ marginTop: 20 }} className="form-group row mb-0">
                            <div className="col-md-12">
                                <label htmlFor="validate-internet-ip-field">Select Type </label>

                                {portalSetting && portalSetting.IsRxFax ?
                                    <Select
                                        getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                                        defaultValue="Pick up"
                                        value={ordertype} className="col-md-12 row mb-0"
                                        onChange={handleChangetype}>
                                  
                                    {portalSetting.ShowPickupTypeInRepeatRx ? <Option value="Pick up">Pick up</Option>:null}
                                    <Option value="Pharmacy">Pharmacy</Option>
                                </Select> :
                                    <Select defaultValue="Pick up" value={"Pick up"} getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                    } className="col-md-12 row mb-0" onChange={handleChangetype}>
                                        <Option value="Pick up">Pick up</Option>

                                    </Select>}




                                {/* <Select defaultValue="Pick up" value={ordertype} className="col-md-12 row mb-0" onChange={handleChangetype}>
                                    {portalSetting && portalSetting.isRxFax ?
                                  <Option value="Pick up">Pick up</Option>
                                  <Option value="Pharmacy">Pharmacy</Option>
                                        :  <Option value="Pick up">Pick up</Option>
                                  <Option value="Pharmacy">Pharmacy</Option>

                                    }



                                </Select> */}

                            </div>
                        </div>


                        {portalSetting && portalSetting.IsRxFax &&
                            <>
                                {ordertype == 'Pharmacy' &&
                                    <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                        <div className="col-md-12">
                                            <label htmlFor="validate-internet-ip-field">Pharmacies</label>
                                            <Select
                                        placeholder="Select Pharmacy"
                                                getPopupContainer={(trigger) =>
                                                    trigger.parentNode
                                                } 
                                                showSearch 
                                                // defaultValue={prefferedPharmacyID}
                                                value={Pharmacyname.id}
                                            style={{ width: '100%' }} id="mPharmacy" className="col-md-12 row mb-0" onChange={handleChange}
                                            filterOption={(input, option) => (option?.children?.toLowerCase() ?? '').includes(input)}>
                                                    <Option value="">Select</Option>
                                        <OptGroup label="Favorite Pharmacies" >
                                        {/* {console.log("Pharmni",Pharmacy.filter(value=>value.resource.extension[2].valueBoolean).sort((a,b)=>a.resource.name>b.resource.name?1:-1))} */}
                                                    {
                                                        Pharmacy && Pharmacy.length && Pharmacy.sort((a,b)=>a.resource.name>b.resource.name?1:-1).map((value, key) => {

                                                            if (value.resource.extension[2].valueBoolean)
                                                                return (
                                                                    <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                                                )
                                                        })
                                                    }
                                                </OptGroup>
                                                <OptGroup label="Other Pharmacies">
                                               
                                                    {
                                                        
                                                        Pharmacy && Pharmacy.length && Pharmacy.sort((a,b)=>a.resource.name>b.resource.name?1:-1).map((value, key) => {

                                                            if (!value.resource.extension[2].valueBoolean)
                                                                return (
                                                                    <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                                                )
                                                        })
                                                    }
                                                </OptGroup>
                                            </Select>
                                            <div style={{ marginTop: 20, paddingLeft: 10 }} className="form-group row mb-0">
                                            <Checkbox checked={isRepeatFavChecked} onChange={()=>setIsRepeatFavChecked(!isRepeatFavChecked)} >Mark as Favorite</Checkbox>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>}


                    </div>



                    {portalSetting && portalSetting.IsRxUrgent &&
                        <div>
                            <div className="col-md-12 mb-0 mt-2">
                                <Checkbox checked={isSelected} onChange={() => setIsSelected(!isSelected)}>Urgent</Checkbox>
                                {isSelected && <p>{portalSetting.RepeatRxUrgentNotes ? portalSetting.RepeatRxUrgentNotes : ""}</p>}



                            </div>
                        </div>
                    }



                </div>
                {/* </div> */}
            </Modal>

            {/* End Modal*/}

            <Modal
                title="Medication"
                visible={newModalVissible}
                onOk={() => {
                    props.getAppSetting()
                    setNewModalVissible(false)
                    // props.getMed(pageSize, pageNumber);
                    // setLoading(false)

                }}
                cancelText="Close"
                onCancel={() => setNewModalVissible(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                >
                
                
                <p>{mesagestate}</p>
                
            </Modal>
            {/* When provider is on leave */}
            <Modal
            title="Medication"
                visible={providerOnLeaveModalVissible}
                onOk={() => {
                    setProviderOnLeaveModalVissible(false)
                    RepeatedMedicationAdding(true)
                    // props.getMed(pageSize, pageNumber);
                    // setLoading(false)

                }}
                cancelText="Close"
                onCancel={() => setProviderOnLeaveModalVissible(false)}
            >
                <>
                <p><b>Your Provider is on Leave</b></p>
                <p>How would you like to proceed with repeat medication request</p>
                <Radio.Group value={providerLeaveChoice} onChange={(e)=>setProviderLeaveChoice(e.target.value)}>

                <Radio className='w-100' value={"other"}>
                    <div className=''>
                    <p className='mx-2'>Select Other Provider:</p> 
                <Select
                    getPopupContainer={(trigger) =>
                        trigger.parentNode
                        }
                    allowClear
                    showSearch
                    disabled={providerLeaveChoice !== "other"}
                    defaultValue={providerOnLeaveState.length ? providerOnLeaveState[0]?.ProviderID : ""}
                >
                    {providerOnLeaveState.length && providerOnLeaveState.map(state=>
                        <Option value={state.ProviderID}>{state.ProviderName}</Option>
                    )}
                   
                </Select></div>
                </Radio>
                <Radio className='mt-3 w-100' value={"default"}>Send request to default provider
                </Radio>
                </Radio.Group>
                </>
            </Modal>
            <Modal
                title="Print Selected Medications"
                open={printtMedsModal}
                onOk={() => setIsPrintActive(true)}
                onCancel={() => setPrintMedsModal(false)}
                okText="Print"
                width={1000}
            >
                <div ref={componentRef}>
                    <PrintOrganizationHeader patientProfile={Profile} organizationData={Practise} isPrintActive={isPrintActive} />
                    <table style={{ padding: "10px", borderCollapse: "collapse", borderWidth: "1px" }} className="print-med-table w-100">
                        <tr className="print-med-table-row mb-2">
                            <th className="print-med-table-head">
                                S.No.
                            </th>
                            <th className="print-med-table-head">
                                Medicine
                            </th>
                            <th className="print-med-table-head">
                                Prescribed On
                            </th>
                        </tr>
                        {array?.map((med, index) =>
                            <>
                                <tr style={{ borderWidth: "2px", borderCollapse: "collapse", borderColor: "black" }} className="print-med-table-row mb-2">
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>
                                        <p className="m-0"><b>{med?.resource?.medicationCodeableConcept?.coding[0]?.display}</b></p>
                                        <p className="m-0">Sig.: {med?.resource?.dosageInstruction[0]?.text}</p>
                                        <p className="m-0">Mitte: {med?.resource?.extension[1]?.valueString}</p>
                                    </td>
                                    <td>
                                        <p>{GetAppointmentDate(med?.resource.hasOwnProperty('authoredOn') ? med?.resource.authoredOn : null) + '/' + GetMonthName(med?.resource.hasOwnProperty('authoredOn') ? med?.resource.authoredOn : null) + '/' + GetYear(med?.resource.hasOwnProperty('authoredOn') ? med?.resource.authoredOn : null)}</p>
                                    </td>
                                </tr>
                            </>
                        )}
                    </table>
                </div>
            </Modal>
        </div>
    );
}


function mapStateToProps(state) {
    return {
        AllMedeications: state.AllMedeications,
        Pharmacy: state.Pharmacy,
        Profile: state.Profile,
        Practise: state.Practise,
        ApplicationSettings: state.ApplicationSettings

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMed: (pageSize, pageNumber) => dispatch(fetchAllMedsFromApi(pageSize, pageNumber)),
        getAppSetting: () => dispatch(fetchApplicationSettingFromApi()),
        getPharmacy: () => dispatch(fetchPharmcyFromApi()),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LongTermMed);

// export default AllMedications
