import { BOOK_APP, BOOK_APP_SUCCESS, BOOK_APP_FAIL, BOOK_APP_CLEANUP, BOOK_APP_SAME_DAY_BOOK } from "../constants/Constant";
import getAvSlots from "../services/BookAppService";
import getUserId from "../core/GetToken";
import { FormatAMPM } from "../core/datehelper";
import moment from "moment";

//function will return data
export const fetchTestAllergy = (passingDate, practitionerId, typeid, LocationIDs, sameDayBooking) => {

    return (disptach) => {  

        disptach(getApp())

        if(!sameDayBooking && passingDate == moment(new Date()).format("YYYY-MM-DD")){
            console.log("same day not allowed")
            disptach(getAppSameDayBooking())
            return false
        } 
        console.log("getting slotss")
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            // token = data
            const queryString = '?PatientID=' + item.userid + '&ScheduleDate=' + passingDate + '&PageSize=50&PractitionerID=' + practitionerId + '&AppointmentTypeID=' + typeid +'&LocationIDs='+LocationIDs;
            console.log("Date in here" + queryString, "today date ", moment(new Date()).format("YYYY-MM-DD"))
            getAvSlots(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    console.log("DATA FOUND: ", json)
                    let amlist = [];
                    let pmlist = [];
                    //    alert(JSON.stringify(json))
                    if (json[0].hasOwnProperty('entry')) {

                        json[0].entry.map((item, index) => {
                           // console.log("here is data :" + FormatAMPM(item.resource.start.split('+')[0]))
                            FormatAMPM(item.resource.start.split('+')[0]).includes('AM') ?
                                amlist.push(item) : pmlist.push(item)
                        })
                    }
                    //    formatAMPM(item.resource.start).includes('pm') &&
                    disptach(getAppSuccess(amlist, pmlist))
                })
                .catch(err => disptach(getAppFail()))
        })

    }
}

export const clenupbooking = () => {
    return (disptach) => {
        disptach(getAppCleanUp())
    }
}



const getApp = () => {
    return {
        type: BOOK_APP
    }
}

//data success
const getAppSuccess = (data, pmlist) => {
    return {
        type: BOOK_APP_SUCCESS,
        data,
        pmlist
    }
}

//data failed
const getAppFail = () => {
    return {
        type: BOOK_APP_FAIL
    }
}
const getAppSameDayBooking = () => {
    return {
        type: BOOK_APP_SAME_DAY_BOOK
    }
}

const getAppCleanUp = () => {
    return {
        type: BOOK_APP_CLEANUP
    }
}


