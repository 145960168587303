
import Main from "./app/index";
import { Worker } from '@react-pdf-viewer/core';
import * as All from './intercept'
import * as AA from './intercept2.js'
import "./App.css"
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
const BrowserRouter = require("react-router-dom").BrowserRouter

function App() {

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <BrowserRouter>

        <Main />

      </BrowserRouter>
    </Worker>
  );
}

export default App;
