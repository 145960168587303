


import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
const ImmuneHandler = props => {
    return (
        <div className="ind-med col-lg-12 ">
            <div className="card shadow p-0 mb-3 rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={props.styles}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className="med-heading" style={{ width: 450, textAlign: 'justify', fontWeight: '500', color: '#fff' }}>{props.heading}</div>
                        <div className="med-date" style={{ fontWeight: '500', color: '#fff' }}>{props.date}</div>
                    </View>
                </div>
                <div className="card-body">
                    <p className="card-title"><span className="text-bolder">Indication:</span> {props.indication}</p>
                    <p className="card-text"><span className="">Vaccinator:</span> {props.vacc}</p>
                    <p className="card-text"><i className="mdi mdi-24px mdi-clock-fast" /> {props.datedue}</p>
                </div>
            </div>
        </div>
    );
}

export default ImmuneHandler