import { DRAFT_MESSAGE_LISTING, DRAFT_MESSAGE_LISTING_SUCCESS, DRAFT_MESSAGE_LISTING_LOADING, DRAFT_MESSAGE_LISTING_ERROR, DRAFT_MESSAGE_LISTING_FAILED } from '../../constants/Constant'
import getUserId from "../../core/GetToken";
import Settings from '../../config/Settings';




/******************************** LT *******************************************/

//return long terms diagnosis
export const listDraftMessageAPI = (values) => {
    return (dispatch) => {
        dispatch(listingDraftMessages())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = `?PatientID=${item.userid}&Type=${values.type}&MessagingType=${values.messagingType}&PageSize=${values.pageSize}&PageNumber=${values.pageNumber}`
            fetch(Settings.apiUrl + "Patient/Messaging" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                    if (json == null) {
                        dispatch(listingDraftMessagesSuccess([{ total: 0 }]))

                    } else {

                        dispatch(listingDraftMessagesSuccess(json))
                    }
                })
                .catch(err => dispatch(listingDraftMessagesFailed(err)))

        })
    }
}





//ALL DIAGNOSIS LOADS HERE
const listingDraftMessages = () => {
    return {
        type: DRAFT_MESSAGE_LISTING
    }
}

const listingDraftMessagesSuccess = (data) => {
    return {
        type: DRAFT_MESSAGE_LISTING_SUCCESS,
        data,
    }
}

const listingDraftMessagesFailed = () => {
    return {
        type: DRAFT_MESSAGE_LISTING_FAILED
    }
}
