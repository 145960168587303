import { SEND_MESSAGE_LISTING, SEND_MESSAGE_LISTING_SUCCESS, SEND_MESSAGE_LISTING_LOADING, SEND_MESSAGE_LISTING_ERROR, SEND_MESSAGE_LISTING_FAILED } from '../../constants/Constant'
import getUserId from "../../core/GetToken";
import Settings from '../../config/Settings';




/******************************** LT *******************************************/

//return long terms diagnosis
export const listSendMessageAPI = (values) => {
    return (dispatch) => {
        dispatch(listingSendMessages())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = `?PatientID=${item.userid}&Type=${values.type}&MessagingType=${values.messagingType}&PageSize=${values.pageSize}&PageNumber=${values.pageNumber}`
            fetch(Settings.apiUrl + "Patient/Messaging" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                    if (json == null) {
                        dispatch(listingSendMessagesSuccess([{ total: 0 }]))

                    } else {

                        dispatch(listingSendMessagesSuccess(json))
                    }
                })
                .catch(err => dispatch(listingSendMessagesFailed(err)))

        })
    }
}





//ALL DIAGNOSIS LOADS HERE
const listingSendMessages = () => {
    return {
        type: SEND_MESSAGE_LISTING
    }
}

const listingSendMessagesSuccess = (data) => {
    return {
        type: SEND_MESSAGE_LISTING_SUCCESS,
        data,
    }
}

const listingSendMessagesFailed = () => {
    return {
        type: SEND_MESSAGE_LISTING_FAILED
    }
}
