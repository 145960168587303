import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from "react-redux";
import Dots from "react-activity/lib/Dots";
import 'react-activity/lib/Dots/Dots.css';
import Home from "./pages/Home";


import { View } from "react-native-web";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { AuthContext } from "../shared/core/context";
import { refreshTokenValidatorData, validatePatientTokenFromApi } from "../shared/actions/TokenValidActions";
import getUserId from "../shared/core/GetToken";
import { Modal, Button, Space } from 'antd';
import Appointments from "./pages/Appointments";
import Diagnosis from "./pages/Diagnosis";
import Med from "./pages/Med";
import PatientResults from "./pages/PatientResults";
import AllergiesHome from "./pages/AllergiesHome";
import ImmuneHome from "./pages/ImmuneHome";
import TimelineHome from './pages/TimelineHome';
import RemidersHome from './pages/RemidersHome';
import ResourcesHome from './pages/ResourcesHome';
import ForgetPassword from "./pages/ForgetPassword";
import LettersDocs from "./pages/LettersDocs";
import Vitals from "./pages/Vitals";
import ResetPassword from './pages/ResetPassword';
import UserProfile from "./pages/UserProfile";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import OuterHtml from './components/OutterHtml';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import ProfileScreen from './pages/ProfileScreen';
import Accounts from './pages/Accounts';
import RepeatedMedcation from './pages/RepeatedMedcation';
import ZoomMeeting from './pages/ZoomMeeting';
import Messaging from './pages/Messaging/Messaging';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ErrorPage from './pages/ErrorPage';
import { Routes } from 'react-router-dom';
import { refreshProxyListData } from '../shared/actions/ProxyActions';
import { RefreshApplicationSettings } from '../shared/actions/GetApplicationSettingAction';
import Settings from '../shared/config/Settings';
import LoginSwitch from './pages/LoginSwitch';
const Route = require("react-router-dom").Route;
const Router = require("react-router-dom").BrowserRouter;

const Redirect = require("react-router-dom").Redirect;
const useNavigate = require("react-router-dom").useNavigate;
const loadjs = require('loadjs');
//function Main() {
const Main = props => {
    useGoogleAnalytics();
    const [userToken, setUserToken] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [mobDeviceMsgModalVisible, setMobDeviceMsgModalVisible] = useState(false)
    const { identityApiData, identityApiDataLoading, identityApiDataSuccess } = props.IdentityServerApiData

    const { TokenValidator, isChecking, showModal } = props.TokenValidator;
    const linkHistory = useNavigate();

    // React.useEffect(() => {
    //     loadjs(['http://indici-pre-production.web.app/assets/js/template.js', 'http://indici-pre-production.web.app/assets/js/dashboard.js'], 'foobar');
    //     loadjs.ready('foobar', function () {

    //     });
    // }, [])
    useEffect(() => {
        if (identityApiDataSuccess && identityApiData.ACCAPIURL) {
            console.log("jajsckanskjvnasjvksa", identityApiData.ACCAPIURL)
            Settings.apiUrl = identityApiData.ACCAPIURL
        } else {
            Settings.apiUrl = process.env.REACT_APP_API_URL
        }
    }, [identityApiDataSuccess])
    React.useEffect(() => {

        // props.validateTokenRefresh()
        // props.proxyListRefresh()
        // props.appSetttingsRefresh()
        console.log("caughtttttt")
        getUserId().then((data) => {
            if (data != 'none') {
                props.validateToken(linkHistory);
            }
        })
    }, []);
    React.useEffect(() => {

        if (!TokenValidator) {
            //alert('hello')
            // if (showModal) {
            //     Modal.warning({
            //         title: 'This is a warning message',
            //         content: 'some messages...some messages...',
            //     });
            // }

            authContext.signOut()
            console.log("useLogout")
        } else {
            console.log("useLogin" + TokenValidator)
        }
    }, [TokenValidator]);
    //context api
    const authContext = React.useMemo(() => {
        return {
            signIn: () => {
                setUserToken(true);
            },
            signOut: () => {
                setUserToken(false);
                // location.reload();
            },
        }
    })


    const warning = () => {
        Modal.warning({
            title: 'This is a warning message',
            content: 'some messages...some messages...',
        });
    }
    const checkDevice = () => {
        if (isMobileOnly) {
            setMobDeviceMsgModalVisible(true)
        }
        else {
            setMobDeviceMsgModalVisible(false);
        }
    }
    useEffect(() => {
        checkDevice()
    }, [isMobileOnly])

    useEffect(() => {
        Settings.apiUrl = sessionStorage.getItem("ACCAPIURL") ? sessionStorage.getItem("ACCAPIURL") :process.env.REACT_APP_API_URL;
    }, [sessionStorage.getItem("ACCAPIURL")])
    
    return (

        <AuthContext.Provider value={authContext}>
            {/* <Switch>
                <Route exact path="/" component={Login} />
            </Switch> */}


            {
                isChecking &&
                <View style={{
                    opacity: 1,
                    position: 'relative',
                    backgroundColor: '#fff',
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Dots />
                </View>

            }
            {
                !isChecking &&
                // <div>
                //     {!isChecking && TokenValidator ? <Header /> : null}
                //     <div id="myphonemenu" className={!isChecking && TokenValidator ? "page-body" : null}>
                //         {!isChecking && TokenValidator ? <Sidebar /> : null}
                //         <div className={!isChecking && TokenValidator ? "page-content-wrapper" : null}>
                <Routes>

                        <Route element={<PublicRoute authed={TokenValidator} />}>

                            <Route path="/login" caseSensitive element={<Login />} />
                            <Route path="/loginSwitch/:q" caseSensitive element={<LoginSwitch />} />
                            <Route path="/signup" caseSensitive element={<Signup />} />
                            <Route path="/forgetPassword" caseSensitive element={<ForgetPassword />} />
                            <Route path="/resetpassword" caseSensitive element={<ResetPassword />} />
                        </Route>s

                        <Route element={<PrivateRoute authed={TokenValidator} />}>

                            <Route caseSensitive path="/preview" element={<OuterHtml />} />



                            <Route caseSensitive path="/" element={<Home />} />
                            <Route caseSensitive path="/appointments" element={<Appointments />} />
                            <Route caseSensitive path="/zoom" element={<ZoomMeeting />} />
                            <Route caseSensitive path="/diagnosis" element={<Diagnosis />} />


                            <Route caseSensitive path="/medications" element={<Med />} />
                            <Route caseSensitive path="/reports" element={<PatientResults />} />
                            <Route caseSensitive path="/allergies" element={<AllergiesHome />} />
                            <Route caseSensitive path="/immunisation" element={<ImmuneHome />} />
                            <Route caseSensitive path="/timeline" element={<TimelineHome />} />
                            <Route caseSensitive path="/reminders" element={<RemidersHome />} />
                            <Route caseSensitive path="/resources" element={<ResourcesHome />} />
                            <Route caseSensitive path="/letters" element={<LettersDocs />} />
                            <Route caseSensitive path="/vitals" element={<Vitals />} />
                            <Route caseSensitive path="/emails" element={<Messaging />} />
                            <Route caseSensitive path="/profile" element={<ProfileScreen />} />
                            <Route caseSensitive path="/profile-old" element={<UserProfile />} />
                            <Route caseSensitive path="/accounts" element={<Accounts />} />
                            <Route caseSensitive path="/medication" element={<RepeatedMedcation />} />
                        </Route>

                    {/* <PrivateRoute exact path="/inbox" component={MessagesHome} authed={TokenValidator} /> */}

                    {/* <PrivateRoute exact path="/read" component={ReadMessage} authed={TokenValidator} /> */}


                    {/* <PrivateRoute exact path="/" component={Home} />
                        <PrivateRoute exact path="/appointments" component={Appointments} /> */}
                    {/* <PrivateRoute exact path="/diagnosis" component={Diagnosis} />
                        */}
                    <Route path="*" element={<ErrorPage />} />

                </Routes>
                    //             {!isChecking && <footer className="footer-ignore">
                    //                 <small>Version 0.4.7.5<br />© Copyrights . All rights Reserved</small>
                    //             </footer>}
                    //         </div>

                    //     </div>

                    // </div >



            }

            {/* {
                isChecking &&
                <View style={{
                    opacity: 1,
                    position: 'relative',
                    backgroundColor: '#fff',
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Dots />
                </View>

            }
            {
                !isChecking && userToken != null || TokenValidator ? (
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/appointments" component={Appointments} />
                        <Route exact path="/diagnosis" component={Diagnosis} />
                        <Route exact path="/medications" component={Med} />
                        <Route exact path="/reports" component={PatientResults} />
                        <Route exact path="/allergies" component={AllergiesHome} />
                        <Route exact path="/immunisation" component={ImmuneHome} />
                        <Route exact path="/timeline" component={TimelineHome} />
                        <Route exact path="/reminders" component={RemidersHome} />
                        <Route exact path="/resources" component={ResourcesHome} />
                        <Route exact path="/letters" component={LettersDocs} />
                        <Route exact path="/vitals" component={Vitals} />
                        <Route exact path="/emails" component={Messaing} />
                        <Route exact path="/profile" component={UserProfile} />

                    </Switch>
                ) : (
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/forgetPassword" component={ForgetPassword} />
                            <Route exact path="/resetpassword" component={ResetPassword} />
                        </Switch>
                    )
            } */}

            {/* <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/appointments" component={Appointments} />
            </Switch> */}
            {/* <Modal
                open={mobDeviceMsgModalVisible}
                onOk={() => {
                    // setMobDeviceMsgModalVisible(false);
                    // window.open("https://play.google.com/store/apps/details?id=nz.app.myindici", "_blank")
                    window.location = "https://play.google.com/store/apps/details?id=nz.app.myindici"
                }}
                closable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                title="Switch to Mobile App"
            >
                For the best user experience, we recommend you install the mobile app. The app also enables you to take advantage of your device's inbuilt features such as Fingerprint or Face ID login, touch controls, push notifications and enhancements.
            </Modal> */}
        </AuthContext.Provider >
    );
}




function mapStateToProps(state) {
    return {
        TokenValidator: state.TokenValidator,
        IdentityServerApiData: state.IdentityServerReducer,

    }
}
function mapDispatchToProps(disptach) {
    return {
        validateToken: (history) => disptach(validatePatientTokenFromApi(history)),
        validateTokenRefresh: () => disptach(refreshTokenValidatorData()),
        proxyListRefresh: () => disptach(refreshProxyListData()),
        appSetttingsRefresh: () => disptach(RefreshApplicationSettings()),

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main)
