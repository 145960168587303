import React, { useState, useEffect } from "react";
import { Select, Modal, Button, message, Upload } from "antd";
import { Input, notification, Spin } from "antd";
import { RadiusBottomleftOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { getMessageRecipientsAPI } from "../../../../shared/actions/MessagingActions/ListMessagesRecipients";
import { listMessageRolesAPI } from "../../../../shared/actions/MessagingActions/LoadMessagingRoles";
import { sendMessageAPI } from "../../../../shared/actions/MessagingActions/SendingMessages";
import { connect } from "react-redux";
import AttachmentsHandler from "../MessageAttachments/AttachmentsHandler";
import renderHTML from 'react-render-html';

const { Option } = Select;
const { TextArea } = Input;

const ReplyMsgModal = (props) => {
    const { recipientsData, recipientsDataSuccess } = props.MessageRecipients
    const { rolesData, rolesDataSuccess } = props.MessagingRoles
    const { sendMessage, sendMessageLoading, sendMessageSuccess, sendMessageFailed } = props.SendingMessage

    const [msgProviderLocaionId, setMsgProviderLocationId] = useState(rolesDataSuccess ? rolesData[0].entry[0].resource.id : 0);
    const [msgProviderLocationName, setMsgProviderLocationName] = useState(rolesDataSuccess ? rolesData[0].entry[0].resource.title : null);
    const [msgRecipientId, setMsgRecipientId] = useState(recipientsDataSuccess && recipientsData[0].entry
        ? recipientsData[0]?.entry[0]?.resource.extension[0].valueString
        : null);
    const [msgRecipientName, setMsgRecipientName] = useState(recipientsDataSuccess && recipientsData[0].entry
        ? recipientsData[0].entry[0].resource.name[0].text
        : null);
    const [msgSubject, setMsgSubject] = useState("Re:" + props.replySubject);
    const [msgBody, setMsgBody] = useState("");
    const [msgBodyErrMsg, setMsgBodyErrMsg] = useState("");
    const [msgSubErrMsg, setsetMsgSubErrMsg] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([])
  const [imageFiles, setImageFiles] = useState([])


    const onRolesChange = (value, option) => {
        console.log(value)
        setMsgProviderLocationId(value);
        setMsgProviderLocationName(option.children);
        setMsgRecipientId(null);
        const values = {

            roleID: value,
        };
        props.getMsgRecipients(values);
    };
    const onProviderChange = (value, option) => {
        setMsgRecipientId(option.key);
        setMsgRecipientName(option.children);
        console.log("provider name = " + msgRecipientName)
        console.log("provider id = " + msgRecipientId)
    };

    const checkValidations = () => {
        let caughtErr = false
        console.log("validatinggg", msgBody)

        if (msgBody == "" || msgBody == null) {
            setMsgBodyErrMsg("Please type something in the body of the message")
            caughtErr = true
        }
        if (msgSubject == "" || msgSubject == null) {
            console.log("empptyyy boddd")
            setsetMsgSubErrMsg("Please type something in the subject of the message")
            caughtErr = true
        }
        return caughtErr
    }
    const handleSendClick = () => {
        let validateErr = checkValidations()
        if (validateErr) {
            return
        }
        const values = {
            centre: {
                id: "Location/" + msgProviderLocaionId,
                name: msgProviderLocationName,
            },
            practitioner: {
                id: props.providerRef,
                name: props.provider
            },

            message: {
                subject: msgSubject,
                body: msgBody + "<br><hr><br><br>" + props.oldMessageBody,
            },
            selectedFiles: selectedFiles,
            messageType: "Send Mail",
            roleID: props.roleID,
        };
        props.sendMessage(values)

    };
    const handleDraftClick = () => {
        let validateErr = checkValidations()
        if (validateErr) {
            return
        }
        const values = {
            centre: {
                id: "Location/" + msgProviderLocaionId,
                name: msgProviderLocationName,
            },
            practitioner: {
                id: props.providerRef,
                name: props.provider
            },
            message: {
                subject: msgSubject,
                body: msgBody + "<br /><hr /><br /><br />" + props.oldMessageBody,
            },
            selectedFiles: selectedFiles,
            messageType: "Draft",
            roleID: props.roleID,

        };
        props.sendMessage(values)


    };


    useEffect(() => {
        console.log("Selected Files ", selectedFiles)

    }, [selectedFiles])
    useEffect(() => {
        console.log("subject of msg" + msgSubject)
    }, [])

    useEffect(() => {
        if (sendMessageSuccess) {
            console.log("success ", sendMessageSuccess);
            props.handleCancel()
        }
    }, [sendMessageLoading])
    const handleRemoveFile =(index)=>{
    if(selectedFiles.length >0){
      setSelectedFiles(selectedFiles.filter((file, fileIndex)=> fileIndex != index))
    }
  }
const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
const pdfTypeRegex = /application\/(pdf)/gm;
const selectFilesHandler = (e) => {
    const { files } = e.target;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match(imageTypeRegex) || file.type.match(pdfTypeRegex)) {
        validImageFiles.push(file);
      }
    }
    if (validImageFiles.length) {
      setImageFiles(validImageFiles);
      return;
    }
    alert("Selected images are not of valid type!");
  };
  useEffect(() => {
    const images = [], fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        let data ={}
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
             data = {
                contentAttachment: {
                  contentType: result.split("/")[1].split(";base64")[0],
                  data: result.split("base64,")[1]
                
              }
            }
            images.push(data)
          }
          if (images.length === imageFiles.length && !isCancel) {
            setSelectedFiles((prev) => prev.concat(images));
          }
        }
        fileReader.readAsDataURL(file);
      })
    };
    return () => {
      isCancel = true;
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFiles]);
    return (
        <>
            <Modal
                title="Reply Message"
                width={800}
                style={{ top: "50px" }}
                open={props.isReplyModalOpen}
                onCancel={props.handleCancel}
                footer={[
                    <Button
                        key="cancel"
                        onClick={props.handleCancel}
                        className="cancel-msg-btn"
                    >
                        Cancel
          </Button>,
                    <Button
                        key="draft"
                        onClick={handleDraftClick}
                        className="draft-msg-btn"
                    >
                        Draft
          </Button>,
                    <Button key="send" onClick={handleSendClick} className="send-msg-btn">
                        Send
          </Button>,
                ]}
            >
                {props.disclaimerMsg && props.disclaimerMsg != "" &&
                 <div className="row alert alert-warning compose-msg-diclaimer">
                    <h6 className="alert-heading">Disclaimer:</h6>
                    {
                          renderHTML(props.disclaimerMsg)
                    }

        </div>}
        <div className="row">
        <label style={{fontWeight: 'bolder'}} className="col-3 ">Practitioner</label>
                <Input className="col-8" placeholder="Select Provider" value={props.provider} />
</div>
        <div className="row">

          <label style={{fontWeight: 'bolder', marginTop:"15px"}} className="col-3 ">Subject</label>

                < Input
                className="col-8"
                    placeholder="Subject"
                    style={{ marginTop: "15px" }}
                    value={msgSubject}
                />
                <p className="text-danger">{msgSubErrMsg}</p>
                </div>
                <TextArea
                    // value={value}
                    // onChange={(e) => setValue(e.target.value)}
                    placeholder="Write your message here"
                    autoSize={{ minRows: 5, maxRows: 8 }}
                    style={{ width: "100%", marginTop: "15px" }}
                    value={msgBody}
                    onChange={(e) => { setMsgBody(e.target.value); setMsgBodyErrMsg("") }}
                    maxLength={800}
                />
                <p className="text-danger">{msgBodyErrMsg}</p>
                <TextArea
                    className="mt-2"
                    // value={value}
                    // onChange={(e) => setValue(e.target.value)}
                    placeholder="Original Message"
                    autoSize={{ minRows: 5, maxRows: 10 }}
                    contentEditable={false}
                    style={{ width: "100%", marginTop: "15px", opacity: "0.5" }}
                    value={props.oldMessageBody}
                    maxLength={800}
                />
            <label htmlFor="file" className="p-2 text-primary fw-bold mt-3 px-3 choose-file">Choose Files
        </label>
                <input
            type="file"
            id="file"
            onChange={selectFilesHandler}
            accept="image/png, image/jpg, image/jpeg,application/pdf"
            multiple
          />
          {
        selectedFiles.length > 0 ?
          <AttachmentsHandler selectedFiles={selectedFiles} type="composing" handleRemoveFile={handleRemoveFile}/>:null
      }

            </Modal>
        </>
    );
};

function mapStateToProps(state) {
    return {

        MessageRecipients: state.MessagesRecipientsReducer,
        SendingMessage: state.SendingMessagesReducer,
        MessagingRoles: state.LoadMessagingRolesReducer
    }
}
function mapDispatchToProps(disptach) {
    return {

        getMsgRecipients: (values) => disptach(getMessageRecipientsAPI(values)),
        sendMessage: (values) => disptach(sendMessageAPI(values)),
        loadMsgRoles: () => disptach(listMessageRolesAPI()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReplyMsgModal)
// export default
