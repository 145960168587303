import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions, TouchableOpacity } from 'react-native';


const MedicationHandler = props => {
    console.log(props)
    return (
        <div className="col-lg-12 ">
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#f3f3f3', padding: 10 }}>
                <View style={{ width: 8, backgroundColor: props.medcategory=="longterm" ?'rgb(246, 134, 71)': '#11578f', borderTopRightRadius: 5, borderBottomRightRadius: 5 }}>

                </View>
                
                <div className="item-wrapper" style={{ flexDirection: 'column', marginLeft: 10 }}>
                    <Text style={{ color: '#000', fontSize: 14 }}>Prescribed On: {props.medDate}</Text>
                    <p className="card-title" style={{ color: '#707070', marginTop: 5, fontSize: 14 }}>{props.medicationDetails}</p>
                    <p style={{ color: '#707070', fontsize: 14, marginTop: 10 }}>{props.directions}</p>
                </div>

            </View>
            {/* <div className="card shadow p-0 mb-3 rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={props.cardStyle}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        


                            <Text style={{ fontWeight: '500', color: '#fff', marginLeft: 10 }}>{props.medicationDetails}</Text>

                            <Text style={{ fontWeight: '500', color: '#fff' }}>{props.medDate}</Text>
                        </View>
                       
                </div>
                <div className="card-body">
                    <h4 className="card-title"><i className="mdi mdi-18px mdi-pharmacy" /> {props.directions}</h4>
                    <p style={{ marginTop: 5 }} className="card-text"><i className="mdi mdi-18px mdi-calendar-clock" /> Period: {props.checking} , Quantity: {props.quantity}</p>

                    <View style={{ width: '100%', marginTop: 0, borderTopWidth: 1, borderTopColor: '#DDDDDD' }}></View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p className="card-text"><i className="mdi mdi-24px mdi-clock-in" />Start Date: {props.startDate}</p>
                        <p className="card-text"><i className="mdi mdi-24px mdi-clock-out" />End Date: {props.endDate}</p>
                    </View>


                </div>
            </div> */}
        </div>
    );
}

export default MedicationHandler
