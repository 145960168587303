import { DELETE_MESSAGE, DELETE_MESSAGE_SUCCESS, DELETE_MESSAGE_REFRESH_DATA, DELETE_MESSAGE_LOADING, DELETE_MESSAGE_ERROR, DELETE_MESSAGE_FAILED } from '../../constants/Constant'


//SET INITAILS STATE
const initialState = {
    deleteMessage: null,
    deleteMessageLoading: false,
    deleteMessageSuccess: false,
    deleteMessageFailed: false,
    deleteMessageError: null,
    deleteMessageStatus: "idle",
}
// const initialState = {
//     deleteMessage: "",
//     deleteMessageLoading: "",
//     deleteMessageSuccess: "",
//     deleteMessageFailed: "",
//     deleteMessageError: "",
//     deleteMessageStatus: "",
// }

//export the defult state

export default function deleteMessageReducer(state = initialState, action) {
    switch (action.type) {
        case DELETE_MESSAGE:
            return {
                ...state,
                deleteMessageLoading: true,
                deleteMessageStatus: "loading",
                deleteMessage: null,
                deleteMessageSuccess: false,
                deleteMessageFailed: false,
                deleteMessageError: null,
            }

        case DELETE_MESSAGE_REFRESH_DATA:
            console.log("refreshing delete mesage data")
            return {
                ...state,
                deleteMessage: null,
                deleteMessageLoading: false,
                deleteMessageSuccess: false,
                deleteMessageFailed: false,
                deleteMessageError: null,
                deleteMessageStatus: "idle",
            }
        case DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                deleteMessageLoading: false,
                deleteMessage: action.data,
                deleteMessageSuccess: true,
                deleteMessageStatus: "success"
            }

        case DELETE_MESSAGE_FAILED:
            return {
                ...state,
                deleteMessageLoading: false,
                deleteMessageStatus: "failed",
                deleteMessageError: action.data,
                deleteMessageFailed: true
            }
        default:
            return state
    }
}