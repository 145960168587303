import { GET_LOOKUP_LIST, GET_LOOKUP_LIST_SUCCESS, GET_LOOKUP_LIST_FAILED } from '../constants/Constant'


//SET INITAILS STATE
const initialState = {
    LookUpList: [],
    isFetchingLookup: false,
    error: false
}

//export the defult state

export default function LookupListReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LOOKUP_LIST:
            return {
                ...state,
                isFetchingLookup: true
            }

        case GET_LOOKUP_LIST_SUCCESS:
            return {
                ...state,
                isFetchingLookup: false,
                LookUpList: action.data
            }

        case GET_LOOKUP_LIST_FAILED:
            return {
                ...state,
                isFetchingLookup: false,
                error: true,
                LookUpList: []
            }
        default:
            return state
    }
}