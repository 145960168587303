import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { ActivityIndicator, View, FlatList, TouchableOpacity, Text } from "react-native-web";
import DatePicker from "react-horizontal-datepicker";
import { fetchAvailablePractitionerFromApi, getPractitionerClean } from "../../../shared/actions/GetAvailablePractitionerAction";
import { fetchRolesFromApi } from "../../../shared/actions/GetRolesAction";
import { fetchAppointmentsTypesFromApi } from "../../../shared/actions/GetAppointmentsTypeAction";
import { fetchTestAllergy, clenupbooking } from '../../../shared/actions/BookAppActions'
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime, FormatAMPM } from '../../../shared/core/datehelper';
import { addPaitentAppoinmenttoAPI, clenuplatData } from '../../../shared/actions/AddApointmentActions';
import { Space } from 'antd';
import { Modal, Button, Checkbox, Radio } from 'antd';
import renderHTML from 'react-render-html';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import $ from "jquery";
import { fetchfAppointmentsFromApi } from "../../../shared/actions/FutureAppointActions";
import getUserId from '../../../shared/core/GetToken';
//import GetAppSettings from '../../../shared/core/GetAppSettings';
import Settings from '../../../shared/config/Settings';
import { fetchProviderLocationFromApi } from "../../../shared/actions/GetProviderLocationAction";
import { fetchLookupListAPI } from "../../../shared/actions/LookUpActions";
import GetAppSettings from '../../../shared/core/GetAppSettings';
import { notification, } from 'antd';



import moment from 'moment';
import { debounce } from 'underscore';
let apiCounter = 0;
let maxdate = '';
let morning = false;
let evening = false;
let children = [];
let allist = [];
let str = '';
let listofsymptoms = ""
let travel = ""
let contact = "";
const NewUpdatedAppointment = props => {
    const { FutureAppointments, isGettingFut } = props.FutureAppointments;
    const { AppointmentTypes, isLoadingAPPType } = props.AppointmentTypes
    const { ProviderLocation, defaultLocation, Colorlist, isFetchingProvider } = props.ProviderLocation;
    const { LookUpList, isFetchingLookup } = props.LookUpList
    const [portalSetting, setPortalSetting] = useState(null);
    const { AvailablePractitioner, isGetting } = props.AvailablePractitioner;
    const { Roles, isFetching } = props.Roles;
    /////////////Booking 
    const { BookApp, BookAppPM, isBooking, sameDayErrorMsg } = props.BookApp;
    const { AddAppointment, isAdding, AddingFailed, isAvailable } = props.AddAppointment;

    const { Proxy, isGettlistPrpxy } = props.Proxy;
    const [slotId, setSlotId] = useState('');

    const [showReset, setShowReset] = useState(false);
    const [appointTime, setAppointTime] = useState('');
    const [durationAppoint, setdurationAppoint] = useState('');
    const [doctorName, setDoctorName] = useState('');
    const [loadingDoctors, setLoadingDoctors] = useState(false);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [Pid, SetPid] = useState('')
    // const [pageSize, setPageSize] = useState(100);           //state for the page number
    // const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    const [isselected, setIsSelected] = useState(false);
    const [isselectedreason, setIsSelectedReason] = useState(false); //state for the
    const [isSelectedBook, setIsSelectedBook] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date()); //state for the
    const [availableDay, setAvailableDay] = useState('')
    const [typeid, setTypeId] = useState('')
    const [typeName, setTypeName] = useState('')


    const [AvailableDateList, setAvailableDateList] = useState([]) //store the dates
    const [apiCalled, setApiCalled] = useState(false);
    const [isCovidModalVisible, setIsCovidModalVisible] = useState(false);
    // const [appSettings, setAppSettings] = useState(null)
    const [travelcheck, setTravelCheck] = React.useState(2);
    const [contactCheck, setContactCheck] = React.useState(2);
    const [bookingtype, setBookingType] = React.useState(1);
    const [locationName, setLocationName] = useState('')
    const [value, setValue] = React.useState(1);
    const [user, setUser] = React.useState(null)

    const [selectedReasonForVisit, setSelectedReasonForVisit] = useState(25)


    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    const [loadingdate, setLoadingDate] = useState(false)
    const [newDate, setNewDate] = useState('')
    const [roleid, setRoleId] = useState("")
    const [roleName, setRoleName] = useState('')

    const { Option } = Select;

    const [nosymptoms, setNoSymptom] = useState(true)
    const [symptoms, setSymptoms] = useState('')
    const [confirmModalvisible, setConfirmModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const [confirmModalvisible2, setConfirmModalVisible2] = useState(false);
    const [confirmLoading2, setConfirmLoading2] = React.useState(false);
    const [appointmenttype2, setAppointmenttype2] = useState('')
    const [currentUser, setCurrentUser] = React.useState('');
    const [PaitientID, setPaitientID] = React.useState('');

    const [coivdDose, setCovidDose] = useState(1)
    const [wearMask, setWearMask] = useState(1)
    const [PracticeLocationID, setPracticeLocation] = useState('')
    const [ready, setReady] = useState(false)
    const [ErrorModal, setErrorModal] = useState(false)


    const onBookForChange = e => {
        // //console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    const onBookingTypeChange = e => {
        // //console.log('radio checked', e.target.value);
        setBookingType(e.target.value);
    };
    const onTravelChange = e => {
        // //console.log('radio checked', e.target.value);
        setTravelCheck(e.target.value);
    };
    const onContactWithSickChange = e => {
        // //console.log('radio checked', e.target.value);
        setContactCheck(e.target.value);
    };

    const onChangeConsentA = e => {
        setCovidDose(e.target.value)
    }

    const onChangeConsentB = e => {
        setWearMask(e.target.value)
    }

    const addClassTest = (val, item) => {
        // alert(JSON.stringify(e))
        //  ////console.log("hahahahahahahah")
        $('.timing').removeClass('selected')
        $('#m' + val).addClass('selected')
        //  alert(appSettings)
        const newItem =
            setAppointTime(FormatAMPM(item.resource.start.split('+')[0]));

        setLocationName(ProviderLocation.length > 0 ? ProviderLocation.filter(location => {
            if (location.resource.extension[0].valueString == item.resource.extension[5].valueString)
                return true
        })[0].resource.extension[1].valueString : '')


        //if true then direct confirmTION modal
        if (portalSetting && portalSetting.AppointmentShowReasonforContact) {
            // setConfirmModalVisible(true)
            setIsSelectedReason(true)

            // setSlotId(item.resource.extension[0].valueString);
        }
        else {
            setIsSelectedReason(true)
        }

        if (portalSetting && !portalSetting.DisableAppointmentCOVID) {
            setIsCovidModalVisible(true);
            setSlotId(item.resource.extension[0].valueString);
        } else {
            setConfirmModalVisible(true)

            setSlotId(item.resource.extension[0].valueString);
        }


    }
    //handleConfirmAppoint()
    const handleCovidModalOk = () => {

        if (symptoms != '' || nosymptoms) {

            // if (nosymptoms && contactCheck == 2 && travelcheck == 2) {
            if (nosymptoms && coivdDose == 1 && wearMask == 1) {

                setConfirmModalVisible(true);
                setIsCovidModalVisible(false);
            }
            else {
                if (portalSetting && portalSetting.IsShowBookAppointmentOptions) {
                    listofsymptoms = ""
                    symptoms.map((item, index) => {
                        return (
                            listofsymptoms = listofsymptoms + item + ","
                        )
                    })
                    travel = travelcheck == 1 ? "Yes \nDetail:" + document.getElementById('mtravel').value : "No"
                    contact = contactCheck == 1 ? "Yes \nDetail:" + document.getElementById('mcontact').value : "No"
                    // alert(listofsymptoms + travel + contact)
                    let message = `Suspected COVID-19 \nSymptoms \nHave you had any of the following?\n${listofsymptoms} \nRecent travel or contact with someone with novel coronavirus \n a) Have you travelled to or from (excluding airport transit through) countries or areas of concern* within 14 days before onset of illness? ${travel}\nb) Have you been in close contact or casual contact** with a person with suspect, probable or confirmed infection of novel coronavirus in the 14 days before the onset of your symptoms? ${contact}`;
                    document.getElementById('mReason').value = message
                    setIsCovidModalVisible(false);
                    setConfirmModalVisible2(true);
                } else {
                    // setIsCovidModalVisible(false);
                    // alert("hihihi")
                    // setConfirmModalVisible(true);
                    // document.getElementById('mReason').value = "As per the responses recorded through this questionnaire, your appointment cannot be booked. For further assistance please contact the practice to organize/manage your appointment."
                    setIsCovidModalVisible(false);
                    // setConfirmModalVisible(true);
                    setErrorModal(true)
                }

            }

        } else {
            openNotificationWith('error')
        }


    };
    const handleCovidModalCancel = () => {
        setIsCovidModalVisible(false);
        setWearMask(1)
        setCovidDose(1)
        setNoSymptom(true)
        setSymptoms("")
        setContactCheck(2)
        setTravelCheck(2)
    };

    const openNotificationWith = type => {
        notification[type]({
            message: 'Notification',
            description:
                'Fill mandatory field.',
        });
    };
    const openNotificationWithDynamic = (type, value) => {
        notification[type]({
            message: 'Notification',
            description:
                value,
        });
    };
    const openNotificationWithIcon = type => {
        notification[type]({
            message: 'Notification',
            description:
                'No More Slots Available for next Day',
        });
    };

    const selectedDay = val => {
        let valLocation = '';
        defaultLocation.map((item, index) => {
            return (
                valLocation = valLocation + item + ","
            )
        })
        //     apiCounter = apiCounter + 100000
        setNewDate('')
        ////console.log(val);
        setSelectedDate(val)
        setApiCalled(false)
        apiCounter = 0;
        // testingfunction(val)
        // alert(GetAppointmentDate(val) + ' ' + GetMonthName(val) + ' ' + GetYear(val))
        props.getApp(moment(val).format("YYYY-MM-DD"), Pid, typeid, valLocation, portalSetting.IsAllowSameDayBooking);
        props.addAppointClean();
        setIsSelectedReason(false);
    };

    function onBookWithChange(value, index) {
        ////console.log(`selected ${ value }`);
        // alert(`selected ${ value } `);
        setLoadingDoctors(true)
        setIsSelected(false)
        setRoleId(value)
        setRoleName(index.children)

        //console.log("appointmentType",typeid)
        props.getPractitioner(value, typeid, Roles[0].resource.extension[2].valueString, PracticeLocationID, selectedReasonForVisit)


    }

    function onSelectProviderChange(value) {
        let valLocation = '';
        defaultLocation.map((item, index) => {
            return (
                valLocation = valLocation + item + ","
            )
        })
        ////console.log(`selected ${ value } `);
        setIsSelected(true)
        SetPid(value)
        props.getApp(moment(selectedDate).format("YYYY-MM-DD"), value, typeid, valLocation, portalSetting.IsAllowSameDayBooking);

        // alert(`selected ${ value } `);
        // setLoadingDoctors(true)
        //     props.getApp(selectedDate, value);

        // props.getAppCleanUp()

    }
    function onAppointTypeSelectChange(value, children) {
        setTypeName(children.label)
        setTypeId(value)
    }
    function onAppointTypeSelectChange2(value) {

        // alert(`selected ${ value }`)
        setAppointmenttype2(value)


    }
    function onSelectAccountChange(value) {
        //alert(value)
        setPaitientID(value)
        ////console.log(`selected ${ value } `);
        ////console.log(`selected ${ value } `);
        //   setIsSelected(true)
        // SetPid(value)
        // setTypeId(value)
        //     props.getPractitioner(Roles[0].resource.extension[0].valueString, value, Roles[0].resource.extension[2].valueString)


    }

    // function onBlur() {
    //     ////console.log('blur');
    // }

    // function onFocus() {
    //     ////console.log('focus');
    // }

    // function onSearch(val) {
    //     ////console.log('search:', val);
    // }
    const nextAvailableDay = () => {

        if (Date.parse(new Date(maxdate)) == Date.parse(selectedDate)) {
            let valLocation = '';
            defaultLocation.map((item, index) => {
                return (
                    valLocation = valLocation + item + ","
                )
            })
            setSelectedDate(new Date())
            openNotificationWithIcon('warning')
            apiCounter = 0;
            setApiCalled(false)

            props.getApp(moment(new Date()).format("YYYY-MM-DD"), Pid, typeid, valLocation, portalSetting.IsAllowSameDayBooking);
            return
        }
        // else {
        //     alert(Date.parse(new Date(maxdate)) + ' !- ' + Date.parse(selectedDate))
        // }

        // alert(apiCounter)
        if (!apiCalled) {
            //alert("called")
            setLoadingDate(true)
            getUserId().then((userInfo) => {
                const item = JSON.parse(userInfo);
                var myHeaders = new Headers();
                myHeaders.append("Token", item.token);

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                ////console.log(Settings.apiUrl + "Patient/NextAvailableDay?PatientID=" + item.userid + "&ProviderID=" + Pid + "&AppointmentTypeID=" + typeid + "&StartDate=" + new Date())
                fetch(Settings.apiUrl + "Patient/NextAvailableDay?PatientID=" + item.userid + "&ProviderID=" + Pid + "&AppointmentTypeID=" + typeid + "&StartDate=" + moment(selectedDate).format("YYYY-MM-DD"), requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        var finalData = replaceAllBackSlash(result) //result.replace(/\\\//g, "/"); // hi user
                        //  var finalData = result.replace(/\\/g, "");
                        let res = JSON.parse(finalData)
                        ////console.log(finalData)
                        if (res.length) {
                            let valLocation = '';
                            defaultLocation.map((item, index) => {
                                return (
                                    valLocation = valLocation + item + ","
                                )
                            })
                            setAvailableDateList(res)
                            setLoadingDate(false)
                            apiCounter = apiCounter + 1;
                            setApiCalled(true);
                            setSelectedDate(new Date(res[0].RosterDate))

                            //alert(new Date(res[0].RosterDate))

                            setNewDate(GetAppointmentDate(res[0].RosterDate) + ' ' + GetMonthName(res[0].RosterDate) + ' ' + GetYear(res[0].RosterDate))
                            props.getApp(moment(res[0].RosterDate).format("YYYY-MM-DD"), Pid, typeid, valLocation, portalSetting.IsAllowSameDayBooking);

                        }
                        else {
                            openNotificationWithIcon('warning')
                            setLoadingDate(false)
                        }

                    })
                    .catch(error => {

                        setLoadingDate(false)
                        ////console.log('error', error)
                    });
            })
        } else {

            if (AvailableDateList[apiCounter] != undefined) {
                let valLocation = '';
                defaultLocation.map((item, index) => {
                    return (
                        valLocation = valLocation + item + ","
                    )
                })
                setSelectedDate(new Date(AvailableDateList[apiCounter].RosterDate))
                setNewDate(GetAppointmentDate(AvailableDateList[apiCounter].RosterDate) + ' ' + GetMonthName(AvailableDateList[apiCounter].RosterDate) + ' ' + GetYear(AvailableDateList[apiCounter].RosterDate))
                props.getApp(moment(AvailableDateList[apiCounter].RosterDate).format("YYYY-MM-DD"), Pid, typeid, valLocation, portalSetting.IsAllowSameDayBooking);

            } else {
                //    alert("No More Slots")

                let valLocation = '';
                defaultLocation.map((item, index) => {
                    return (
                        valLocation = valLocation + item + ","
                    )
                })
                openNotificationWithIcon('warning')
                apiCounter = 0;
                setApiCalled(false)
                setSelectedDate(new Date())
                //  setSelectedDate(AvailableDateList[apiCounter - 1].RosterDate)
                // setSelectedDate(new Date(AvailableDateList[apiCounter].RosterDate))
                setNewDate('Showing Slots for today')
                setAvailableDateList([])                //  alert(AvailableDateList[apiCounter].RosterDate)
                props.getApp(moment(new Date()).format("YYYY-MM-DD"), Pid, typeid, valLocation, portalSetting.IsAllowSameDayBooking);
                // apiCounter = 0;
                return

            }
            apiCounter = apiCounter + 1;
        }
    }

    function replaceAllBackSlash(targetStr) {
        var index = targetStr.indexOf("\\");
        while (index >= 0) {
            targetStr = targetStr.replace("\\", "");
            index = targetStr.indexOf("\\");
        }
        return targetStr;
    }

    const handleCloseReset = () => {
        setShowReset(false)
        props.addAppointClean();
        // testFunction(AuthUser[0].extension[0].valueString, AuthUser[0].id)

    };

    const handleConfirmAppoint = () => {
        let reason = document.getElementById('mReason').value;
        if (reason == '') {
            reason = "Not Provided"
        }
        /////////////////here we need 
        // alert(selectedReasonForVisit)
        if (value == 1)
            props.addAppoint(slotId, reason, '', appointmenttype2, selectedReasonForVisit
            );
        else if (value == 2)
            props.addAppoint(slotId, reason, PaitientID, appointmenttype2, selectedReasonForVisit);
    }
    const onLookupChange = (e) => {
        console.log("value of eeee", e)
        
        setSelectedReasonForVisit(e);



        //console.log("Reason",e)
    }

    let ignoredNote = 1;
    const appointBookSuccess = () => {
        //    dispatch(fetchCounterFromApi())
        if (ignoredNote == 1) {
            appsuccessnoti('success')
            setPaitientID('')
        }
        ignoredNote = ignoredNote + 1;
        props.addAppointClean();
        // setTimeout(() => {
        //     window.location.reload();
        // }, 1500);

        // props.navigation.navigate('Appnav')
    }
    const appointBookFailed = () => {
        //    dispatch(fetchCounterFromApi())
        failNoti('error')
        setConfirmLoading2(false);
        props.addAppointClean();
        setConfirmLoading(false)
        setConfirmModalVisible(false)
        //setConfirmLoading2(true)
        setConfirmModalVisible2(false)
        // setTimeout(() => {
        //     window.location.reload();
        // }, 1500);

        // props.navigation.navigate('Appnav')
    }
    const appsuccessnoti = type => {
        notification[type]({
            message: 'Notification',
            description:
                'Appointment Booked successfully',
        });
    };
    const failNoti = type => {
        let message = AddAppointment.AppoinmentStatus
        console.log(typeof (AddAppointment), " => ", AddAppointment)
        if (AddAppointment.AppoinmentStatus == "NotCreatedEmailMissing") {
            message = "Zoom email account does not exist for provider of this patient"
        } else if (AddAppointment.AppoinmentStatus == "NotCreatedZoomEnd") {
            message = "Unable to book appointment due to issue at Zoom end"
        } else if (typeof (AddAppointment) == "string") {
            message = AddAppointment
        }
        openNotificationWithDynamic("error", message)
        // notification[type]({
        //     message: 'Notification',
        //     description:
        //         message,
        //     type: type

        // });
    };

    function handleChangeLocation(value) {
        //console.log(`selected ${value} `);
        setPracticeLocation(value)
        props.getRoles(value)
        // props.getPractitioner(roleid, typeid, Roles[0].resource.extension[2].valueString, value)
        props.getAppCleanUp()

    }
    const getCurrentUserId = () => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // ////console.log("THis is from inside user" + JSON.stringify(item))
            // name = item.parentname;

            setCurrentUser(item.userid)
            setCurrentUser((state) => {
                return state;
            });
        })
        return currentUser;
    }
    const confirmAppointHandleOk = () => {
        // setModalText('The modal will be closed after two seconds');
        handleConfirmAppoint()
        setConfirmLoading(true);
    };
    const confirmhandleCancel = () => {
        //console.log('Clicked cancel button');
        setConfirmModalVisible(false);
    };
    const confirmAppointHandleOk2 = () => {
        // setModalText('The modal will be closed after two seconds');
        if (portalSetting.DisableAppointment) {
            openNotificationWithDynamic('error', "Please ring the practice to organise an appointment")
            setConfirmLoading2(true);
            setConfirmModalVisible2(false)
        }
        else {
            if (bookingtype == 2) {
                handleConfirmAppoint()
                setConfirmLoading2(true);
            }
            else {
                if (appointmenttype2 != '') {
                    setConfirmLoading2(true);
                    handleConfirmAppoint()
                }
                else {
                    openNotificationWith('warning')
                }
            }
        }
        // setTimeout(() => {
        //     // setVisible(false);
        //     setConfirmLoading(false);
        // }, 2000);
    };
    const confirmhandleCancel2 = () => {
        //console.log('Clicked cancel button');
        setConfirmModalVisible2(false);
    };
    function onChangeSymptom(checkedValues) {
        //console.log('checked = ', checkedValues);
        setSymptoms(checkedValues)
        // alert(checkedValues.length)
        if (checkedValues.length)
            setNoSymptom(false)
    }
    //onChangeNoSympton
    function onChangeNoSympton(checkedValues) {
        //console.log('checked No Symptoms= ', checkedValues);
        setNoSymptom(checkedValues)

        if (checkedValues.target.checked)
            onChangeSymptom([])
    }

    useEffect(() => {
        props.getProviderLocation();

        setPortalSetting(JSON.parse(localStorage.getItem("@portalSettings")))
        // GetAppSettings().then((info) => {
        //     const set = JSON.parse(info)
        // })

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            setUser(item)
        })

        props.getAppoint(pageSize, pageNumber);
        props.getAppointType();
        props.getLookupList();
        var now = new Date();
        maxdate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).toLocaleString()).add(60, 'days')
    }, []);

    useEffect(() => {
        if (defaultLocation != '') {
            setPracticeLocation(defaultLocation)
            props.getRoles(defaultLocation)
        }
    }, [defaultLocation]);

    useEffect(() => {
        if (AppointmentTypes && AppointmentTypes.length > 0) {
            setTypeId(AppointmentTypes[0].resource.extension[0].valueString)
            setTypeName(AppointmentTypes[0].resource.extension[1].valueString)
        }
    }, [AppointmentTypes])

   

    useEffect(() => {
        if (portalSetting && portalSetting.ReasonForContact)
        props.getPractitioner(value, typeid, Roles[0].resource.extension[2].valueString, PracticeLocationID, selectedReasonForVisit)
    },[selectedReasonForVisit]);
 


    useEffect(() => {
        if (Roles && Roles.length > 0) {
            setRoleId(Roles[0].resource.extension[0].valueString)
            // roleID, appType, roleType, locations, selectedReasonForVisit
            props.getPractitioner(Roles[0].resource.id, typeid, Roles[0].resource.extension[2].valueString, PracticeLocationID, selectedReasonForVisit)
        }

    }, [Roles])

    useEffect(() => {
        if (AddAppointment == "Appointment has been booked successfully") {
            appointBookSuccess()
            setConfirmLoading2(false);
            setConfirmModalVisible(false)
            setConfirmLoading(false);
        }
    }, [AddAppointment])
    useEffect(() => {
        AddingFailed &&
            appointBookFailed()
    }, [AddingFailed])
    useEffect(() => {
        if (ProviderLocation && ProviderLocation.length > 0) {

            for (let i = 0; i < ProviderLocation.length; i++) {

                let obj = {
                    id: ProviderLocation[i].resource.extension[0].valueString,
                    colorcode: Math.floor(Math.random() * 16777215).toString(16),
                    name: ProviderLocation[i].resource.extension[1].valueString
                }
                children.push(obj);
                allist.push(ProviderLocation[i].resource.extension[0].valueString)
                //  if (!str.includes(ProviderLocation[i].resource.extension[0].valueString)) {
                str = str + ProviderLocation[i].resource.extension[0].valueString + ","
                //   }


            }
            setReady(!ready)
        }


    }, [ProviderLocation])

    useEffect(() => {
        if (AvailablePractitioner && AvailablePractitioner.length > 0) {
            SetPid(AvailablePractitioner[0].resource.id)
            setIsSelected(true)
            // commenting now for test
            //      props.getApp(GetAppointmentDate(selectedDate) + ' ' + GetMonthName(selectedDate) + ' ' + GetYear(selectedDate), AvailablePractitioner[0].resource.id, typeid, portalSetting.IsAllowSameDayBooking);
        }
        else { setIsSelected(false) }
    }, [AvailablePractitioner])

    useEffect(() => {
        //alert('dsdssd')
        let val = '';
        defaultLocation.map((item, index) => {
            return (
                val = val + item + ","
            )
        })
        if (Pid != '' && typeid != '' && roleid != '' && portalSetting) {
            console.log("portalSetting=====", portalSetting)
            props.getApp(moment(selectedDate).format("YYYY-MM-DD"), Pid, typeid, val, portalSetting?.IsAllowSameDayBooking);
        }
        return props.getAppCleanUp()
    }, [typeid, defaultLocation])  
    useEffect(() => {
        if (Proxy && Proxy.length > 1) {
            setPaitientID(Proxy[1].resource.extension[0].valueString)

        }
    }, [Proxy])
    useEffect(() => {
        if (Roles && Roles.length) {
            
            // setRoleName(Roles[0].resource.extension[1].valueString)
            // if (typeid)
            // props.getPractitioner(roleid === "" ? Roles[0]?.resource?.extension[0]?.valueString : roleid, typeid, Roles[0]?.resource?.extension[2]?.valueString, defaultLocation, selectedReasonForVisit)
        }
    }, [Roles, typeid])

    return (
        <div>

            {isSelectedBook &&
                <div className="row row-margin-zero" >
                    <div className="col-md-12 mx-auto">
                        <div className="row mb-2">
                            <div className="col-md-12 mx-auto">
                                <div className="alert alert-warning" role="alert" style={{ maxheight: 150, overflowY: 'auto', overflowX: 'hidden', padding: '15px 20px 15px 20px', lineHeight: '22px' }}>

                                    <h5 className="alert-heading">Disclaimer:</h5>
                                    {portalSetting && portalSetting.PortalAppointmentTermsAndConditions &&
                                        renderHTML(portalSetting.PortalAppointmentTermsAndConditions)
                                    }


                                </div>
                            </div>
                        </div>

                        {AppointmentTypes && AppointmentTypes.length ?
                            //AppointmentTypes && AppointmentTypes.length && defaultLocation && Roles && Roles.length && ProviderLocation && ProviderLocation.length && defaultLocation.length ?
                            <>
                                {user && user.userid == user.parentid ?
                                    <>
                                        {
                                            Proxy && Proxy.length > 1 ?
                                                <>
                                                    <div className="form-group row ">

                                                        <div className="col" style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <label className="col-form-label">Book For</label>
                                                            <Radio.Group onChange={onBookForChange} value={value}>
                                                                <Radio value={1}>Self</Radio>
                                                                <Radio value={2}>Family Member</Radio>

                                                            </Radio.Group>
                                                        </div>
                                                    </div>

                                                    {value == "2" &&
                                                        <div className="form-group row ">
                                                            <div className="col">
                                                                {
                                                                    Proxy && Proxy.length ?
                                                                        <Select
                                                                            defaultValue={Proxy[1].resource.extension[0].valueString}

                                                                            defaultActiveFirstOption
                                                                            showSearch
                                                                            style={{ width: '100%' }}
                                                                            placeholder="Select Account"
                                                                            optionFilterProp="children"
                                                                            onChange={onSelectAccountChange}
                                                                            //onFocus={onFocus}
                                                                            //onBlur={onBlur}
                                                                            //onSearch={onSearch}

                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 10
                                                                            }
                                                                        >
                                                                            {Proxy.map((item, index) => {
                                                                                if (getCurrentUserId() != item.resource.id)
                                                                                    return (
                                                                                        <Option value={item.resource.id}>{item.resource.name[0].text}</Option>
                                                                                    )
                                                                            })}

                                                                        </Select>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                </> : null
                                        }
                                    </> : null}




                                
                                    <div className="form-group row ">
                                    {portalSetting && portalSetting.AppointmentShowReasonforContact ? <div className="col">
                                            <label className="col-form-label">Reason for Contact</label>
                                            <Select

                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Select"
                                                optionFilterProp="children"
                                                onChange={onLookupChange}

                                                defaultValue={25}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 10
                                                }
                                            >
                                                <Option value={25}>Select</Option>
                                                {LookUpList?.map((data, index) => {
                                                    return (
                                                        <Option value={data.resource.extension[0].valueString}>{data.resource.extension[1].valueString}</Option>
                                                    )
                                                })}

                                            </Select>

                                        </div>: null}

                                        <div className="col">
                                            <label className="col-form-label">Reason</label>
                                            <textarea id="mReason" className="form-control" rows={3} placeholder="Type Reason here..." defaultValue={""} />

                                        </div>
                                    </div> 
                                

                              


                                <div className="form-group row ">
                                    <div className="col">
                                        <label className="col-form-label">Appointment Type:</label>
                                        {
                                            AppointmentTypes && AppointmentTypes.length ?
                                                <Select
                                                    defaultValue={AppointmentTypes[0].resource.extension[0].valueString}

                                                    defaultActiveFirstOption
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Appointment type"
                                                    optionFilterProp="children"
                                                    onChange={onAppointTypeSelectChange}
                                                    //onFocus={onFocus}
                                                    //onBlur={onBlur}
                                                    //onSearch={onSearch}
                                                    getPopupContainer={(trigger) =>
                                                        trigger.parentNode
                                                    }
                                                    filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input)}
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={AppointmentTypes.map((person) => {
                                                        return {
                                                            value: person.resource.extension[0].valueString,
                                                            label: person.resource.extension[1].valueString
                                                        }

                                                    })}
                                                />

                                                : null
                                        }
                                    </div>
                                    <div className="col">
                                        <label className="col-form-label">Provider Location:</label>

                                        {defaultLocation && ProviderLocation && ProviderLocation.length && defaultLocation.length ?
                                            <Select
                                                id="mploc"
                                                mode="multiple"
                                                maxTagCount={5}
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Please select"
                                                defaultValue={defaultLocation.length ? defaultLocation : []}
                                                onChange={handleChangeLocation}
                                            >
                                                {ProviderLocation.map((item, index) => {
                                                    return (
                                                        <Option key={item.resource.extension[0].valueString}>{item.resource.extension[1].valueString}</Option>
                                                    )
                                                })}

                                            </Select> : null}


                                    </div>

                                </div>

                               
                                <div className="form-group row ">
                                    <div className="col">
                                        <label className="col-form-label">Book With:</label>
                                        <div className="row">
                                            <div className="col-md-6 showcase_content_area mb-1">

                                                {
                                                    isFetching && isGetting ? <View style={{ marginTop: 10 }}>
                                                        <ActivityIndicator size="small" color="#00A1DE" />
                                                    </View> :
                                                    Roles ?
                                                        <Select
                                                                disabled={portalSetting &&
                                                                    portalSetting.ByPassLockDownPatientPortalAppointmentBookings == false
                                                                    && AvailablePractitioner.length && AvailablePractitioner[0].resource.extension[3].valueBoolean == true}
                                                            // defaultValue={Roles.length > 0 ? Roles[0].resource.extension[0].valueString : ""}
                                                            //showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select"
                                                            optionFilterProp="children"
                                                            onChange={onBookWithChange}
                                                            //onFocus={onFocus}
                                                                value={roleid}
                                                            //onBlur={onBlur}
                                                            //onSearch={onSearch}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            <Option value="">Select</Option>
                                                            {Roles.map((person, index) => {
                                                                return (
                                                                    <Option value={person.resource.extension[0].valueString}>{person.resource.extension[1].valueString}</Option>
                                                                )
                                                            })}

                                                        </Select>
                                                        : null
                                                }


                                            </div>

                                            <div className="col-md-6 showcase_content_area mb-1">
                                                {isGetting ? <View style={{ marginTop: 10 }}>
                                                    <ActivityIndicator size="small" color="#00A1DE" />
                                                </View>
                                                    :
                                                    <>
                                                        {
                                                            AvailablePractitioner ?
                                                                <Select
                                                                    disabled={portalSetting &&
                                                                        portalSetting.ByPassLockDownPatientPortalAppointmentBookings == false
                                                                        && AvailablePractitioner.length && AvailablePractitioner[0].resource.extension[3].valueBoolean == true}
                                                                    defaultValue={AvailablePractitioner.length ? AvailablePractitioner[0].resource.name[0].text : ""}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select provider"
                                                                    optionFilterProp="children"
                                                                    onChange={onSelectProviderChange}
                                                                    //onFocus={onFocus}
                                                                    //onBlur={onBlur}
                                                                    //onSearch={onSearch}
                                                                    getPopupContainer={(trigger) =>
                                                                        trigger.parentNode
                                                                    }
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    <Option value="">Select Provider</Option>
                                                                    {AvailablePractitioner.map((person, index) => {
                                                                        return (
                                                                            <Option value={person.resource.id}>{person.resource.name[0].text}</Option>
                                                                        )
                                                                    })}

                                                                </Select>
                                                                : !isGetting &&
                                                                <p>{ console.log("rolename",roleName)}
                                                                {
                                                                   
                                                                    roleName =="" || roleName == "Select" ? "Please select any Practitioner"
                                                                    :roleName + ' is not available'} </p>

                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="form-group row ">

                                    {

                                        isselected && <div className="col">
                                            <label className="col-form-label">Please select date to book appointment:</label>

                                            <div className="mt-2" style={{ lineHeight: "1.3", fontSize: "14px !important", maxWidth:"75vw" }}>
                                                <DatePicker

                                                    endDate={90}
                                                    selectDate={selectedDate}
                                                    getSelectedDay={selectedDay}
                                                    labelFormat={"MMMM"}
                                                    color={"#4d8af0"}
                                                    
                                                />
                                            </div>

                                        </div>
                                    }
                                </div>




                                {

                                    isselected &&
                                    <TouchableOpacity
                                        // disabled={!array.length}
                                        style={{ height: 40, width: 200, marginTop: 60, marginLeft: 20, borderRadius: 5, justifyContent: 'center', alignItems: 'center', backgroundColor: '#4d8af0' }}
                                        onPress={() => {
                                            nextAvailableDay()
                                        }}>
                                        <Text style={{ color: 'white', fontSize: 15 }}>{loadingdate ? <ActivityIndicator size="small" color="#fff" /> : availableDay !== '' ? availableDay.toString() : "Next Available Day"} </Text>
                                    </TouchableOpacity>
                                }


                                {isSelectedBook &&
                                    <>
                                        {
                                            isselected &&
                                            <>
                                                {/* {isBooking &&
                                                 <ActivityIndicator size="small" color="#00A1DE" />} */}
                                                <div className="schedule-cont">
                                                    <div className="row no-gutters">
                                                        {Colorlist && Colorlist.length > 1 ?
                                                            Colorlist.map((item, i) => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row', margin: 5 }}>
                                                                        <View style={{ width: 20, height: 20, backgroundColor: item.colorcode }}></View>
                                                                        <Text style={{ marginLeft: 5 }}>{item.name}</Text>
                                                                    </View>
                                                                )
                                                            }) : null

                                                        }

                                                        <div className="col-md-12">
                                                            <div className="time-slot">
                                                                <ul className="clearfix">
                                                                    <div className="form-group row mb-0">
                                                                        <div className="col-md-6 mt-2 ">
                                                                            <h6>Morning</h6>
                                                                            {isBooking && <ActivityIndicator style={{ marginTop: 10 }} size="small" color="#00A1DE" />}
                                                                            {
                                                                                BookApp && BookApp.length ?
                                                                                    BookApp.map((item, index) => {

                                                                                        return (
                                                                                            <li style={{ marginTop: 10 }}>

                                                                                                {/* id={"m" + index} */}
                                                                                                <a id={"m" + index} onClick={() => addClassTest(index, item)} style={{ width: 105, display: 'flex', flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center', textDecoration: 'none' }} className="timing" href="javascript:void(0)">
                                                                                                    <span>{FormatAMPM(item.resource.start.split('+')[0])}
                                                                                                    </span>

                                                                                                    {
                                                                                                        Colorlist && Colorlist.length > 1 ?
                                                                                                            <View style={{ marginLeft: 8, width: 20, height: 20, backgroundColor: Colorlist.find(x => x.id === item.resource.extension[5].valueString).colorcode }}></View>
                                                                                                            : null}

                                                                                                </a>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    !isBooking && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>

                                                                                        
                                                                                        <span style={{ marginTop: 10, }} className='text-danger'>{sameDayErrorMsg !== null ?sameDayErrorMsg: "No Slots Available"}</span>
                                                                                    </View>
                                                                            }

                                                                        </div>


                                                                        <div className="col-md-6 mt-2 ">
                                                                            <h6>Afternoon</h6>
                                                                            {isBooking && <ActivityIndicator style={{ marginTop: 10 }} size="small" color="#00A1DE" />}
                                                                            {
                                                                                BookAppPM && BookAppPM.length ?
                                                                                    BookAppPM.map((item, index) => {

                                                                                        return (
                                                                                            <li style={{ marginTop: 10 }}>
                                                                                                <a h id={"ma" + index} onClick={() => addClassTest('a' + index, item)}
                                                                                                    style={{ width: 105, display: 'flex', flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center', textDecoration: 'none' }} className="timing" href="javascript:void(0)">
                                                                                                    <span>{FormatAMPM(item.resource.start.split('+')[0])}
                                                                                                    </span>
                                                                                                    {
                                                                                                        Colorlist && Colorlist.length > 1 ?
                                                                                                            <View style={{ marginLeft: 8, width: 20, height: 20, backgroundColor: Colorlist.find(x => x.id === item.resource.extension[5].valueString).colorcode }}></View>
                                                                                                            : null}
                                                                                                </a>
                                                                                            </li>

                                                                                        )
                                                                                        //  }

                                                                                    })
                                                                                    :
                                                                                    !isBooking && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <span style={{ marginTop: 10, }} className='text-danger'>{sameDayErrorMsg !== null ?sameDayErrorMsg: "No Slots Available"}</span>
                                                                                    </View>
                                                                            }
                                                                        </div>

                                                                    </div>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    isselectedreason &&

                                                    <div className="col-md-12">


                                                    </div>
                                                }
                                            </>

                                        }
                                    </>
                                }


                                {!isSelectedBook ?
                                    <TouchableOpacity

                                        style={{ height: 40, width: 200, marginTop: 20, marginLeft: 20, borderRadius: 5, justifyContent: 'center', alignItems: 'center', backgroundColor: '#4d8af0' }}
                                        onPress={() => { setIsSelectedBook(true) }}>
                                        <Text style={{ color: 'white', fontSize: 15 }}>Book an Appointment</Text>
                                    </TouchableOpacity>
                                    :
                                    null
                                }
                            </>
                            : <ActivityIndicator size="small" color="#00A1DE" />
                        }


                    </div>




                </div>

            }
            <Space direction="vertical"></Space>

            <Modal
                title="Book Your Appointment"
                centered
                open={showReset}
                onOk={() => {
                    handleConfirmAppoint()
                    // saveApi()
                    // setAddContactVisible(false)
                }}
                onCancel={() => handleCloseReset()}
                //afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1000}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">

                        {/* <br></br>
                        <hr></hr> */}
                        {/* <div className="letter-emergency-detail-card-heading">
                            <h5>Address Information</h5>
                        </div> */}

                        {/* House infomraiton */}
                        <div style={{ marginTop: 20 }} className="form-group row mb-0">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Appointment Id</label>
                                <div className="input-group">
                                    <input id="mHouse" type="text" value={slotId} readOnly className="form-control" placeholder="Find Address" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Doctor Name:</label>
                                <div className="input-group">
                                    <input id="mHouse" type="text" value={doctorName} readOnly className="form-control" placeholder="House Number" />

                                </div>
                            </div>

                        </div>

                        {/* Street information */}
                        <div style={{ marginTop: 20 }} className="form-group row mb-0">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Appointment Duration</label>
                                <div className="input-group">
                                    <input id="mFloor" type="text" value={durationAppoint} readOnly className="form-control" placeholder="Floor" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Appointment Time </label>
                                <div className="input-group">
                                    <input id="mHouse" type="text" value={appointTime} readOnly className="form-control" placeholder="House Number" />

                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Reason</label>
                                <div className="input-group">
                                    <input id="mStreet" type="text" className="form-control" placeholder="Street" />

                                </div>
                            </div> */}
                        </div>

                        {/* City information */}
                        {/* <div style={{ marginTop: 20 }} className="form-group row mb-0">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Suburb/Town</label>
                                <select id="mTown" className="form-control" name="country">
                           
                                </select>
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">City</label>
                                <select id="mCity" className="form-control" name="country">
                               

                                </select>
                            </div>

                        </div> */}


                        {
                            isAdding && <ActivityIndicator style={{ marginTop: 30 }} size="small" />
                        }

                        {/* <p style={{ color: 'red', alignSelf: 'center', marginTop: 10 }} >{AddAppointment}</p> */}

                    </div>




                </div>

            </Modal>

            <Modal
                title="COVID-19 (NOVEL CORONAVIRUS)"
                open={isCovidModalVisible}
                onOk={handleCovidModalOk}
                onCancel={handleCovidModalCancel}
                width={1000}>
                <div className='text-center bg-secondary p-3 mb-2' style={{ fontWeight: 600, fontSize: "19px" }}>This information is being captured for practice use only.</div>
                <h6 id="covidTitle">Symptoms</h6>
                <p>These symptoms do not necessarily mean you have COVID-19 but it is important for the health and wellbeing of our staff and community that we look out for each other and inform the practice if you have any of the below symptoms.</p>
                <hr />

                <div className="form-group mt-2">
                    <p id="covidQuesA">Have you ever had any of the following?</p>
                    <div className="row mt-2 ml-1">

                        <Checkbox.Group options={['Cough', 'Shortness of breath', 'Sore throat', 'Fever (38C or higher)', 'Muscle aches', 'Runny Nose', 'Fatigue', 'Loss of Smell', 'Altered Taste']} value={symptoms} onChange={onChangeSymptom} />
                        {/* <Checkbox >Cough</Checkbox>
                        <Checkbox >Shortness of breath</Checkbox>
                        <Checkbox >Sore throat</Checkbox>
                        <Checkbox >Fever (38C or higher)</Checkbox> */}
                        <Checkbox checked={nosymptoms} onChange={onChangeNoSympton}>No Symptoms</Checkbox>
                    </div>
                </div>

                <div className="form-group mt-2">
                    <h6 id="covidTitleB">Recent travel or contact with someone with novel coronavirus</h6>
                    <hr />
                    <p id="travelA">a) Have you travelled to or from (excluding airport transit through) countries or areas of concern* within 14 days before onset of illness?</p>

                    <div className="row mt-2 ml-1">
                        <div className="col">
                            <div>
                                <Radio.Group onChange={onTravelChange} value={travelcheck}>
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>

                                </Radio.Group>
                            </div>
                            {
                                travelcheck == 1 &&
                                <>
                                    <p className="mt-4">If YES, please provide details</p>
                                    <input id="mtravel" className="form-control" placeholder="Enter details here..." />

                                </>}

                        </div>
                    </div>
                    <div className="mt-4"></div>
                    <p id="travelB">b) Have you been in close contact or casual contact** with a person with suspect, probable or confirmed infection of novel coronavirus in the 14 days before the onset of your symptoms?*</p>
                    <div className="row mt-2 ml-1">
                        <div className="col">
                            <div>
                                <Radio.Group onChange={onContactWithSickChange} value={contactCheck} >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>

                                </Radio.Group>
                            </div>
                            {
                                contactCheck == 1 &&
                                <>
                                    <p className="mt-4">If YES, please provide details</p>
                                    <input id="mcontact" className="form-control" placeholder="Enter details here..." />
                                </>
                            }
                        </div>
                    </div>
                </div>


                {/* ----------------------------------------------- */}
                <div className="form-group mt-2">
                    <h6 id="covidTitleB">Consent</h6>
                    <hr />
                    <p id="travelA">a) I, and any person aged over 12 years attending, have received 2 doses of CVOID vaccination?</p>
                    <div className="row mt-2 ml-1">
                        <div className="col">
                            <div>
                                <Radio.Group onChange={onChangeConsentA} value={coivdDose}>
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>

                                </Radio.Group>
                            </div>
                            {/* {
                                travelcheck == 1 &&
                                <>
                                    <p className="mt-4">If YES, please provide details</p>
                                    <input id="mtravel" className="form-control" placeholder="Enter details here..." />

                                </>} */}
                        </div>
                    </div>


                    <div className="mt-4"></div>
                    <p id="travelB">c) I, and any person aged over 12 years attending, will wear face mask at all times?</p>
                    <div className="row mt-2 ml-1">
                        <div className="col">
                            <div>
                                <Radio.Group onChange={onChangeConsentB} value={wearMask} >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>

                                </Radio.Group>
                            </div>
                            {/* {
                                contactCheck == 1 &&
                                <>
                                    <p className="mt-4">If YES, please provide details</p>
                                    <input id="mcontact" className="form-control" placeholder="Enter details here..." />
                                </>
                            } */}
                        </div>
                    </div>
                </div>


            </Modal>

            <Modal
                title="Confirm Appointment"
                open={confirmModalvisible}
                onOk={confirmAppointHandleOk}

                confirmLoading={confirmLoading}
                onCancel={confirmhandleCancel}
            >
                <p>{"Are you sure you want to book an appointment on, "}</p>
                <p><b>DateTime:</b> {GetAppointmentDate(selectedDate) + "-" + GetMonthName(selectedDate) + "-" + GetYear(selectedDate)}, {appointTime}</p>
                <p><b>Type:</b> {typeName}</p>
                <p><b>Location:</b> {locationName}</p>
            </Modal>
            <Modal
                title="Confirm Appointment"
                open={ErrorModal}
                onOk={() => { setErrorModal(false) }}

                // confirmLoading={confirmLoading}
                onCancel={() => { setErrorModal(false) }}
            >
                <p>
                    As per the responses recorded through this questionnaire, your appointment cannot be booked. For further assistance please contact the practice to organize/manage your appointment.
                </p>
                {/* {"Are you sure you want to book an appointment on " + GetAppointmentDate(selectedDate) + "-" + GetMonthName(selectedDate) + "-" + GetYear(selectedDate) + " " + appointTime}</p> */}
            </Modal>

            <Modal
                title="Confirm Appointment"
                open={confirmModalvisible2}
                onOk={confirmAppointHandleOk2}
                width={1000}
                onCancel={confirmhandleCancel2}
            >
                <p>
                    {`Based upon provided input on COVID-  19 it is suggested to contact health line 0800 358 5453. Please select one of the following two options. If you still want to book an appointment ${GetAppointmentDate(selectedDate) + "-" + GetMonthName(selectedDate) + "-" + GetYear(selectedDate)} ${appointTime} at ${locationName}. Please confirm.`}
                </p>
                <p> Please select one of the following two options</p>
                {/*  */}
                <Radio.Group onChange={onBookingTypeChange} value={bookingtype}>
                    <Radio value={1}>Virtual consult</Radio>
                    <Radio value={2}>Still wish to book Face 2 Face </Radio>
                </Radio.Group>

                {
                    bookingtype == 2 ?
                        <div>
                            <hr />
                            <p className="mt-4">{portalSetting && portalSetting.Face2FaceConfirmationMessage}</p>

                        </div>
                        :
                        AppointmentTypes && AppointmentTypes.length ?
                            <Select
                                //  defaultValue={AppointmentTypes[0].resource.extension[1].valueString}
                                //defaultActiveFirstOption={true}
                                defaultActiveFirstOption
                                showSearch
                                style={{ width: '100%', marginTop: 10 }}
                                placeholder="Select Appointment type"
                                optionFilterProp="children"
                                onChange={onAppointTypeSelectChange2}
                                // onFocus={onFocus}
                                //onBlur={onBlur}
                                //onSearch={onSearch}
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                            >
                                {AppointmentTypes.map((person, index) => {
                                    if (person.resource.extension[1].valueString.includes('Virtual'))
                                        return (
                                            <Option value={person.resource.extension[0].valueString}>{person.resource.extension[1].valueString}</Option>
                                        )
                                })}

                            </Select>
                            : null
                }

            </Modal>
        </div >

    );
}

function mapStateToProps(state) {
    return {
        AvailablePractitioner: state.AvailablePractitioner,
        Roles: state.Roles,
        BookApp: state.BookApp,
        Proxy: state.Proxy,
        AddAppointment: state.AddAppointment,
        FutureAppointments: state.FutureAppointments,
        AppointmentTypes: state.AppointmentTypes,
        ProviderLocation: state.ProviderLocation,
        LookUpList: state.LookUpList,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getLookupList: () => disptach(fetchLookupListAPI()),
        getPractitioner: (roleID, appType, roleType, locations, selectedReasonForVisit) => disptach(fetchAvailablePractitionerFromApi(roleID, appType, roleType, locations, selectedReasonForVisit)),
        getRoles: (defaultLocation) => disptach(fetchRolesFromApi(defaultLocation)),
        getApp: (def, id, typeid, locations, sameDayBooking) => disptach(fetchTestAllergy(def, id, typeid, locations, sameDayBooking)),
        getAppCleanUp: () => disptach(clenupbooking()),
        getPractitionerClean: () => disptach(getPractitionerClean()),
        addAppoint: (slotId, testing, PaitientID, typeid, reasonId) => disptach(addPaitentAppoinmenttoAPI(slotId, testing, PaitientID, typeid, reasonId==null?25:reasonId)),
        addAppointClean: () => disptach(clenuplatData()),
        getAppoint: (pageSize, pageNumber) => disptach(fetchfAppointmentsFromApi(pageSize, pageNumber)),
        getAppointType: () => disptach(fetchAppointmentsTypesFromApi()),
        getProviderLocation: () => disptach(fetchProviderLocationFromApi()),
    }
}

// function mapStateToProps(state) {
//     return {
//         MissedAppointments: state.MissedAppointments
//     }
// }

// function mapDispatchToProps(dispatch) {
//     return {
//         getMissedAppoint: (pageSize, pageNumber) => dispatch(fetchmAppointmentsFromApi(pageSize, pageNumber)),

//     }
//}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewUpdatedAppointment)

//export default FutureAppointmentView