import Modal from 'antd/lib/modal/Modal'
import React from 'react'

const SendConfirmModal = ({ isModalOpen, handleOk, handleCancel, loading }) => {

    return (
        <Modal
            title="Send Message"
            open={isModalOpen}
            onOk={() => handleOk()}
            onCancel={() => handleCancel()}
            okText={"Yes"}
            cancelText={"No"}
            confirmLoading={loading}
        >
            Are you sure you want to send this message?
        </Modal>
    )
}

export default SendConfirmModal
