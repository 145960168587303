import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text, StyleSheet } from "react-native-web";
import { Tabs } from "antd";
import { connect } from 'react-redux'

import "antd/dist/antd.css";
import AllMedications from "./MedicationsTabs/AllMedications";
import LongTermMed from "./MedicationsTabs/LongTermMed";
import RepeatedMedication from "./MedicationsTabs/RepeatedMedication";

import { checkToken } from "../../shared/actions/TokenValidActions";
const useNavigate = require("react-router-dom").useNavigate;

const { TabPane } = Tabs;

const Med = (props) => {
  const history = useNavigate()
  const [isloaded, setIsload] = React.useState(false);
  React.useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://indici-pre-production.web.app/assets/js/template.js";
    document.body.appendChild(script);
    // d.div.appendChild(script);
  }, []);

  React.useEffect(() => {
    props.tokenVal(history);
  }, [])
  return (
    
          <div className="page-content-wrapper-inner">
            <div className="viewport-header">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb has-arrow">
                  <li className="breadcrumb-item">
                    {/* <a href=>Dashboard</a> */}
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Medications
                  </li>
                </ol>
              </nav>
            </div>
            <div className="content-viewport">
              <div className="row row-margin-zero">
                <div className="col-12">
                  <div className="grid">
                    <div className="grid-body">
                      {/* <h2 className="grid-title">Appointments</h2> */}
                      <div className="item-wrapper">
                        <Tabs defaultActiveKey="1" centered>
                          <TabPane tab="Long Term" key="1">
                            <LongTermMed />
                          </TabPane>
                          {/* <TabPane tab="Prescription Details" key="2" style={{ backgroundColor: '#f7f7f7', padding: 10 }}> */}
                          <TabPane tab="History" key="2">
                            <AllMedications />
                          </TabPane>
                          {/* <TabPane tab="Repeated Medication" key="3">
                            <RepeatedMedication />
                          </TabPane> */}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

       
    // <div>
    //     <Header />
    //     <div id="myphonemenu" className="page-body">
    //         <Sidebar />
    //         <div className="page-content-wrapper">
    //             <div className="page-content-wrapper-inner">

    //                 <div className="viewport-header">
    //                     <nav aria-label="breadcrumb">
    //                         <ol className="breadcrumb has-arrow">
    //                             <li className="breadcrumb-item">
    //                                 <a href="#">Dashboard</a>
    //                             </li>
    //                             <li className="breadcrumb-item active" aria-current="page">Medications</li>
    //                         </ol>
    //                     </nav>
    //                     <div className="item-wrapper">
    //                         <Tabs defaultActiveKey="1" centered >
    //                             <TabPane tab="Long Term" key="1" >
    //                                 <LongTermMed />

    //                             </TabPane>
    //                             {/* <TabPane tab="Prescription Details" key="2" style={{ backgroundColor: '#f7f7f7', padding: 10 }}> */}
    //                             <TabPane tab="Recent Medication" key="2">

    //                                 <AllMedications />
    //                             </TabPane>
    //                             <TabPane tab="Repeated Medication" key="3" >

    //                                 <RepeatedMedication />
    //                             </TabPane>

    //                         </Tabs>
    //                     </div>
    //                 </div>

    //             </div>

    //             <footer className="footer-ignore">

    //             </footer>
    //         </div>

    //     </div>

    // </div >
  );
};

const styles = StyleSheet.create({
  textDetails: {
    textAlign: "justify",
    color: "#95989A",
    fontSize: 15,
  },
  textDetailsInner: {
    textAlign: "justify",
    color: "#333333",
    fontSize: 14,
  },
});


function mapStateToProps(state) {
  return {

  }
}

function mapDispatchToProps(dispatch) {
  return {

    tokenVal: (history) => dispatch(checkToken(history))
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Med)


// export default Med;
