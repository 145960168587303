import React, { useState, useEffect } from "react";
import { Select, Modal, Button, message, Upload } from "antd";
import { Input, notification, Spin } from "antd";
import { RadiusBottomleftOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { getMessageRecipientsAPI } from "../../../../shared/actions/MessagingActions/ListMessagesRecipients";
import { listMessageRolesAPI } from "../../../../shared/actions/MessagingActions/LoadMessagingRoles";
import { sendMessageAPI, sendMessageAPIRefresher } from "../../../../shared/actions/MessagingActions/SendingMessages";
import { connect } from "react-redux";
import PdfFileComp from "../MessageAttachments/PdfFileComp";
import JpgFileComp from "../MessageAttachments/JpgFileComp";
import AttachmentsHandler from "../MessageAttachments/AttachmentsHandler";
import renderHTML from 'react-render-html';

const { Option } = Select;
const { TextArea } = Input;

const ComposeMsgModal = (props) => {
  const { recipientsData, recipientsDataSuccess } = props.MessageRecipients
  const { rolesData, rolesDataSuccess } = props.MessagingRoles
  const { sendMessage, sendMessageLoading, sendMessageSuccess, sendMessageFailed } = props.SendingMessage
  const [msgRoleId, setMsgRoleId] = useState(rolesDataSuccess && rolesData && rolesData[0].entry ? rolesData[0].entry[0].resource.id:"");
  const [msgRoleName, setMsgRoleName] = useState(rolesDataSuccess && rolesData && rolesData[0].entry ? rolesData[0].entry[0].resource.title:"");
  const [msgRecipientId, setMsgRecipientId] = useState("");
  const [msgRecipientName, setMsgRecipientName] = useState("");
  const [msgSubject, setMsgSubject] = useState("");
  const [msgBody, setMsgBody] = useState("");
  const [msgBodyErrMsg, setMsgBodyErrMsg] = useState("");
  const [providerErrMsg, setProviderErrorMsg] = useState("");
  const [rolesErrMsg, setRolesErrMsg] = useState("");
  const [msgSubErrMsg, setsetMsgSubErrMsg] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([])
  const [imageFiles, setImageFiles] = useState([])
  let formatFiles = []


  const onRolesChange = (value, option) => {
    setRolesErrMsg("")
    setMsgRoleId(value);
    setMsgRoleName(option.children);
   
  };

  useEffect(()=>{
    setMsgRecipientId("");
    setMsgRecipientName("");
    
    if(msgRoleId != ""){
       const values = {
      roleID: msgRoleId,
    };
    props.getMsgRecipients(values);
    }
  },[msgRoleId])
  const onProviderChange = (value, option) => {
    setMsgRecipientId(option.key);
    setMsgRecipientName(option.children);
    setProviderErrorMsg("")
  };
  //  useEffect(()=>{
  //   if(msgRoleId){
  //     const values = {
  //       roleID: msgRoleId,
  //     };
  //     props.getMsgRecipients(values);
  //   }
    
  // },[msgRoleId])

  const checkValidations = () => {
    let caughtErr = false
    console.log("validatinggg", msgBody)

    if(msgRecipientId == ""){
      setProviderErrorMsg("Please Select a Provider")
      caughtErr = true
    }
    if(msgRoleId == ""){
      setRolesErrMsg("Please Select a Role")
      caughtErr = true
    }
    if (msgBody == "") {
      setMsgBodyErrMsg("Please type something in the body of the message")
      caughtErr = true
    }
    if (msgSubject == "") {
      console.log("empptyyy boddd")
      setsetMsgSubErrMsg("Please type something in the subject of the message")
      caughtErr = true
    }
    return caughtErr
  }
  const handleSendClick = () => {
    let validateErr = checkValidations()
    if (validateErr) {
      return
    } else {
      console.log("inside elseeee")
    const values = {
      centre: {
        id: "Location/" + msgRoleId,
        name: msgRoleName,
      },
      practitioner: {
        id: "Practitioner/" + msgRecipientId,
        name: msgRecipientName,
      },
      message: {
        subject: msgSubject,
        body: msgBody,
      },
      selectedFiles: selectedFiles,
      messageType: "Send Mail",
      roleID: msgRoleId,
    };
    props.sendMessage(values)
  }
};
  const handleDraftClick = () => {
    let validateErr = checkValidations()
    if (validateErr) {
      return
    }
    const values = {
      centre: {
        id: "Location/" + msgRoleId,
        name: msgRoleName,
      },
      practitioner: {
        id: "Practitioner/" + msgRecipientId,
        name: msgRecipientName,
      },

      message: {
        subject: msgSubject,
        body: msgBody,
      },
      selectedFiles: selectedFiles,
      messageType: "Draft",
      roleID: msgRoleId,

    };
  props.sendMessage(values)

  };
  const handleRemoveFile =(index)=>{
    if(selectedFiles.length >0){
      setSelectedFiles(selectedFiles.filter((file, fileIndex)=> fileIndex != index))
    }
  }
const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
const pdfTypeRegex = /application\/(pdf)/gm;
const selectFilesHandler = (e) => {
    const { files } = e.target;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match(imageTypeRegex) || file.type.match(pdfTypeRegex)) {
        validImageFiles.push(file);
      }
    }
    if (validImageFiles.length) {
      setImageFiles(validImageFiles);
      return;
    }
    alert("Selected images are not of valid type!");
  };
  useEffect(() => {
    const images = [], fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        let data ={}
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
             data = {
                contentAttachment: {
                  contentType: result.split("/")[1].split(";base64")[0],
                  data: result.split("base64,")[1]
                
              }
            }
            images.push(data)
          }
          if (images.length === imageFiles.length && !isCancel) {
            setSelectedFiles((prev) => prev.concat(images));
          }
        }
        fileReader.readAsDataURL(file);
      })
    };
    return () => {
      isCancel = true;
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFiles]);
  useEffect(()=>{
    console.log("seelcted files length => ", selectedFiles.length, " data=>", selectedFiles)
  },[selectedFiles])
  return (
    <>
      <Modal
        title="New Message"
        width={800}
        style={{ top: "50px" }}
        open={props.isComposeModalOpen}
        onCancel={props.handleCancel}
        
        footer={[
          <Button
            key="cancel"
            onClick={props.handleCancel}
            className="cancel-msg-btn"
          >
            Cancel
          </Button>,
          <Button
            key="draft"
            onClick={handleDraftClick}
            className="draft-msg-btn"
          >
            Draft
          </Button>,
          <Button key="send" onClick={handleSendClick} className="send-msg-btn">
            Send
          </Button>,
        ]}
      >
        <div className="row alert alert-warning compose-msg-diclaimer">
                    <h6 className="alert-heading">Disclaimer:</h6>
                    {props.disclaimerMsg != "" &&
                          renderHTML(props.disclaimerMsg)
                    }

        </div>
        {!rolesDataSuccess ? <Spin /> :
        <div className="row">
        <label style={{fontWeight: 'bolder'}} className="col-3 ">Role</label>
          <Select
          className="col-8"
            showSearch
            placeholder="To"
            optionFilterProp="children"
            style={{ width: "100%", borderRadius: "20px" }}
            onChange={onRolesChange}
            value={
              msgRoleId
            }
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
          <Option value={""}>Select</Option>

            {rolesDataSuccess
              ? rolesData[0].entry.map((data) => (
                <Option
                  value={data.resource.id}
                  key={data.resource.id}
                >
                  {data.resource.title}
                </Option>
              ))
              : null}
          </Select>
        </div>
          }
          <p className="text-danger">{rolesErrMsg}</p>
          <div className="row">

          <label style={{fontWeight: 'bolder'}} className="col-3 ">Practitioner</label>

        <Select
        className="col-8"
          showSearch
          placeholder="Select Provider"
          optionFilterProp="children"
          style={{ width: "100%"}}
          value={msgRecipientId}
          onChange={onProviderChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          <Option value={""}>Select</Option>
          {recipientsDataSuccess && msgRoleId != "" && recipientsData[0].entry
            ? recipientsData[0].entry.map((data) => (
              <Option
                value={data.resource.extension[0].valueString}
                key={data.resource.extension[0].valueString}
              >
                {data.resource.name[0].text}
              </Option>
            ))
            : null}
        </Select>
          </div>

        <p className="text-danger">{providerErrMsg}</p>

              <div className="row">
              <label style={{fontWeight: 'bolder'}} className="col-3 m-0">Subject</label>

        <Input
        className="col-8"
          placeholder="Subject"
          style={{  }}
          value={msgSubject}
          onChange={(e) => { setMsgSubject(e.target.value); setsetMsgSubErrMsg("") }}
          />
          </div>
        <p className="text-danger">{msgSubErrMsg}</p>
        <TextArea
          // value={value}
          // onChange={(e) => setValue(e.target.value)}
          placeholder="Write your message here"
          autoSize={{ minRows: 10, maxRows: 10 }}
          style={{ width: "93%", marginTop: "15px" }}
          value={msgBody}
          onChange={(e) => { setMsgBody(e.target.value); setMsgBodyErrMsg("") }}
          maxLength={500}
        />
        <p className="text-danger">{msgBodyErrMsg}</p>
       
     
        <label htmlFor="file" className="p-2 text-primary fw-bold mt-3 px-3 choose-file">Choose Files
        </label>
        <input
            type="file"
            id="file"
            onChange={selectFilesHandler}
            accept="image/png, image/jpg, image/jpeg,application/pdf"
            multiple
          />
          {
        selectedFiles.length > 0 ?
          <AttachmentsHandler selectedFiles={selectedFiles} type="composing" handleRemoveFile={handleRemoveFile}/>:null
      }

      </Modal>
    </>
  );
};


function mapStateToProps(state) {
  return {
    TokenValidator: state.TokenValidator,

    MessageRecipients: state.MessagesRecipientsReducer,
    SendingMessage: state.SendingMessagesReducer,
    MessagingRoles: state.LoadMessagingRolesReducer
  }
}
function mapDispatchToProps(disptach) {
  return {

    getMsgRecipients: (values) => disptach(getMessageRecipientsAPI(values)),
    sendMessage: (values) => disptach(sendMessageAPI(values)),
    sendMessageRefreshData: () => disptach(sendMessageAPIRefresher()),
    loadMsgRoles: () => disptach(listMessageRolesAPI()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposeMsgModal)
// export default
