import { INBOX_MESSAGE_LISTING, INBOX_MESSAGE_LISTING_SUCCESS, INBOX_MESSAGE_LISTING_LOADING, INBOX_MESSAGE_LISTING_ERROR, INBOX_MESSAGE_LISTING_FAILED } from '../../constants/Constant'


//SET INITAILS STATE
const initialState =  {
    inboxMessageListing: null,
    inboxMessageListingSuccess: false,
    inboxMessageListingFailed: false,
    inboxMessageListingLoading: false,
    inboxMessageListingError: null,
    inboxMessageListingStatus: "idle",
  }

//export the defult state

export default function inboxMessageReducer(state = initialState, action) {
    switch (action.type) {
        case INBOX_MESSAGE_LISTING:
            return {
                ...state,
                inboxMessageListingLoading: true,
                inboxMessageListingStatus: "loading",
                // inboxMessageListing: null,
                inboxMessageListingSuccess: false,
                inboxMessageListingFailed: false,
                inboxMessageListingError: null,
            }

        case INBOX_MESSAGE_LISTING_SUCCESS:
            return {
                ...state,
                inboxMessageListingLoading: false,
                inboxMessageListing: action.data,
                inboxMessageListingSuccess: true,
                inboxMessageListingStatus: "success",
                inboxMessageListingFailed: false,
                inboxMessageListingError: null,
            }

        case INBOX_MESSAGE_LISTING_FAILED:
            return {
                ...state,
                inboxMessageListingLoading: false,
                inboxMessageListingStatus: "failed",
                inboxMessageListingError: action.data,
                inboxMessageListingFailed: true,
                inboxMessageListing: null,
                inboxMessageListingSuccess: false,
            }

        default:
            return state
    }
}