import { createStore, applyMiddleware,blacklist } from "redux";
import rootReducer from "./shared/reducers/index";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

//cofigute store
const persistConfig = {
    key: "main-root",
    storage,
    blacklist: ['VitalReducer']

};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));

const Persister = persistStore(store);


export default store;
export { Persister };